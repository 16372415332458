import { stripeColorsWhite, dailyPolarColors, MONTHS_SHORT, windColors, BACKGROUND_COLOR } from '../config/constants.ts';
import { theme } from '../theme.tsx'

interface Annotation {
    x: number;
    y: number;
    xref: string;
    yref: string;
    showarrow: boolean;
    text: string;
    font: {
        color: string;
        weight: number;
        size: number;
    };
}

const cumulDays = [0, 31, 60, 91, 121, 152, 182, 213, 244, 274, 305, 335, 366];

function truncateString(str, maxLengthOverall) {
    if (str === null) {
        return "Loading..."
    }

    const maxLengthWithoutDots = maxLengthOverall - 1
    if (str.length > maxLengthWithoutDots && str.length >= maxLengthOverall) {
        return str.slice(0, maxLengthWithoutDots) + "..";
    }
    return str;
}

function yvalsToColors(yvals) {
    const yvalsMin = Math.min(...yvals)
    const yvalsAnom = yvals.map(val => val - yvalsMin)
    const yvalsRange = Math.max(...yvalsAnom) / 2
    const yvalsScaled = yvalsAnom.map(val => (val / yvalsRange) - 1)
    const markerColor = yvalsScaled.map(
        function (value) {
            return getStripeColor(value, 'BlueToRed')
        }
    )
    return markerColor
}

function getStripeColor(value: number, colorScale = 'Hawkins') {

    let theseStripeColors: string[] = []
    if (colorScale === 'Hawkins') {
        theseStripeColors = stripeColorsWhite
    } else if (colorScale === 'BlueToRed') {
        // https://leonardocolor.io/scales.html#
        // Must be odd!
        theseStripeColors = dailyPolarColors
    } else {
        throw new Error(`Unknown color scale: ${colorScale}`)
    }

    const halfLength = (theseStripeColors.length - 1) / 2
    const indexRaw = value * halfLength
    const indedRounded = Math.round(indexRaw)
    const indexShifted = indedRounded + halfLength
    const indexClamped = Math.min(Math.max(0, indexShifted), theseStripeColors.length - 1)

    return theseStripeColors[indexClamped]
}

function getDataMinMax(yvals, base, yvals2, base2) {
    // Calculate yvalsPbase and yvalsMn based on the base values
    const yvalsPbase = base ? yvals.map((num, idx) => num + base[idx]) : [];

    // Calculate dataMin and dataMax for the plot
    let dataMin = base ? Math.min(...base) : 0; // or some other default value
    let dataMax = Math.max(...yvalsPbase);

    if (base2 !== null) {
        dataMin = Math.min(dataMin, Math.min(...base2));
    }
    if (yvals2 !== null) {
        const yvals2Pbase2 = base2 ? yvals2.map((num, idx) => num + base2[idx]) : [];
        dataMax = Math.max(dataMax, Math.max(...yvals2Pbase2));
    }

    const dataMinPlot = dataMin - 5;
    const dataMaxPlot = dataMax;
    return { dataMinPlot, dataMaxPlot };
}

function generatePolarData(yvals, base, yvals2, base2, xvals, dataRange, show, monthIndex, forDownload) {
    // Define cumulative days and scaling factor
    const scaling = 360 / 366;

    // Initialize dataToPlot array
    const dataToPlot: Record<string, any>[] = [];

    const text = getDaysLong();

    const highlightColor = theme.colors.c3sRed[9] + '22'

    if ((base !== null) && (yvals !== null)) {
        let stripesColors: string[] = Array(yvals.length).fill("#00000033");
        let zorder = 10
        if (show[0]) {
            const yvalsMn = base ? yvals.map((num, idx) => (num + base[idx]) / 2) : [];
            stripesColors = yvalsToColors(yvalsMn);
            zorder = 2000
        }

        // Prepare text and hovertemplate for the plot
        const hovertemplate = '%{text}<br><b>Min: </b>%{base:.1f} °C<br><b>Max</b>: %{customdata:.1f} °C';
        const customdata = base?.map((val, i) => val + yvals[i]);

        // Create the actual data for plotting
        const actualData = {
            type: "barpolar",
            mode: "lines",
            r: yvals,
            base: base,
            theta: xvals,
            fill: "toself",
            marker: { color: stripesColors },
            name: "",
            zorder: zorder,
            text: text,
            hovertemplate: hovertemplate,
            textposition: "none",
            customdata: customdata,
        };

        // Push the actual data to the dataToPlot array
        if (show[0]) {
            // Put at the end if we want to have it on top
            dataToPlot.push(actualData);
        } else if (forDownload) {
            // Do nothing (don't add it)
        } else {
            dataToPlot.unshift(actualData);
        }
    }

    if ((base2 !== null) && (yvals2 !== null)) {
        let stripesColors: string[] = Array(yvals2.length).fill("#00000033");
        if (show[1]) {
            const yvalsMn = base2 ? yvals2.map((num, idx) => (num + base2[idx]) / 2) : [];
            stripesColors = yvalsToColors(yvalsMn);
        }

        // Prepare text and hovertemplate for the plot
        const hovertemplate = '%{text}<br><b>Min: </b>%{base:.1f} °C<br><b>Max</b>: %{customdata:.1f} °C';
        const customdata = base2?.map((val, i) => val + yvals2[i]);

        // Create the actual data for plotting
        const actualData = {
            type: "barpolar",
            mode: "lines",
            r: yvals2,
            base: base2,
            theta: xvals,
            fill: "toself",
            marker: { color: stripesColors },
            name: "",
            zorder: 10,
            text: text,
            hovertemplate: hovertemplate,
            textposition: "none",
            customdata: customdata,
        };

        // Push the actual data to the dataToPlot array
        if (show[1]) {
            dataToPlot.push(actualData);
        } else if (forDownload) {
            // Do nothing (don't add it)
        } else {
            dataToPlot.unshift(actualData);
        }
    }

    let iStart = 0
    if (monthIndex !== null) {
        if (monthIndex % 2 === 0) {
            iStart = 0
        } else {
            iStart = 1
        }
    }

    // Generate plot data for each stripe
    // This has to be done after the real data or hovertext doesn't work properly
    for (let i = iStart; i < (cumulDays.length - 1); i++) {
        let r = [dataRange.dataMinPlot];
        let theta = [cumulDays[i] * scaling];
        const jfinal = cumulDays[i + 1] + 1;
        for (let j = cumulDays[i]; j < jfinal; j++) {
            r.push(dataRange.dataMaxPlot);
            theta.push(j * scaling);
        }
        r.push(dataRange.dataMinPlot);
        theta.push(jfinal);

        const opacity = ((i % 2) === 0) ? 0.05 : 0
        const fillColor = (i !== monthIndex) ? `rgba(0, 0, 0, ${opacity})` : highlightColor

        const newData = {
            type: "scatterpolar",
            mode: "lines",
            r: r,
            color: 'transparent',
            theta: theta,
            fill: "toself",
            fillcolor: fillColor,
            line: {
                color: 'transparent',
            },
            name: "",
            zorder: -20,
        };
        dataToPlot.unshift(newData);
    }

    // Return the final dataToPlot array
    return dataToPlot;
}

function generatePolarLayout(width, height, dataRange, tickColor, annotationColor, monthIndex, fontSize) {
    let annotations: Annotation[] = [];

    const scaling = 360 / 366;
    const r = 1
    const degToR = (Math.PI / 180)
    const paperAspectRatio = height / width
    for (let i = 0; i < (MONTHS_SHORT.length); i++) {

        const theta = (cumulDays[i] + 15) * scaling
        const x = (r * Math.cos(theta * degToR) / 2 * paperAspectRatio) + 0.5
        const y = (r * Math.sin(theta * degToR) / 2 + 0.5)
        const newAnnotation = {
            x: x,
            y: y,
            xref: 'x domain',
            yref: 'y domain',
            showarrow: false,
            text: MONTHS_SHORT[i],
            font: {
                color: (monthIndex === i) ? "black" : annotationColor,
                weight: 1000,
                size: fontSize,
            },
        }
        annotations.push(newAnnotation)
    }

    const polarLayout = {
        polar: {
            barmode: "stack",
            bgcolor: BACKGROUND_COLOR,

            radialaxis: {
                showgrid: true,
                fixedrange: true,
                visible: true,
                range: [dataRange.dataMinPlot, dataRange.dataMaxPlot],
                layer: "above traces",
                tickmode: 'array',
                tickfont: {
                    color: tickColor,
                    size: fontSize,
                },
                angle: 37,
            },
            angularaxis: {
                showgrid: false,
                fixedrange: true,
                visible: false,
            },
        },
        width: `${width}`,
        height: `${height}`,
        showlegend: false,
        margin: {
            b: 1,
            l: 1,
            r: 1,
            t: 1,
        },
        font: {
            family: 'Lato, sans-serif',
        },
        hoverlabel: {
            font: {
                family: 'Lato, sans-serif',
            }
        },
        paper_bgcolor: BACKGROUND_COLOR,
        dragmode: false,
        annotations: annotations,
    }

    return polarLayout
}

function generateWindLayout(width, height, tickColor, annotationColor, fontSize) {
    let annotations: Annotation[] = [];

    const directions = ['North', ' ', 'N-E', ' ', 'East', ' ', 'S-E', ' ', 'South', ' ', 'S-W', ' ', 'West', ' ', 'N-W', ' ']

    const r = 1
    const degToR = (Math.PI / 180)
    const paperAspectRatio = height / width

    const angle = 360 / directions.length
    for (let i = 0; i < (directions.length as number); i++) {

        const theta = (90 - angle * i)
        const x = (r * Math.cos(theta * degToR) / 2 * paperAspectRatio) + 0.5
        const y = (r * Math.sin(theta * degToR) / 2 + 0.5)
        const newAnnotation = {
            x: x,
            y: y,
            xref: 'x domain',
            yref: 'y domain',
            showarrow: false,
            text: directions[i],
            font: {
                color: annotationColor,
                weight: 1000,
                size: fontSize,
            },
        }
        annotations.push(newAnnotation)
    }

    const polarLayout = {
        polar: {
            bgcolor: BACKGROUND_COLOR,
            barmode: "stack",

            radialaxis: {
                showgrid: true,
                fixedrange: true,
                visible: true,
                layer: "above traces",
                tickmode: 'array',
                tickfont: {
                    color: tickColor,
                    size: fontSize,
                },
                angle: 37,
            },
            angularaxis: {
                showgrid: false,
                fixedrange: true,
                visible: false,
                direction: "clockwise",
            },
        },
        width: `${width}`,
        height: `${height}`,
        showlegend: false,
        margin: {
            b: 1,
            l: 1,
            r: 1,
            t: 1,
        },
        font: {
            family: 'Lato, sans-serif',
        },
        hoverlabel: {
            font: {
                family: 'Lato, sans-serif',
            }
        },
        paper_bgcolor: BACKGROUND_COLOR,
        dragmode: false,
        annotations: annotations,
    }

    return polarLayout
}

function generateWindData(previousData, currentData, show, forDownload) {
    let dataToPlot: Record<string, any>[] = [];

    const directions16full = ['North', 'NNE', 'North-East', 'ENE', 'East', 'ESE', 'South East', 'SSE', 'South',
        'SSW', 'South West', 'WSW', 'West', 'WNW', 'North West', 'NNW']
    const interval = 22.5
    const angles = Array.from({ length: Math.floor(360 / interval) + 1 }, (_, i) => i * interval);
    const opacity = 'ee'
    const windColorsForDownload = [theme.colors.c3sGrey[2] + opacity, theme.colors.c3sYellow[2] + opacity, theme.colors.c3sYellow[6] + opacity, theme.colors.c3sYellow[9] + opacity]
    const windColorsMod = forDownload ? windColorsForDownload : windColors

    if ((previousData['cutoff_labels'] !== null) && (show[0])) {
        const cutoff_labels = previousData['cutoff_labels']
        const length = previousData['anglePcs_at_speed1'].length
        const data1 = [{
            r: previousData['anglePcs_at_speed1'],
            theta: angles,
            name: (forDownload ? cutoff_labels[0] + ' metres/sec' : ""),
            marker: { color: windColorsMod[0] },
            type: "barpolar",
            text: directions16full,
            customdata: Array(length).fill(cutoff_labels[0]),
            hovertemplate: '%{text}, %{customdata} m/s<br><b>Amount: </b>%{r:.1f}%',
            textposition: "none",
        }, {
            r: previousData['anglePcs_at_speed2'],
            theta: angles,
            name: (forDownload ? cutoff_labels[1] + ' metres/sec' : ""),
            marker: { color: windColorsMod[1] },
            type: "barpolar",
            text: directions16full,
            customdata: Array(length).fill(cutoff_labels[1]),
            hovertemplate: '%{text}, %{customdata} m/s<br><b>Amount: </b>%{r:.1f}%',
            textposition: "none",
        }, {
            r: previousData['anglePcs_at_speed3'],
            theta: angles,
            name: (forDownload ? cutoff_labels[2] + ' metres/sec' : ""),
            marker: { color: windColorsMod[2] },
            type: "barpolar",
            text: directions16full,
            customdata: Array(length).fill(cutoff_labels[2]),
            hovertemplate: '%{text}, %{customdata} m/s<br><b>Amount: </b>%{r:.1f}%',
            textposition: "none",
        }, {
            r: previousData['anglePcs_at_speed4'],
            theta: angles,
            name: (forDownload ? cutoff_labels[3] + ' metres/sec' : ""),
            marker: { color: windColorsMod[3] },
            type: "barpolar",
            text: directions16full,
            customdata: Array(length).fill(cutoff_labels[3]),
            hovertemplate: '%{text}, %{customdata} m/s<br><b>Amount: </b>%{r:.1f}%',
            textposition: "none",
        }]
        dataToPlot = dataToPlot.concat(data1)
    }

    if ((currentData['cutoff_labels'] !== null) && (show[1])) {
        const cutoff_labels = currentData['cutoff_labels']
        const length = currentData['anglePcs_at_speed1'].length
        const data2 = [{
            r: currentData['anglePcs_at_speed1'],
            theta: angles,
            name: (forDownload ? cutoff_labels[0] + ' metres/sec' : ""),
            marker: { color: windColorsMod[0] },
            type: "barpolar",
            text: directions16full,
            customdata: Array(length).fill(cutoff_labels[0]),
            hovertemplate: '%{text}, %{customdata} m/s<br><b>Amount: </b>%{r:.1f}%',
            textposition: "none",
        }, {
            r: currentData['anglePcs_at_speed2'],
            theta: angles,
            name: (forDownload ? cutoff_labels[1] + ' metres/sec' : ""),
            marker: { color: windColorsMod[1] },
            type: "barpolar",
            text: directions16full,
            customdata: Array(length).fill(cutoff_labels[1]),
            hovertemplate: '%{text}, %{customdata} m/s<br><b>Amount: </b>%{r:.1f}%',
            textposition: "none",
        }, {
            r: currentData['anglePcs_at_speed3'],
            theta: angles,
            name: (forDownload ? cutoff_labels[2] + ' metres/sec' : ""),
            marker: { color: windColorsMod[2] },
            type: "barpolar",
            text: directions16full,
            customdata: Array(length).fill(cutoff_labels[2]),
            hovertemplate: '%{text}, %{customdata} m/s<br><b>Amount: </b>%{r:.1f}%',
            textposition: "none",
        }, {
            r: currentData['anglePcs_at_speed4'],
            theta: angles,
            name: (forDownload ? cutoff_labels[3] + ' metres/sec' : ""),
            marker: { color: windColorsMod[3] },
            type: "barpolar",
            text: directions16full,
            customdata: Array(length).fill(cutoff_labels[3]),
            hovertemplate: '%{text}, %{customdata} m/s<br><b>Amount: </b>%{r:.1f}%',
            textposition: "none",
        }]
        dataToPlot = dataToPlot.concat(data2)
    }

    return dataToPlot
}

function deepMerge(target, source) {
    for (const key in source) {
        if (source.hasOwnProperty(key)) {
            // Check if the value is an object and not null, and not an array
            if (typeof source[key] === 'object' && source[key] !== null && !Array.isArray(source[key])) {
                // If the target does not have this key, create it
                if (!target[key]) {
                    target[key] = {};
                }
                // Recursively merge the objects
                deepMerge(target[key], source[key]);
            } else if (Array.isArray(source[key])) {
                // If it's an array, concatenate it with the target array (if it exists)
                target[key] = target[key] ? [...target[key], ...source[key]] : source[key];
            } else {
                // Otherwise, just assign the value from source to target
                target[key] = source[key];
            }
        }
    }
    return target;
}

function makePrecipText(binEdges, forTicks) {
    const suffix = forTicks ? "mm" : "mm/hour"
    const breakOrSpace = forTicks ? "<br>" : " "
    const text = binEdges.slice(0, -1).map((X, i) => {
        let X1 = Number(X.toFixed(2));              // Truncate X to 2 significant figures
        let X2 = Number(binEdges[i + 1].toFixed(2)); // Truncate the next value to 2 significant figures
        if (i === 0) {
            X1 = 0
        }
        if (i === 0) {
            return `Up to${breakOrSpace}${X2} ${suffix}`;
        } else if (i === binEdges.length - 2) {
            return `${X1}${suffix}${breakOrSpace}or more`;
        } else {
            return `${X1} to${breakOrSpace}${X2} ${suffix}`;
        }
    });
    return text;
}

// Function to format numbers to 2 significant figures
function formatPrecipValues(value) {
    if (value < 1) {
        return value.toPrecision(1);  // For numbers < 1, use toPrecision
    } else if (value > 1000) {
        return parseFloat(value.toPrecision(3)); // For numbers >= 1000, remove trailing zeros
    } else {
        return parseFloat(value.toPrecision(2)); // For numbers >= 1, remove trailing zeros
    }
}

function makeAnnotation(text: string, y: number, fontSize: number, forDownload: boolean = false) {
    const newAnnotation = {
        x: 0.5,
        y: y,
        xref: 'x domain',
        yref: 'y domain',
        showarrow: false,
        text: truncateString(text, forDownload ? 35 : 15),
        yanchor: 'middle',
        font: {
            color: "#00000055",
            weight: 1000,
            size: fontSize * 2,
        },
    }
    return newAnnotation;
}

function sanitiseVariable(variable) {
    return variable.replace(/ /g, '_').replace(/[^a-zA-Z0-9_]/g, '')
}

function sanitiseLocation(location): string {
    if (location === null) return "";

    const lat = Math.abs(location.lat).toFixed(2);
    const lng = Math.abs(location.lng).toFixed(2);

    const latDirection = location.lat >= 0 ? 'N' : 'S';
    const lngDirection = location.lng >= 0 ? 'E' : 'W';

    return `${lat}${latDirection}${lng}${lngDirection}`;
}

function arraysAreEqual(arr1: boolean[], arr2: boolean[]) {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
    }
    return true;
}

function getStripesHex(fraction, colors, scatterMax) {
    const margin = scatterMax * 2
    const fractionScaled = fraction * (1 - margin) + margin / 2  // Scale it a bit so we don't saturate at the ends too much

    const randomScatter = scatterMax * Math.sin((Math.random() - 0.5) * Math.PI)

    const indexRounded = Math.round((fractionScaled + randomScatter) * colors.length)
    const indexClamped = Math.min(Math.max(0, indexRounded), colors.length - 1)

    return colors[indexClamped]
}

function makeStripesBadgeGradient(nCols, colors, scatter) {
    const opacityHex = '88'
    let stripesString = `linear-gradient(90deg`
    const step = 100 / nCols
    for (let i = 0; i < nCols; i++) {
        const colorHex = getStripesHex(i / nCols, colors, scatter) + opacityHex
        const percent1 = i * step
        const percent2 = percent1 + step
        stripesString += `, ${colorHex} ${percent1}%, ${colorHex} ${percent2}%`
    }
    stripesString += `) `
    return stripesString
}

function makeThresholdGradient() {
    const color1 = 'rgb(40, 40, 40)'
    const color2 = 'rgb(80, 80, 80)'
    const color3 = 'rgb(40, 40, 40)'
    const gradient = `linear-gradient(90deg, ${color1} 0, ${color1} 28%, ${color2} 38%, ${color2} 62%, ${color3} 72%, ${color3} 100%)`
    return gradient
}

// Define the months and corresponding days in a leap year
const months = {
    "January": 31,
    "February": 29,  // 29 days in February during a leap year
    "March": 31,
    "April": 30,
    "May": 31,
    "June": 30,
    "July": 31,
    "August": 31,
    "September": 30,
    "October": 31,
    "November": 30,
    "December": 31
};

// Helper function to get the day suffix
function getDaySuffix(day) {
    if ((day >= 4 && day <= 20) || (day >= 24 && day <= 30)) {
        return "th";
    } else {
        const suffixes = { 1: "st", 2: "nd", 3: "rd" };
        return suffixes[day % 10] || "th";
    }
}

function getDaysLong() {
    // Create the list of days
    const days_long: string[] = [];

    for (const month in months) {
        const days = months[month];
        for (let day = 1; day <= days; day++) {
            const daySuffix = getDaySuffix(day);
            days_long.push(`${month} ${day}${daySuffix}`);
        }
    }

    return days_long;
}

function normalizeLongitude(longitude) {
    // Normalize the longitude to be within the range of -180 to 180
    longitude = ((longitude + 180) % 360 + 360) % 360 - 180;
    return longitude;
}

function formatLocation(latitude, longitude) {
    // Determine N/S for latitude
    let latDirection = latitude >= 0 ? 'N' : 'S';
    let formattedLat = Math.abs(latitude).toFixed(1) + latDirection;
    
    // Determine E/W for longitude
    let lonDirection = longitude >= 0 ? 'E' : 'W';
    let formattedLon = Math.abs(longitude).toFixed(1) + lonDirection;
    
    // Combine into a single string
    return `${formattedLat}, ${formattedLon}`;
}

export {
    generatePolarData, generatePolarLayout,
    getDataMinMax, getStripeColor,
    truncateString, generateWindLayout,
    generateWindData, formatPrecipValues,
    makeAnnotation, deepMerge,
    makePrecipText, sanitiseLocation,
    sanitiseVariable, arraysAreEqual,
    makeStripesBadgeGradient, makeThresholdGradient,
    getDaysLong, normalizeLongitude,
    formatLocation,
};