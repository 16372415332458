import React, { useState, useEffect, memo } from 'react';
import createPlotlyComponent from 'react-plotly.js/factory';
import CustomPlotly from './CustomPlotly.ts';  // Import the custom Plotly instance

const Plot = createPlotlyComponent(CustomPlotly);

function generateScatteredSineWave(amplitude, offset, scatter, length = 12) {
    let data: number[] = [];

    for (let i = 0; i < length; i++) {
        // Calculate the linear trend value
        let sineValue = Math.sin(i * Math.PI / length) * amplitude

        // Generate a random scatter value within the range of [-scatter, scatter]
        let randomScatter = (Math.random() * 2 - 1) * scatter;

        // Add the scatter to the trend value
        let scatteredValue = sineValue + randomScatter + offset;

        // Add the result to the data array
        data.push(scatteredValue);
    }

    return data;
}

function getRandomHexOpacity(min, max) {
    // Convert percentage to hexadecimal range
    const minHex = Math.floor(min * 255).toString(16).padStart(2, '0');
    const maxHex = Math.floor(max * 255).toString(16).padStart(2, '0');

    // Generate a random hex opacity value between min and max
    const minDecimal = parseInt(minHex, 16);
    const maxDecimal = parseInt(maxHex, 16);
    const randomDecimal = Math.floor(Math.random() * (maxDecimal - minDecimal + 1)) + minDecimal;

    return randomDecimal.toString(16).padStart(2, '0').toUpperCase();
}

function generateColorArray(baseColor, numColors, minOpacity, maxOpacity) {
    const colors: string[] = [];
    for (let i = 0; i < numColors; i++) {
        const opacityHex = getRandomHexOpacity(minOpacity, maxOpacity);
        colors.push(`${baseColor}${opacityHex}`);
    }
    return colors;
}

function GraphLoading({ width, height }) {
    useEffect(() => {
        console.log("Rendered GraphLoading")
    })

    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        // Set the component as mounted when the effect runs
        setIsMounted(true);

        return () => {
            // Cleanup function: Set the component as unmounted
            setIsMounted(false);
        };
    }, []);

    const yvals1 = generateScatteredSineWave(10, 5, 2, 4)
    const base = Array(4).fill(0)
    const xvals = [0, 1, 2, 3].map(val => val - 2.5)
    const markerColor = generateColorArray('#000000', 12, 0.15, 0.6);

    const plotType = "bar"
    const plotMode = "markers"
    const tickmode = "array"

    const bargap = 0.1
    const barcornerradius = 5
    const yAxisVisible = false
    const tickangle = -60
    const dtick = null
    const xAxisRange = null

    const dataToPlot1 = [{
        x: xvals,
        y: yvals1,
        base: base,
        mode: plotMode,
        type: plotType,
        name: "A",
        marker: { color: markerColor },
    }]

    const layout = {
        xaxis: {
            showgrid: false,
            fixedrange: true,
            range: xAxisRange,
            tickangle: tickangle,
            tickmode: tickmode, //  If "linear", the placement of the ticks is determined by a starting position `tick0` and a tick step `dtick`
            dtick: dtick,
            // zeroline: true,
            tickvals: xvals,
            ticktext: [" ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " "],
        },
        yaxis: {
            showgrid: true,
            fixedrange: true,
            anchor: 'free',
            position: 0.22,
            visible: yAxisVisible,
            tickfont: {
                weight: 1000,
                size: 15,
                color: "black",
            }
        },
        width: `${width}`,
        height: `${height}`,
        showlegend: false,
        margin: {
            b: 35,
            l: 5,
            r: 5,
            t: 0,
        },
        paper_bgcolor: 'transparent',
        plot_bgcolor: 'transparent',
        barcornerradius: barcornerradius,
        bargap: bargap,
        hovermode: false,
        dragmode: false,
    }

    const myStyle = {
        width: `${width}`,
        height: `${height}`,
    }

    return (
        <>
            {isMounted && dataToPlot1 && layout ? (
                <Plot
                    data={dataToPlot1}
                    layout={layout}
                    config={{
                        displayModeBar: false,
                    }}
                    style={myStyle}
                    className="graphLoadingAnimation"
                />
            ) : (
                <>
                </>
            )}
        </>
    )
}

export default memo(GraphLoading)