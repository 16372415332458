// Your geolocation function with abort controller
const fetchGeolocation = (
  signal: AbortSignal
): Promise<{ latitude: number; longitude: number }> => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (!signal.aborted) {  // Only resolve if not aborted
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          }
        },
        (error) => {
          if (!signal.aborted) {  // Reject only if not aborted
            console.error("GEO: Error getting location", error);
            reject(error);
          }
        }
      );

      // Abort handler
      signal.addEventListener("abort", () => {
        console.log("GEO: Geolocation request aborted.");
        reject(new Error("Geolocation request aborted by user."));
      });
    } else {
      console.error("GEO: Geolocation is not supported by this browser.");
      reject(new Error("Geolocation not supported by browser"));
    }
  });
};

export default fetchGeolocation;