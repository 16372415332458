async function fetchZarrData(query: string, signal: AbortSignal, retries = 3, delay = 1000) {
  const c3sAppsServer = "https://apps.copernicus-climate.eu/c3s-apps/era-explorer-server/"

  // =================================================================================================
  // HERE is where you choose the server
  // ngrok server should work for all, but will have to be changed each time you restart ngrok
  // =================================================================================================
  
  const server = c3sAppsServer
  // const server = process.env.BACKEND_URL || "/era-explorer/backend/" // Needed for real deployment on sites.ecmwf.int
  console.log("server", server)
  const apiUrl = server + query

  console.log("ZARR request: ", apiUrl)
  
  const fetchData = async (attempt: number): Promise<any> => {
  try {
    // Make the API call using fetch with an abort signal
    const response = await fetch(apiUrl, {
      method: "GET",
      mode: "cors",
      // credentials: 'include',  // Needed for real deployment but doesn't seem to work on my laptop
      headers: {
        'Content-Type': 'text/plain',
        'ngrok-skip-browser-warning': "1",
        },
      signal
    });

    // Check if the response is ok (status is in the range 200-299)
    if (!response.ok) {
      if (response.status >= 500 && response.status < 600) {
        throw new Error(`ZARR: Server error: ${response.status}`);
      }
      throw new Error(`ZARR: Network response was not ok: ${response.statusText}`);
    }

    // Convert the response to JSON
    const result = await response.json();
    console.log("ZARR: Data fetched successfully");
    return result;

  } catch (error) {
    // Handle abort errors differently from other errors
    if (error.name === 'AbortError') {
      console.log('ZARR: Fetch aborted');
      throw error; // No retries on abort
    }

    console.error(`ZARR: Error fetching data on attempt ${attempt}:`, error);

      // Retry on server errors (5xx) and network issues, but not for client errors (4xx)
      if (attempt < retries) {
        const nextDelay = delay * Math.pow(2, attempt); // Exponential backoff
        console.log(`ZARR: Retrying in ${nextDelay / 1000} seconds...`);
        await new Promise(res => setTimeout(res, nextDelay)); // Wait before retrying
        return fetchData(attempt + 1);
      }

      throw error; // Max retries reached, throw error
    }
  };

  return fetchData(1);
}

export default fetchZarrData;