import React from 'react';
import MapMarker from './MapMarker.tsx';
import { useSelector, TypedUseSelectorHook } from 'react-redux'
import { RootState } from '../../reducers/store.ts';

function MapMarkerContainer() {
    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

    const comparisonMode = useTypedSelector(state => state.data.comparisonMode)

    return (
        <>
            <MapMarker routeNumber={0} comparisonMode={comparisonMode} />
            {comparisonMode &&
                <>
                    <MapMarker routeNumber={1} comparisonMode={comparisonMode} />
                </>
            }
        </>
    )
}

export default MapMarkerContainer