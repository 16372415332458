import TemperaturePopup from './TemperaturePopup.tsx'
import { useSelector, TypedUseSelectorHook, useDispatch } from 'react-redux'
import { Marker } from 'react-leaflet';
import { RootState } from '../../reducers/store.ts';
import React, { useEffect, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import L from 'leaflet';
import useUpdatePositionAndData from '../../hooks/useUpdatePositionAndData.ts';
import MarkerTooltip from './MarkerTooltip.tsx';
import { updateAnnualAverageTemp, updateMaxRainfall, updateMonthlyTemp } from '../../reducers/progressSlice.ts';

const average = arr => arr.reduce((p, c) => p + c, 0) / arr.length;

var blueIcon = new L.Icon({
    iconUrl: process.env.PUBLIC_URL + '/mapMarkers/marker-icon-2x-c3sblue.png',
    shadowUrl: process.env.PUBLIC_URL + '/mapMarkers/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

var redIcon = new L.Icon({
    iconUrl: process.env.PUBLIC_URL + '/mapMarkers/marker-icon-2x-c3sred.png',
    shadowUrl: process.env.PUBLIC_URL + '/mapMarkers/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

function MapMarker({ routeNumber, comparisonMode }) {
    useEffect(() => {
        console.log("Rendered MapMarker")
    })

    const variableKey = 'tempAnn'

    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

    const averageTempRaw = useTypedSelector(state => state.data[variableKey][routeNumber].data.values)
    const averageRainfallRaw = useTypedSelector(state => state.data['precipAnn'][routeNumber].data.values)
    const monthlyTempRawMin = useTypedSelector(state => state.data['tempMonthly'][routeNumber].data.min)
    const monthlyTempRawDiff = useTypedSelector(state => state.data['tempMonthly'][routeNumber].data.diff)
    const averageTemp = ((averageTempRaw === null) || (averageTempRaw === undefined)) ? "Calculating..." : `${average(averageTempRaw).toFixed(1)} °C`;

    // ======================== 
    // For progress tracking
    const dispatch = useDispatch()

    useEffect(() => {
        if ((averageTempRaw !== null) && (averageTempRaw !== undefined)) {
            dispatch(updateAnnualAverageTemp(average(averageTempRaw)))
        }

        if ((averageRainfallRaw !== null) && (averageRainfallRaw !== undefined)) {
            dispatch(updateMaxRainfall(average(averageRainfallRaw)))
        }

        if ((monthlyTempRawMin !== null) && (monthlyTempRawMin !== undefined) && (monthlyTempRawDiff !== null) && (monthlyTempRawDiff !== undefined)) {
            // Add arrays element by element
            const monthlyTempRawMax = monthlyTempRawMin.map((minTemp, index) =>
                minTemp + monthlyTempRawDiff[index]
            );
            // console.log("DEBUG TRACKPROGRESS", monthlyTempRawMax)
            dispatch(updateMonthlyTemp(monthlyTempRawMax))
        }
    }, [dispatch, averageTempRaw, averageRainfallRaw, monthlyTempRawMin, monthlyTempRawDiff])
    // ======================== 
    // ======================== 

    const locationPosition = useTypedSelector(state => {
        switch (comparisonMode) {
            case true:
                switch (routeNumber) {
                    case 1:
                        return state.location.locationPosition;
                    case 0:
                        return state.location.locationPosition2;
                    default:
                        throw new Error('Invalid route number');
                }
            case false:
                return state.location.locationPosition;
            default:
                throw new Error('Invalid comparison mode');
        }
    });

    const locationName = useTypedSelector(state => {
        switch (comparisonMode) {
            case true:
                switch (routeNumber) {
                    case 1:
                        return state.location.locationName;
                    case 0:
                        return state.location.locationName2;
                    default:
                        throw new Error('Invalid route number');
                }
            case false:
                return state.location.locationName;
            default:
                throw new Error('Invalid comparison mode');
        }
    });

    const clickOrTap = isMobile ? "Tap" : "Click or drag"
    const clickOrTapShort = isMobile ? "Tap" : "Click"

    const { updatePositionAndData } = useUpdatePositionAndData();

    const eventHandlers = useMemo(
        () => ({
            dragend(e) {
                const newLatLng = e.target.getLatLng()
                const lat = newLatLng.lat
                const lng = newLatLng.lng;
                updatePositionAndData(lat, lng, routeNumber, null, 'dragged')
                console.log("dragend newLatLng", newLatLng)
            },
        }),
        [updatePositionAndData, routeNumber],
    )

    if (locationPosition.lat === null || locationPosition.lng === null) {
        return null
    }

    const noSecondLocation = false

    const extraText = (comparisonMode && noSecondLocation) ? `.</br>${clickOrTapShort} somewhere to compare` : ""

    let icon, content, locked
    if (routeNumber === 0) {
        icon = redIcon
        content = comparisonMode ? `Location currently locked${extraText}` : `${clickOrTap} somewhere</br>to change this location`
        locked = comparisonMode
    } else {
        icon = blueIcon
        content = `${clickOrTap} somewhere</br>to change this location`
        locked = false
    }

    // Calculate markers at intervals of 360 degrees longitude
    let markerPositions: { lat: number; lng: number; }[] = []
    for (let i = -1; i <= 1; i += 1) {
        const newPosition = {
            lat: locationPosition.lat,
            lng: locationPosition.lng + i * 360
        }
        markerPositions.push(newPosition);
    }

    const draggable = (!locked && !isMobile)

    return (
        <>
            {markerPositions.length > 0 && markerPositions.map((pos, index) => (
                <Marker
                    position={pos}
                    key={index}
                    icon={icon}
                    riseOnHover={true}
                    draggable={draggable}
                    eventHandlers={eventHandlers}
                >
                    {/* This is a horrendous hack, but this is here to force a rerender of the tooltips
                        AND the popup border stuff (via the class name)
                        which SHOULD be happening automatically, but isn't. */}
                    <div key={comparisonMode} >
                        <TemperaturePopup
                            position={pos}
                            placeName={locationName}
                            averageTemp={averageTemp}
                            locked={locked}
                        />

                        <MarkerTooltip content={content} />
                    </div>
                </Marker>
            ))}
        </>
    );
}

export default MapMarker