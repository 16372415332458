import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux'
import { updatePosition, updateName, toggleVelocity, cancelGeolocation } from '../reducers/locationSlice.ts'
import useFetchData from './useFetchData.ts';
import useFindNearestPlace from './useFindNearestPlace.ts';
import {
    setVariablesLoading,
    updateNamesInData,
    setFetchingData,
} from '../reducers/dataSlice.ts'
import store from '../reducers/store.ts';
import { variableOptions } from '../config/constants.ts';
import { useSequenceAbortController } from '../components/nonUI/SequenceAbortControllerProvider.tsx';
import { nprogress } from '@mantine/nprogress';
import { setRouteOneComplete } from '../reducers/progressSlice.ts';

function customOrderArray(arr, startIdx) {
    // Reorders based on the start index and doesn't include duplicates (e.g. temperature annual which is used twice)
    const n: number = arr.length;
    const n_half: number = Math.ceil(arr.length / 2)
    const result = [arr[startIdx]];

    for (let i = 0; i < n_half; i++) {
        const leftIdx = (startIdx + n - 1 - i) % n;
        const rightIdx = (startIdx + 1 + i) % n

        const newKeyLeft = Object.keys(arr[leftIdx])[0];

        // Check if the key already exists
        const existsLeft = result.some(item => Object.keys(item)[0] === newKeyLeft);

        if (!existsLeft) {
            result.push(arr[leftIdx]);
        }

        const newKeyRight = Object.keys(arr[rightIdx])[0];

        // Check if the key already exists
        const existsRight = result.some(item => Object.keys(item)[0] === newKeyRight);

        if (!existsRight) {
            result.push(arr[rightIdx]);
        }
    }

    return result;
}


const useUpdatePositionAndData = () => {
    const dispatch = useDispatch();
    const { fetchData } = useFetchData();
    const { findNearestPlace } = useFindNearestPlace();

    // Memoize the keys array to avoid recalculating it on every render
    const keys = useMemo(() => {
        return [...new Set(variableOptions.map(option => option.sliceKey))];
    }, []);

    const { sequenceAbortController, abortControllers } = useSequenceAbortController();

    const fetchSequentialData = useCallback(async (lat, lng, routeNumber, routesAndAbortControllersOrdered) => {
        sequenceAbortController![routeNumber].current.abort(); // Abort the previous sequence
        sequenceAbortController![routeNumber].current = new AbortController(); // Create a new AbortController for the new sequence

        // Set all plots to loading state
        dispatch(setVariablesLoading({
            routeNumber: routeNumber,
            locationPosition: { lat, lng }
        }
        ));

        try {
            console.log("BEGINNING SEQUENTIAL DATA GET", routeNumber);
            dispatch(setFetchingData({ routeNumber: routeNumber, fetchingData: true }));

            if (routeNumber === 1) {
                dispatch(setRouteOneComplete(false));
            }

            for (let item of routesAndAbortControllersOrdered) {
                let route = Object.keys(item)[0];
                let abortController = item[route];
                console.log("AWAITING DATA FROM", route, routeNumber);
                await fetchData(lat, lng, routeNumber, route, `updatePositionAndData`, abortController);
                console.log("GOT DATA FROM", route, routeNumber);
            }

            console.log("COMPLETED SEQUENTIAL DATA GET", routeNumber);
            dispatch(setFetchingData({ routeNumber: routeNumber, fetchingData: false }));
            if (routeNumber === 1) {
                // Progress tracking. The user could quit comparison mode before the data is fetched
                // and this will still be set, so I will have to check that the user is still in comparison mode
                dispatch(setRouteOneComplete(true));
            }

            const fetchingData = store.getState().data.fetchingData
            if (!fetchingData.some(val => val)) {
                nprogress.complete();
            }
        } catch (error) {
            console.log("ABORTING SEQUENTIAL DATA GET", routeNumber);
        }
    }, [dispatch, fetchData, sequenceAbortController]);

    const updatePositionAndData = useCallback((
        lat: number, lng: number, routeNumber: number,
        newPlaceName: string | null, debug: string,
        fromURL: boolean = false, fromComparisonURL: boolean = false
    ) => {
        console.log(`updatePositionAndData: Called by ${debug}`, newPlaceName);

        dispatch(toggleVelocity());
        if (!fromURL) {
            dispatch(updatePosition({ lat, lng }));
        }
        if (debug !== 'geolocation') {
            dispatch(cancelGeolocation());
        }

        // Abort all current requests
        keys.forEach(key => {
            abortControllers[routeNumber].current[key]?.abort();
            abortControllers[routeNumber].current[key] = new AbortController();
        });

        const dataOrder = variableOptions.map(option => ({
            [option.route]: abortControllers[routeNumber].current[option.sliceKey]
        }));

        const prioritySlide = store.getState().data.prioritySlide;
        const dataReordered = customOrderArray(dataOrder, prioritySlide);

        // Fetch sequential data
        nprogress.reset();
        nprogress.start();
        fetchSequentialData(lat, lng, routeNumber, dataReordered);

        if (newPlaceName === null) {
            findNearestPlace(lat, lng, routeNumber, fromComparisonURL, `updatePositionAndData+${debug}`);
        } else {
            dispatch(updateName(newPlaceName));
            const newLocationInfo = {
                placeName: newPlaceName,
                position: { lat, lng },
            };
            dispatch(updateNamesInData({
                routeNumber: routeNumber,
                locationInfo: newLocationInfo
            }));
        }
    }, [dispatch, fetchSequentialData, findNearestPlace, keys, abortControllers]);

    return { updatePositionAndData };
};

export default useUpdatePositionAndData;