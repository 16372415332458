import React, { memo } from 'react';
import { Badge, rem, Tooltip } from '@mantine/core';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { RootState } from '../../reducers/store.ts';
import { truncateString } from '../../utils/helperFunctions.ts'
import { IconX, IconCircleFilled } from '@tabler/icons-react';
import { BOX_SHADOW, variableOptions } from '../../config/constants.ts';

function ComplexBadge({
    label,
    onClick,
    showBadge,
    color,
    textColorSelected,
    textColorUnselected,
    blankBackgroundColor,
    ready,
    landscape,
    backgroundGradient = "",
    gradientButton = false,
    animationDelay = 0,
    variable = "",
    current = true,
    ignoreAttention = false,
    overrideReady = false,
    comparisonMode = false,
    tooltipLabel = "Toggle location",
}) {

    const borderColor = !gradientButton ? color : '#333'

    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

    const attentionReceived = useTypedSelector(state => state.graph.attentionReceived)

    const attentionReceivedAndListening = (ignoreAttention) ? true : attentionReceived

    const locationName = useTypedSelector((state) => {
        switch (current) {
            case true:
                const sliceKey = variableOptions.find((element) => element.name === variable)?.sliceKey;
                if (sliceKey !== undefined) {
                    return state.data[sliceKey][0].metadata.locationName;
                } else {
                    return null
                }
            case false:
                const sliceKey2 = variableOptions.find((element) => element.name === variable)?.sliceKey;
                if (sliceKey2 !== undefined) {
                    return state.data[sliceKey2][1].metadata.locationName;
                } else {
                    return null
                }
        }
    });

    const gotData = useTypedSelector((state) => {
        switch (current) {
            case true:
                const sliceKey = variableOptions.find((element) => element.name === variable)?.sliceKey;
                if (sliceKey !== undefined) {
                    return state.data[sliceKey][0].metadata.gotData;
                } else {
                    return null
                }
            case false:
                const sliceKey2 = variableOptions.find((element) => element.name === variable)?.sliceKey;
                if (sliceKey2 !== undefined) {
                    return state.data[sliceKey2][1].metadata.gotData;
                } else {
                    return null
                }
        }
    });

    let locationNameTrunc: string | null = null
    if ((gotData) && (locationName !== null)) {
        locationNameTrunc = truncateString(locationName, landscape ? 20 : 15)
    }

    const icon = (current ? <IconCircleFilled stroke={5} style={{ width: rem(13), height: rem(13) }} /> : <IconX stroke={5} style={{ width: rem(15), height: rem(15) }} />);

    const rightSection = ((variable === "Frost days etc") && comparisonMode) ? icon : null;

    const removeClass = (!ready && !overrideReady) ? 'removeFromPng' : ''

    return (
        <Tooltip label={tooltipLabel}>
            <Badge
                component='span'
                className={(!attentionReceivedAndListening && (ready || overrideReady)) ? `badgeButton pulse badgesNamedForCloning ${removeClass}` : `badgeButton badgesNamedForCloning ${removeClass}`}
                onClick={onClick}
                style={{
                    borderWidth: 2,
                    borderColor: borderColor,
                    backgroundColor: (backgroundGradient === "") ? (showBadge ? color : blankBackgroundColor) : "transparent",
                    color: showBadge ? textColorSelected : textColorUnselected,
                    animationDelay: `${animationDelay}s`,  // So the badges don't always animate together
                    backgroundImage: backgroundGradient,
                    boxShadow: ((ready || overrideReady) ? BOX_SHADOW : ''),
                    marginRight: "3px",
                }}
                rightSection={rightSection}
            >
                {((locationNameTrunc !== null) ? locationNameTrunc : label)}
            </Badge>
        </Tooltip>
    )
};

export default memo(ComplexBadge)