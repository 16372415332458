import { useSelector, TypedUseSelectorHook } from 'react-redux'
import { RootState } from '../../reducers/store.ts';
import React, { useEffect, memo, useState } from 'react';
import { isMobile } from 'react-device-detect';

function ColorBars() {
    useEffect(() => {
        console.log("Rendered ColorBars")
    })

    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

    const spectralPalette = useTypedSelector(state => state.graph.spectralPalette)
    const multipanel = useTypedSelector(state => state.graph.multipanel)

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener("orientationchange", handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('orientationchange', handleResize);
        };
    }, []);

    const minWidthForMobileLandscape = 600

    const graphsOnBottom = (windowSize.width < windowSize.height) || multipanel
    const isNarrowMobile = isMobile && (windowSize.width < minWidthForMobileLandscape)

    const path = process.env.PUBLIC_URL + '/colorBars/'
    const prefix = spectralPalette ? 'spectral' : 'mono'
    const orientation = isNarrowMobile ? 'vertical' : 'horizontal'

    const imageName = path + prefix + '_' + orientation + '_colorBar.png'

    const baseStyle: React.CSSProperties = {
        position: "fixed",
        pointerEvents: "none",
        overflow: 'visible',
        backgroundColor: "#ffffff66",
        borderRadius: "10px",
        WebkitBackdropFilter: 'blur(2px)', /* Adjust the blur level */
        backdropFilter: 'blur(2px)', /* Adjust the blur level */
        willChange: "transform, backdrop-filter",
    }

    const barLength = 300

    const styleMobile: React.CSSProperties = {
        bottom: `${graphsOnBottom ? 20 : 10}px`,
        right: `${graphsOnBottom ? 5 : 20}px`,
        height: `${barLength}px`,
    }

    const styleHorizontal: React.CSSProperties = {
        bottom: `${graphsOnBottom ? 25 : 10}px`,
        minWidth: "500px",
        left: "50%",
        transform: "translateX(-50%)", /* Center horizontally */
    }

    const baseImageStyle: React.CSSProperties = {
        opacity: 1,  // The actual bar colours have lower opacity
        display: 'flex',
    }

    const imageStyleMobile: React.CSSProperties = {
        width: "auto",
        height: `${barLength}px`,
    }

    const imageStyleHorizontal: React.CSSProperties = {
        width: "500px",
        height: "auto",
        alignItems: 'center', /* Center vertically */
    }

    let style: React.CSSProperties
    let imageStyle: React.CSSProperties

    if (isNarrowMobile) {
        imageStyle = {
            ...imageStyleMobile,
            ...baseImageStyle,
        }
        style = {
            ...styleMobile,
            ...baseStyle,
        }
    } else {
        imageStyle = {
            ...imageStyleHorizontal,
            ...baseImageStyle,
        }
        style = {
            ...styleHorizontal,
            ...baseStyle,
        }
    }


    return (
        <div style={style}>
            <img src={imageName} alt="Color bar" style={imageStyle} />
        </div>
    );
}

export default memo(ColorBars)