import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setChromeIOSRotated } from '../../reducers/graphSlice.ts';

// Extend the Navigator type to include `standalone`
interface Navigator {
  standalone?: boolean;
}

const isChromeOniOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  const isIOS = /iPad|iPhone|iPod/.test(userAgent);
  const isStandalone = (navigator as Navigator).standalone;

  // Check if it's Chrome on iOS or in standalone mode on iOS
  return (isIOS && /CriOS/i.test(userAgent)) || (isIOS && isStandalone);
};


const HandleChromeIOSRotation = () => {
  const dispatch = useDispatch()

  const [orientation, setOrientation] = useState(
    window.innerWidth > window.innerHeight ? 'landscape' : 'portrait'
  );

  useEffect(() => {
    const handleResize = () => {
      const isLandscape = window.innerWidth > window.innerHeight;
      const newOrientation = isLandscape ? 'landscape' : 'portrait';

      if (newOrientation !== orientation) {
        setOrientation(newOrientation);

        // Check if on Chrome iOS and log or handle accordingly
        if (isChromeOniOS()) {
          console.log(`HandleChromeIOSRotation: Orientation changed to ${newOrientation} on Chrome iOS`);
          // Handle specific logic for Chrome on iOS here
          dispatch(setChromeIOSRotated())
        } else {
          console.log(`HandleChromeIOSRotation: Orientation changed to ${newOrientation}`);
        }
      }
    };

    window.addEventListener('orientationchange', handleResize);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('orientationchange', handleResize);
      window.removeEventListener('resize', handleResize);
    }
  }, [orientation, dispatch]);


  return null
};

export default HandleChromeIOSRotation;
