import React, { memo, useEffect } from 'react';
import { Switch } from '@mantine/core';
import { theme } from '../../theme.tsx';
import { BOX_SHADOW } from '../../config/constants.ts';


function ToggleSwitch({ handleClick, label, width, defaultChecked, disabled=false}) {
    useEffect(() => {
        console.log("Rendered ToggleSwitch")
    })

    return (
        <Switch
            label={label}
            size="xl"
            onLabel="ON"
            offLabel="OFF"
            color={theme.colors.c3sRed[9]}
            onClick={handleClick}
            defaultChecked={defaultChecked}
            disabled={disabled}
            styles={{
                label: {
                    fontSize: '1rem',
                },
                track: {
                    width: `${width}px`,
                    minWidth: `${width}px`,
                    maxWidth: `${width}px`,
                    boxShadow: BOX_SHADOW,
                },
                thumb: {
                    boxShadow: BOX_SHADOW,
                },
            }}
        />
    );
}

export default memo(ToggleSwitch);
