// src/middleware/urlSyncMiddleware.js
import { updateUrlFromState } from '../utils/urlState.ts';

const urlSyncMiddleware = store => next => action => {
  const result = next(action);
  const state = store.getState();
  updateUrlFromState(state);
  return result;
};

export default urlSyncMiddleware;