import Papa from 'papaparse';

let countryCapitalCache: Record<string, string> | null = null;

const loadCapitalData = async (): Promise<Record<string, string>> => {
    // Check if data is already loaded and cached
    if (countryCapitalCache) {
        return countryCapitalCache;
    }

    // If not, load and parse the CSV data
    const csvUrl = `/filtered_cleaned_capitals.csv`;
    const response = await fetch(csvUrl);

    if (!response.ok) {
        throw new Error('OSM: Failed to load CSV file');
    }

    const csvText = await response.text();
    const countryCapitalMap: Record<string, string> = {};

    // Parse CSV and build the map with lowercase country names
    Papa.parse(csvText, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
            results.data.forEach((row: any) => {
                const countryKey = row.Country.toLowerCase();
                if (!countryCapitalMap[countryKey]) {
                    countryCapitalMap[countryKey] = row.City;
                }
            });
        }
    });

    // Cache the parsed data for future calls
    countryCapitalCache = countryCapitalMap;
    return countryCapitalMap;
};

function labelFromAdminLevels(admin, label, allowAll) {
    // Extract the levels and their corresponding values
    const levels = Object.keys(admin).filter(key => key.startsWith('level')).map(key => ({
        level: parseInt(key.replace('level', '')),
        value: admin[key]
    }));

    // Sort the levels in descending order
    levels.sort((a, b) => b.level - a.level);

    console.log('labelFromAdminLevels', levels)
    if (levels.length > 0 && !allowAll) {
        if (levels[levels.length - 1].level <= 2) {
            throw new Error('OSM: Got country as lowest level, returning lat/long instead');
        } else if ((levels.length === 1) && (levels[0].level <= 4)) {
            throw new Error('OSM: Got country as lowest level (and just one level), returning lat/long instead');
        }
    }


    // Check if we have at least two levels
    if (levels.length === 1) {
        // If a single level, can we use the label instead?
        if (label.includes(',')) {
            return trimAfterSecondComma(label);
        } else {
            return levels[0].value;
        }
    } else if (levels.length === 0) {
        return "Unknown"
    } else {
        // Get the highest and second highest levels
        const highestLevel = levels[0].value;
        const secondHighestLevel = levels[1].value;

        // Return the concatenated string
        return `${highestLevel}, ${secondHighestLevel}`;
    }
}

function trimAfterSecondComma(input) {
    const parts = input.split(',');
    return parts.length > 2 ? parts.slice(0, 2).join(',') : input;
}

async function fetchPlaceName(query: string, defaultPlaceName: string, signal: AbortSignal) {
    const server = "https://nominatim.openstreetmap.org/"
    const apiUrl = server + query

    try {
        // Make the API call using fetch with an abort signal
        const response = await fetch(apiUrl, {
            method: "GET",
            mode: "cors",
            headers: {
                'Content-Type': 'text/plain',
                'User-Agent': 'ERA-explorer',  // Replace with your app name and version
                'Referer': 'https://cds.climate.copernicus.eu/apps/c3s/app-era5-explorer', // Replace with your actual domain
            },
            signal
        });

        console.log('fetchPlaceNames', apiUrl)
        console.log('fetchPlaceNames response', response)

        // Check if the response is ok (status is in the range 200-299)
        if (!response.ok) {
            throw new Error('OSM: fetchPlaceNames Network response was not ok');
        }

        // Convert the response to JSON
        const data = await response.json();

        let result = defaultPlaceName
        try {
            const adminLevels = data.features[0].properties.geocoding.admin;
            result = labelFromAdminLevels(adminLevels, data.features[0].properties.geocoding.label, false)
        } catch (error2) {
            console.log("OSM: Error with geocoding (likely ocean point): " + error2)
        }

        console.log('OSM: fetchPlaceNames result', result)

        // Return the JSON data
        return result;
    } catch (error) {
        // Handle abort errors differently from other errors
        if (error.name === 'AbortError') {
            console.log('OSM: fetchPlaceNames Fetch aborted');
        } else {
            console.error('OSM: fetchPlaceNames Error fetching data:', error);
        }
        throw error;
    }
}

async function fetchPlaceList(query: string, signal: AbortSignal, retry: boolean = true) {
    const apiUrl = `https://nominatim.openstreetmap.org/search?q=${query}&format=geocodejson&addressdetails=1`
    console.log("OSM: queryURL", apiUrl);

    try {
        // Make the API call using fetch with an abort signal
        const response = await fetch(apiUrl, {
            method: "GET",
            mode: "cors",
            headers: {
                'Content-Type': 'text/plain',
                'User-Agent': 'ERA-explorer',  // Replace with your app name and version
                'Referer': 'https://cds.climate.copernicus.eu/apps/c3s/app-era5-explorer', // Replace with your actual domain
            },
            signal
        });

        // Check if the response is ok (status is in the range 200-299)
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        // Convert the response to JSON
        const data = await response.json();

        console.log('OSM: fetchPlaceList RAW', retry, data)

        // Only look for low level features (high admin numbers)...
        let namesAndLocations = data.features.filter(feature => {
            const admin = feature.properties.geocoding.admin;
            // return admin && (admin.level6 || admin.level7 || admin.level8);
            return admin && (admin.level4 || admin.level5 || admin.level6 || admin.level7 || admin.level8);
            // return admin && (admin.level5 || admin.level6 || admin.level7 || admin.level8);  // Otherwise we lose e.g. Tokyo
        }).reduce((acc, feature) => {
            // const label = feature.properties.geocoding.label;
            const label = labelFromAdminLevels(feature.properties.geocoding.admin, feature.properties.geocoding.label, true);
            const position = [feature.geometry.coordinates];

            // Check if label is already in the Set
            if (!acc.seenLabels.has(label) && (label !== 'Unknown')) {
                acc.seenLabels.add(label); // Add label to Set
                acc.result.push([label, position]); // Push to result array
            }
            return acc;
        }, { result: [], seenLabels: new Set() }).result;

        const maxOptionsToDisplay = 4;
        namesAndLocations = namesAndLocations.slice(0, maxOptionsToDisplay)  // Now truncate to some number

        // Check if the list is empty, meaning the search might be a country
        if (namesAndLocations.length === 0) {
            // Load capital data and check for a capital
            const countryCapitalMap = await loadCapitalData();
            const country = query.toLowerCase()
            const capital = countryCapitalMap[country];
            
            if (capital) {
                // Retry search with the capital city
                const newQuery = `${capital}, ${country}`;
                console.log(`OSM: Retrying search with new query: ${newQuery}`);
                return await fetchPlaceList(newQuery, signal, retry=false);
            }
        }

        // Return the JSON data
        console.log('OSM: fetchPlaceList', namesAndLocations)
        return namesAndLocations;
    } catch (error) {
        // Handle abort errors differently from other errors
        if (error.name === 'AbortError') {
            console.log('OSM: Fetch aborted');
        } else {
            console.error('OSM: Error fetching data:', error);
        }
        throw error;
    }
}

export { fetchPlaceList, fetchPlaceName }