import { createSlice } from '@reduxjs/toolkit'

// Define the state type
interface ProgressState {
  progressInt: number;
  progressIndicator: boolean;

  openedProgress: boolean;
  viewedSlides: number[];
  annualAverageTemp: number;
  rainSelected: boolean;
  maxRainfall: number;
  routeOneComplete: boolean;
  monthlySelected: boolean;
  monthlyTemp: number[];
  sharedStripes: boolean;
}

const progressString = localStorage.getItem('progress');
const progressInt = parseInt((progressString ?? "-1"), 10); // Use base 10 for the integer conversion

const initialState: ProgressState = {
  // The highest index of completed steps
  progressInt: progressInt,
  progressIndicator: false,

  openedProgress: false,
  viewedSlides: [],
  annualAverageTemp: 0,
  rainSelected: false,
  maxRainfall: 0,
  routeOneComplete: false,
  monthlySelected: false,
  monthlyTemp: [-99, 0],
  sharedStripes: false,
}

export const progressSlice = createSlice({
  name: 'progress',
  initialState,
  reducers: {
    incrementAndResetProgress: state => {
      state.progressInt = state.progressInt + 1
      localStorage.setItem('progress', state.progressInt.toString())

      state.progressIndicator = true

      state.openedProgress = false
      state.viewedSlides = []
      state.annualAverageTemp = 0
      // state.rainSelected = false  // Don't reset this as it might be confusing
      state.maxRainfall = 0
      state.routeOneComplete = false
      state.monthlySelected = false
      state.monthlyTemp = [-99, 0]
      state.sharedStripes = false
    },

    setProgressIndicator: (state, action) => {
      state.progressIndicator = action.payload
    },

    updateOpenedProgress: (state, action) => {
      state.openedProgress = action.payload
    },

    updateViewedSlides: (state, action) => {
      const viewedSlides = new Set(state.viewedSlides)
      viewedSlides.add(action.payload)
      state.viewedSlides = Array.from(viewedSlides)
    },

    updateAnnualAverageTemp: (state, action) => {
      state.annualAverageTemp = action.payload
    },

    setRainSelected: (state, action) => {
      state.rainSelected = action.payload
    },

    updateMaxRainfall: (state, action) => {
      state.maxRainfall = action.payload
    },

    setRouteOneComplete: (state, action) => {
      state.routeOneComplete = action.payload
    },

    setMonthlySelected: (state, action) => {
      state.monthlySelected = action.payload
    },

    updateMonthlyTemp: (state, action) => {
      state.monthlyTemp = action.payload
    },

    setSharedStripes: (state, action) => {
      state.sharedStripes = action.payload
    },
  }
})

// Action creators are generated for each case reducer function
export const {
  incrementAndResetProgress, 
  updateOpenedProgress, updateViewedSlides,
  updateAnnualAverageTemp, setProgressIndicator,
  setRainSelected, updateMaxRainfall,
  setRouteOneComplete, setMonthlySelected,
  updateMonthlyTemp, setSharedStripes,
} = progressSlice.actions

export default progressSlice.reducer