import { Group, Text, Accordion } from '@mantine/core';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { IconFlask, IconBinoculars } from '@tabler/icons-react';
import { RootState } from '../../reducers/store.ts';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import SettingsButtonWithPopup from '../inputs/SettingsButtonWithPopup.tsx';
import { FROST_DAY, SUMMER_DAY, TROPICAL_NIGHT } from '../../config/constants.ts';

interface AccordionLabelProps {
    label: string;
    image: React.ReactNode;
    description: string;
}

const lineHeight = "80%"
const myStyle = { lineHeight: lineHeight, marginBottom: "0.5em" }

function AccordionLabel({ label, image, description }: AccordionLabelProps) {

    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
    const multipanel = useTypedSelector(state => state.graph.multipanel)

    return (
        <Group wrap="nowrap">
            {image}
            <div>
                <Text style={(!isMobile && !multipanel) ? myStyle : {}}>{label}</Text>
                {(!isMobile && !multipanel) &&
                    <Text style={{ lineHeight: "100%" }} size="md" c="dimmed" fw={400}>
                        <i>{description}</i>
                    </Text>
                }
            </div>
        </Group>
    );
}

function ContentExplore({ variable, comparisonMode }) {
    const tapOrClick = isMobile ? "Tap" : "Select";
    const buttonWidth = 23
    const style: React.CSSProperties = {
        display: 'inline-block',
        margin: '0',
        padding: '0',
        verticalAlign: 'top',
        lineHeight: '0',
        marginLeft: 3,
        marginRight: 3,
    }
    const showIcon = comparisonMode ? '' : <>({tapOrClick}<div style={style}><SettingsButtonWithPopup buttonWidth={buttonWidth} /></div>)</>

    switch (variable) {
        case "Temperature monthly climatology":
        case "Temperature daily climatology":
            return (
                <>
                    Compare locations {showIcon} near the ocean with those further inland.
                    Due to its high <b>heat capacity</b>, water heats up and cools down more slowly than land,
                    so temperatures over the ocean are often more stable than those in landlocked regions.
                </>
            )
        case "Frost days etc":
            return (
                <>
                    Compare locations {showIcon} near the ocean with those further inland.
                    Due to its high <b>heat capacity</b>, water heats up and cools down more slowly than land,
                    so temperatures over the ocean are often more stable than those in landlocked regions.
                    Some coastal regions might rarely hit these thresholds, while inland areas could experience
                    both hot and cold extremes throughout the year.`;
                </>
            )
        case "Precip hourly hist":
        case "Precip monthly climatology":
        case "Precip annual timeseries":
            return (
                <>
                    Compare locations {showIcon} on different sides of mountain ranges, like the Andes in South America.
                    {' '}<b>Orographic lift</b> as air rises over mountains increases the relative humidity.
                    This can lead to more rain on the side facing the wind, while the other side stays drier.
                </>
            )
        case "Temperature annual timeseries":
        case "Temperature warming stripes":
            return (
                <>
                    Compare locations {showIcon} near the equator with those near the poles.
                    Climate change caused by <b>human activities</b> is generally warming the planet but poleward
                    <b>heat transport</b> by the atmosphere and oceans means areas closer
                    to the poles are warming faster than those near the equator.
                </>
            )
        case "Wind annual climatology":
            return (
                <>
                    Compare <b>wind roses</b> for monsoon regions {showIcon} such as South Asia with the midlatitude oceans.
                    Monsoon winds can change direction throughout the year, while winds between around 30° and 60° latitude
                    generally blow from the west (<i>"westerlies"</i>).
                    These westerly winds are driven by the <b>Coriolis effect</b> (Earth's rotation) and
                    {' '}<b>differential heating</b> of the Earth's surface by the sun.
                </>
            )
        case "Humidity monthly climatology":
            return (
                <>
                    Compare locations {showIcon} near large bodies of water with those in deserts or mountains.
                    Humidity tends to be higher near oceans and lakes due to <b>evaporation</b>, while arid regions and higher elevations
                    often experience lower humidity. <b>Seasonal changes</b> also impact humidity levels, with warmer months generally
                    increasing the atmosphere's capacity to hold moisture, leading to lower relative humidity.
                </>
            )
        default:
            return null
    }
}

function ContentWhat({ variable, period }) {
    const period_sanitised = (period !== null) ? period : "1991-2020";

    switch (variable) {
        case "Temperature monthly climatology":
            return (
                <>
                    For each day in a month, the <b>minimum</b> and <b>maximum</b> <b>temperatures</b> are calculated.
                    The average of these values over the <b>whole month</b> is calculated.
                    This is repeated for each year in the climatology period ({period_sanitised}).
                    The average value over all years (<i>e.g.</i> all Januaries) is shown.
                </>
            )
        case "Temperature daily climatology":
            return (
                <>
                    For each day of the year, the <b>minimum</b> and <b>maximum</b> <b>temperatures</b> are calculated.
                    This is repeated for each year in the climatology period ({period_sanitised}).
                    The average value over all years (<i>e.g.</i> all 1st Januaries) is shown.
                </>
            )
        case "Frost days etc":
            return (
                <>
                    For each day in a month, the <b>minimum</b> and <b>maximum</b> <b>temperatures</b> are calculated.
                    These are then compared against the temperature thresholds for <b>Frost Days</b> (minimum temperature
                    below {FROST_DAY} °C), <b>Summer Days</b> (maximum temperature above {SUMMER_DAY} °C),
                    and <b>Tropical Nights</b> (minimum temperature above {TROPICAL_NIGHT} °C).
                    The <b>percentage of days</b> in each month that reach the thresholds are calculated.
                    This is repeated for each year in the climatology period ({period_sanitised}).
                    The average value over all years (<i>e.g.</i> all Januaries) is shown.
                </>
            )
        case "Precip hourly hist":
            return (
                <>
                    For each period of one hour, the <b>total precipitation</b> is calculated.
                    Precipitation includes rain as well as snow, sleet, and hail.
                    This is repeated for every hour in the climatology period ({period_sanitised}).
                    The number of times the precipitation falls within a certain range is then counted.
                    The percentage of the total hours in which the precipitation falls within each range is shown.
                </>
            )
        case "Precip monthly climatology":
            return (
                <>
                    For each month of the year, the <b>total precipitation</b> is calculated.
                    Precipitation includes rain as well as snow, sleet, and hail.
                    This is repeated for each month in the climatology period ({period_sanitised}).
                    The average value over all years (<i>e.g.</i> all Januaries) is shown.
                </>
            )
        case "Precip annual timeseries":
            return (
                <>
                    For each year, the <b>total precipitation</b> is calculated by summing the accumulated precipitation over
                    every hour and day in the year. Precipitation includes rain as well as snow, sleet, and hail.
                </>
            )
        case "Temperature annual timeseries":
            return (
                <>
                    For each year, the <b>average temperature</b> is calculated.
                </>
            )
        case "Temperature warming stripes":
            return (
                <>
                    For each year, the <b>average temperature</b> is calculated.
                    Subsequently, the average temperature over the climatology period ({period_sanitised}) is subtracted.
                    The <b>anomalous temperature</b> is shown.
                    The colour scaling follows that of <b><a href="https://showyourstripes.info" target="_blank" rel="noreferrer">#ShowYourStripes</a></b> but uses a normalisation period of 1940-1999 due to data availability.
                </>
            )
        case "Wind annual climatology":
            return (
                <>
                    For each hour, the <b>wind speed</b> and <b>wind direction</b> are calculated.
                    These are then binned into one of <b>16 direction categories</b> and one of <b>4 speed categories</b>.
                    This is repeated for the entire climatology period ({period_sanitised}).
                    The percentage of values in each category is shown.
                    This is called a <b>wind rose</b> and shows the direction the wind is <i>coming from</i>.
                </>
            )
        case "Humidity monthly climatology":
            return (
                <>
                    <b>Relative humidity</b> is the amount of water vapour present in the air as a percentage of the maximum amount the air can hold at that temperature.
                    For each day in a month, the <b>minimum</b> and <b>maximum</b> <b>relative humidities</b> are calculated.
                    The average of these values over the <b>whole month</b> is calculated.
                    This is repeated for each year in the climatology period ({period_sanitised}).
                    The average value over all years (<i>e.g.</i> all Januaries) is shown.
                </>
            )
        default:
            return null
    }
}

function AccordionGraphs({ variable, period, comparisonMode }) {
    const accordionInfo = [
        {
            id: 'what',
            image: <IconFlask />,
            label: "What's shown?",
            description: 'How this graph was made...',
            content: <ContentWhat variable={variable} period={period} />
        },

        {
            id: 'explore',
            image: <IconBinoculars />,
            label: 'Explore',
            description: 'How do different locations compare?',
            content: <ContentExplore variable={variable} comparisonMode={comparisonMode} />
        },
    ];

    const items = accordionInfo.map((item) => (
        <Accordion.Item value={item.id} key={item.label} className='fullWidth'>
            <Accordion.Control>
                <AccordionLabel {...item} />
            </Accordion.Control>
            <Accordion.Panel>
                <Text size="md" >
                    {item.content}
                </Text>
            </Accordion.Panel>
        </Accordion.Item>
    ));

    return (
        <Accordion chevronPosition="right" variant="contained">
            {items}
        </Accordion>
    );
}

export default AccordionGraphs;