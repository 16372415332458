import React, { useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet'; // Import Leaflet directly
import { Tooltip, ActionIcon, Stack } from '@mantine/core';
import { isMobile } from 'react-device-detect';
import { IconPlus, IconMinus } from '@tabler/icons-react';
import { MARGIN } from '../../config/constants.ts';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { RootState } from '../../reducers/store.ts';

function ZoomButtons() {
  const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
  const multipanel = useTypedSelector(state => state.graph.multipanel)

  const map = useMap();
  const buttonContainerRef = useRef(null);

  useEffect(() => {
    if (buttonContainerRef.current) {
      // Disable click propagation for this element and its children
      L.DomEvent.disableClickPropagation(buttonContainerRef.current);
    }
  }, []);

  const handleZoomIn = (event) => {
    event.stopPropagation();
    event.preventDefault();
    event.nativeEvent.stopImmediatePropagation();

    map.zoomIn();
  };

  const handleZoomOut = (event) => {
    event.stopPropagation();
    event.preventDefault();
    event.nativeEvent.stopImmediatePropagation();

    map.zoomOut();
  };

  const buttonWidth = 40;

  return (
    <div ref={buttonContainerRef} style={{ position: "absolute", left: 0, bottom: (multipanel ? 20 : 0), zIndex: 1000 }}>
      <Stack align='center' gap='5px' style={{ margin: MARGIN }}>
        <Tooltip label={"Zoom in"} disabled={isMobile}>
          <ActionIcon
            onClick={handleZoomIn}
            size={buttonWidth}
          >
            <IconPlus  />
          </ActionIcon>
        </Tooltip>

        <Tooltip label={"Zoom out"} disabled={isMobile}>
          <ActionIcon
            onClick={handleZoomOut}
            size={buttonWidth}
          >
            <IconMinus  />
          </ActionIcon>
        </Tooltip>
      </Stack>
    </div>
  );
}

export default ZoomButtons;