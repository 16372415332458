import React, { useEffect, useState, useRef, memo } from 'react';
import { ActionIcon, Tooltip, Stack } from '@mantine/core';
import { Popup, useMap } from 'react-leaflet';
import { IconLock, IconLockOpen, IconX } from '@tabler/icons-react';
import { RootState } from '../../reducers/store.ts';
import { useSelector, TypedUseSelectorHook, useDispatch } from 'react-redux';

import { switchCurrentAndPrevious } from '../../reducers/dataSlice.ts';
import { switchCurrentAndPreviousLocation } from '../../reducers/locationSlice.ts';
import { setHeaderVisible } from '../../reducers/graphSlice.ts';

function calculateArea(lat: number) {
  const dtor = Math.PI / 180;
  const dlon_dlon_r2 = 772.7646;
  return Math.cos(lat * dtor) * dlon_dlon_r2;
}

function addDegreeSymbol(text: string) {
  return text.replace(/([NESW])/g, '°$1');
}

function splitAtComma(text: string) {
  if (/^[0-9]/.test(text)) {
    const latLonFormatted = addDegreeSymbol(text);
    return [latLonFormatted];
  } else {
    let parts = text.split(',', 2);
    return parts.map(part => part.trim());
  }
}

function formatLatitude(lat: number) {
  return lat > 0 ? `${lat.toFixed(2)} °N` : `${Math.abs(lat).toFixed(2)} °S`;
}

function formatLongitude(lng: number) {
  return lng > 0 ? `${lng.toFixed(2)} °E` : `${Math.abs(lng).toFixed(2)} °W`;
}

function TemperaturePopup({ position, placeName, averageTemp, locked }) {
  const dispatch = useDispatch();
  const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
  const fetchingData = useTypedSelector(state => state.data.fetchingData);
  const comparisonMode = useTypedSelector(state => state.data.comparisonMode);

  const [tooltipVisible, setTooltipVisible] = useState(true);

  const gotSecondData = true;
  const color = locked ? 'c3sRed' : 'c3sBlue';
  const fetchingAnyData = fetchingData.some(fetching => fetching);

  const titleSplit = splitAtComma(placeName);
  const marginBottom = titleSplit.length > 1 ? '0rem' : '0.5rem';
  const lockSize = 40;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (fetchingAnyData) return;

    dispatch(switchCurrentAndPrevious());
    dispatch(switchCurrentAndPreviousLocation());
  };

  const tooltipLabel = () => (
    <div style={{ display: 'flex', textAlign: 'center' }}>
      {locked ? 'Unlock this location' : 'Lock this location'}
      <br />
      {locked ? '(and lock the other)' : '(and unlock the other)'}
    </div>
  );

  const map = useMap();
  const popupRef = useRef(null);

  useEffect(() => {
    function handlePopupOpen() {
      setTooltipVisible(true)
      dispatch(setHeaderVisible(false))
    }
    function handlePopupClose() {
      setTooltipVisible(false)
      dispatch(setHeaderVisible(true))
    }

    if (popupRef.current) {
      map.on('popupopen', handlePopupOpen);
      map.on('popupclose', handlePopupClose);
    }

    return () => {
      map.off('popupopen', handlePopupOpen);
      map.off('popupclose', handlePopupClose);
    };
  }, [map, dispatch]);

  const handleClose = () => {
    if (popupRef.current) {
      map.closePopup(popupRef.current);  // Close the popup on the map
    }
  };

  const closeButton = (
    <Tooltip label={"Close"} disabled={!tooltipVisible}>
      <ActionIcon onClick={handleClose} >
        <IconX style={{ width: '70%', height: '70%' }} stroke={1.5} />
      </ActionIcon>
    </Tooltip>
  )

  return (
    <Popup
      minWidth={250}
      autoPan={false}
      ref={popupRef} // Attach the ref to the Popup
      className={locked || !comparisonMode ? 'redPopup' : 'bluePopup'}
      closeButton={false}
    >
      <div style={{ marginTop: '10px', marginBottom: '10px', minHeight: '100px' }}>

        <h3 style={{ marginBottom: marginBottom }}>
          {titleSplit[0]}
        </h3>

        {titleSplit.length > 1 && (
          <h4 style={{ marginTop: 0 }}>
            <i>{titleSplit[1]}</i>
            <br />
          </h4>
        )}

        Latitude: <b>{formatLatitude(position.lat)}</b>
        <br />
        Longitude: <b>{formatLongitude(position.lng)}</b>
        <br />
        Area represented: <b>{calculateArea(position.lat).toFixed(0)} km<sup>2</sup></b>
        <br />
        Average temperature: <b>{averageTemp}</b>

        <div
          style={{
            position: 'absolute',
            top: '0.5rem',
            right: '0.5rem',
          }}
        >
          {closeButton}
        </div>

        <div
          style={{
            position: 'absolute',
            bottom: '0.5rem',
            right: '0.5rem',
          }}
        >
          <Stack gap="5px">
            {comparisonMode ? (
              fetchingAnyData ? (
                <ActionIcon size={lockSize} color={color} loading={true} >
                  {locked ? <IconLock color={'white'} /> : <IconLockOpen color={'white'} />}
                </ActionIcon>
              ) : (
                gotSecondData && (
                  <Tooltip multiline={true} label={tooltipLabel()}>
                    <ActionIcon size={lockSize} color={color} onClick={handleClick}>
                      {locked ? <IconLock color={'white'} /> : <IconLockOpen color={'white'} />}
                    </ActionIcon>
                  </Tooltip>
                )
              )
            ) : null}
          </Stack>
        </div>
      </div>
    </Popup>
  );
}

export default memo(TemperaturePopup);