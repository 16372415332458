import React, { memo, useEffect, useState } from 'react';
import { ActionIcon, Tooltip, Group, Title, Divider, Stack, Space, Drawer, Box } from '@mantine/core';
import {
    IconMap,
    IconLogicXor,
    IconSettings,
    IconX,
    IconInfoCircle
} from '@tabler/icons-react';
import { isMobile } from 'react-device-detect';
import { useDisclosure } from '@mantine/hooks';
import ToggleLayers from './ToggleLayers.tsx';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { RootState } from '../../reducers/store.ts';
import { useDispatch } from 'react-redux';
import { toggleComparisonMode } from '../../reducers/dataSlice.ts'
import { toggleClimatologyView, toggleMultipanel } from '../../reducers/graphSlice.ts';
import ToggleSwitch from './ToggleSwitch.tsx';
import { storeSecondaryPosition, clearSecondaryPosition } from '../../reducers/locationSlice.ts';
import AboutButtonWithPopup from './AboutButtonWithPopup.tsx';
import { setMonthlySelected } from '../../reducers/progressSlice.ts';

function ShowSettings({ buttonWidth }) {
    useEffect(() => {
        console.log('Rendered ShowSettings')
    })

    const [opened, { open, close }] = useDisclosure(false);

    const titleButtonWidth = 35

    const tooltipLabel = 'Settings'

    const buttonWidthSmall = isMobile ? 50 : 55
    const vGap = 10

    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
    const comparisonMode = useTypedSelector((state) => state.data.comparisonMode)
    const climatologyView = useTypedSelector((state) => state.graph.climatologyView)
    const multipanel = useTypedSelector((state) => state.graph.multipanel)

    // These mean the state is only updated when the modal is closed, so the animations remain smooth
    const [comparisonModeState, setComparisonModeState] = useState(comparisonMode)
    const [climatologyViewState, setClimatologyViewState] = useState(climatologyView)
    const [multipanelState, setMultipanelState] = useState(multipanel)

    const dispatch = useDispatch()

    const graphsOnBottom = (window.innerWidth < window.innerHeight)

    const margin = 16
    const title = (
        <>
            <Group>
                <ActionIcon
                    size={titleButtonWidth}
                    onClick={handleClose}
                >
                    <IconSettings stroke={1.5} />
                </ActionIcon>

                <Title order={3}>
                    <b>ERA explorer</b>
                </Title>
            </Group>

            <ActionIcon
                size={titleButtonWidth}
                onClick={handleClose}
                style={{ position: 'absolute', right: `${margin}px`, top: `${margin}px` }}
            >
                <IconX stroke={1.5} />
            </ActionIcon>
        </>
    )

    function handleComparisonModeClick() {
        setComparisonModeState(prev => !prev)
    }

    function handleClimatologyViewClick() {
        setClimatologyViewState(prev => !prev)
    }

    function handleMultipanelViewClick() {
        setMultipanelState(prev => !prev)
    }


    function handleClose() {
        // THis is because if we dispatch this from the switch it seems to hang the UI briefly and the switch animation doesn't work
        if (comparisonModeState !== comparisonMode) {
            dispatch(toggleComparisonMode())

            if (comparisonModeState) {
                dispatch(storeSecondaryPosition())
            } else {
                dispatch(clearSecondaryPosition())

                // If we're getting secondary data then abort that
                // This doesn't work as expected (as in it doesn't abort it)
                // sequenceAbortController[1].current.abort()
            }
        }

        if (climatologyViewState !== climatologyView) {
            dispatch(toggleClimatologyView())
            dispatch(setMonthlySelected(climatologyViewState))
        }

        if (multipanelState !== multipanel) {
            dispatch(toggleMultipanel())
        }

        close()
    }

    const boxWidth = '20%'
    const lineSize = 'sm'
    const smallGap = '0px'

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const drawAtBottom = windowSize.width < 500
    const switchWidth = 75
    const tempPrecipMinWidth = 80

    return (
        <>
            <Tooltip label={tooltipLabel}>
                <ActionIcon
                    size={buttonWidth}
                    onClick={open}
                >
                    <IconSettings stroke={1.5} />
                </ActionIcon>
            </Tooltip>

            <Drawer
                position={drawAtBottom ? 'bottom' : 'left'}
                opened={opened}
                onClose={handleClose}
                title={title}
                overlayProps={{
                    backgroundOpacity: 0.55,
                    blur: 3,
                }}
                offset={drawAtBottom ? 0 : 5}
                radius={drawAtBottom ? "20px 20px 0 0" : 20}
                size={drawAtBottom ? '460px' : `md`}
                withCloseButton={false}
                styles={{
                    root: {
                        '--drawer-height': (!drawAtBottom ? '520px' : null),
                    }
                }}
            >
                <Divider
                    size={lineSize}
                    labelPosition="left"
                    label={
                        <>
                            <IconMap size={12} />
                            <Box ml={5}>Map layers (30 year averages)</Box>
                        </>
                    }
                />
                <Space h='sm' />

                <Group justify='space-between' style={{ width: '90%', margin: "0px 5%" }} data-autofocus>
                    <Stack align='center' justify='flex-start' gap={vGap} style={{ minWidth: tempPrecipMinWidth, maxWidth: boxWidth, width: boxWidth }} >
                        <ToggleLayers buttonWidth={buttonWidthSmall} layer={'temperature'} />
                        Temperature
                        <Space h={smallGap} />
                    </Stack>

                    <Stack align='center' justify='flex-start' gap={vGap} style={{ minWidth: tempPrecipMinWidth, maxWidth: boxWidth, width: boxWidth }} >
                        <ToggleLayers buttonWidth={buttonWidthSmall} layer={'rain'} />
                        Precipitation
                        <Space h={smallGap} />
                    </Stack>

                    <Divider orientation='vertical' size={lineSize} />

                    <Stack align='center' justify='flex-start' gap={vGap} style={{ width: boxWidth }} >
                        <ToggleLayers buttonWidth={buttonWidthSmall} layer={'wind'} />
                        Wind
                        <Space h={smallGap} />
                    </Stack>
                </Group>

                <Space h='sm' />

                <Divider
                    size={lineSize}
                    labelPosition="left"
                    label={
                        <>
                            <IconLogicXor size={12} />
                            <Box ml={5}>Advanced</Box>
                        </>
                    }
                />
                <Space h='sm' />

                <ToggleSwitch
                    handleClick={handleComparisonModeClick}
                    label={"Compare two locations"}
                    width={switchWidth}
                    defaultChecked={comparisonModeState}
                />

                <Space h='md' />

                <ToggleSwitch
                    handleClick={handleClimatologyViewClick}
                    label={"Show averages of individual months instead of full years"}
                    width={switchWidth}
                    defaultChecked={climatologyView}
                />

                {!isMobile &&
                    <>
                        <Space h='md' />

                        <ToggleSwitch
                            handleClick={handleMultipanelViewClick}
                            label={"Show graphs on the bottom of the screen"}
                            width={switchWidth}
                            defaultChecked={multipanel}
                            disabled={graphsOnBottom}
                        />
                    </>
                }

                <Space h='md' />

                <Divider
                    size={lineSize}
                    labelPosition="left"
                    label={
                        <>
                            <IconInfoCircle size={12} />
                            <Box ml={5}>About</Box>
                        </>
                    }
                />


                <Space h='sm' />
                <Group>
                    <div style={{
                        minWidth: switchWidth - 3,
                        maxWidth: switchWidth - 3,
                        margin: 0,
                        padding: 0,
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <AboutButtonWithPopup buttonWidth={buttonWidth} />
                    </div>
                    About ERA explorer
                </Group>
            </Drawer >
        </>
    );
}

export default memo(ShowSettings);
