import React, { useState, useMemo, useEffect, useRef, memo, useCallback } from 'react';
import { Paper, Stack, ScrollArea, Badge, Group, Tooltip, ActionIcon } from '@mantine/core';
import scrollBarClass from '../../styles/ScrollBar.module.css';
import { useSelector, useDispatch, TypedUseSelectorHook } from 'react-redux';
import MakeGraph from './MakeGraph.tsx';
import GraphLoading from './GraphLoading.tsx';
import { setAttentionReceived } from '../../reducers/graphSlice.ts'
import { RootState } from '../../reducers/store.ts';
import { stripeColorsGrey, dailyPolarColors, frostColor2, summerColor2, tropicalColor2, variableOptions, BOX_SHADOW, BACKGROUND_COLOR } from '../../config/constants.ts';
import ComplexBadge from './ComplexBadge.tsx';
import { isMobile } from 'react-device-detect';
import AccordionGraphs from './AccordionGraphs.tsx';
import { HideSlideButton, ShowSlidesButton } from './HideSlidesButtons.tsx';
import { usePreventPullToRefreshDiv } from '../../hooks/usePreventPullToRefresh.ts';
import { IconCamera } from '@tabler/icons-react';
import ReactDOM from 'react-dom';
import store from '../../reducers/store.ts';
import { arraysAreEqual, makeStripesBadgeGradient, makeThresholdGradient, sanitiseLocation, sanitiseVariable } from '../../utils/helperFunctions.ts';

// import createPlotlyComponent from 'react-plotly.js/factory';
import CustomPlotly from './CustomPlotly.ts';  // Import the custom Plotly instance
import ShareButton from './ShareButton.tsx';

const borderColorUnclickable = 'rgb(210, 210, 210)'
const textColorUnclickable = 'black'

function ScienceBox({ width, height, variable, landscape, buttonIndex, mutuallyExclusive = false }) {
    useEffect(() => {
        console.log("Rendered ScienceBox")
    })

    // const theme = useMantineTheme();

    const nodeRef = useRef(null)
    const scrollAreaRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const scrollAreaElement = scrollAreaRef.current;

        if (scrollAreaElement) {
            const stopPropagation = (e) => {
                // If the ScrollArea can scroll, prevent the wheel event from reaching Embla
                const { scrollTop, scrollHeight, clientHeight } = scrollAreaElement;
                const canScroll = scrollHeight > clientHeight;

                if (canScroll) {
                    // Detect scrolling direction
                    const isScrollingUp = e.deltaY < 0;
                    const isScrollingDown = e.deltaY > 0;

                    // Prevent scrolling when at the top or bottom
                    const isAtTop = scrollTop === 0;
                    const isAtBottom = scrollTop + clientHeight >= scrollHeight;

                    if (
                        (isScrollingUp && isAtTop) ||
                        (isScrollingDown && isAtBottom)
                    ) {
                        // Allow Embla to handle the scroll event when at the boundary
                        return;
                    }

                    // Stop propagation if ScrollArea is still scrollable
                    e.stopPropagation();
                }
            };

            // Attach event listener to prevent propagation of wheel event
            scrollAreaElement.addEventListener('wheel', stopPropagation);

            // Cleanup event listener on component unmount
            return () => {
                scrollAreaElement.removeEventListener('wheel', stopPropagation);
            };
        }
    }, []);

    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
    const sliceKey = variableOptions.find((element) => element.name === variable)!.sliceKey;
    const gotCurrentData = useTypedSelector((state) => state.data[sliceKey][0].metadata.gotData);
    let gotPreviousData = useTypedSelector((state) => state.data[sliceKey][1].metadata.gotData);

    const climPeriod = useTypedSelector((state) => state.data[sliceKey][0].metadata.clim_period);

    const multipanel = useTypedSelector((state) => state.graph.multipanel)

    const comparisonMode = useTypedSelector((state) => state.data.comparisonMode)
    if (gotCurrentData) {
        gotPreviousData = gotPreviousData && comparisonMode
    }

    const graphHeight = height * 0.45;
    const graphWidth = width * 0.9;
    const scrollHeight = height - graphHeight - 80

    const [showPrevCurr, setShowPrevCurr] = useState<boolean[]>([false, true]);
    const [showABC, setShowABC] = useState<boolean[]>([true, true, true])
    const [ready, setReady] = useState(false);

    // console.log("DEBUG", gotCurrentData, gotPreviousData, (gotCurrentData && gotPreviousData));
    if (ready !== (gotCurrentData && gotPreviousData)) {
        // console.log("Rendered ScienceBox - ready", ready);
        setReady((gotCurrentData && gotPreviousData))
    }

    useEffect(() => {
        console.log("MakeGraph - ready", ready)
    }, [ready])

    const previousGotCurrentDataRef = useRef<boolean | null>(null);

    useEffect(() => {
        if (previousGotCurrentDataRef.current === false && gotCurrentData === true) {
            console.log("gotCurrentData just changed to true from false (new data just arrived)");
            if (mutuallyExclusive) {
                setShowPrevCurr([false, true])
            } else {
                setShowPrevCurr([true, true])
            }
        } else if (previousGotCurrentDataRef.current === true && gotCurrentData === false) {
            console.log("gotCurrentData just changed to false from true (user clicked and latest data was made previous)");
            // Add any logic you want to run when gotCurrentData changes from false to true
            setShowPrevCurr([true, false])
        }
        // Update the ref to the current value for the next render
        previousGotCurrentDataRef.current = gotCurrentData;
    }, [gotCurrentData, mutuallyExclusive]);

    const dispatch = useDispatch()

    const shadowOffset = 5
    const units = variableOptions.find((element) => element.name === variable)!.units;
    const aggregation = variableOptions.find((element) => element.name === variable)!.aggregation;
    const statistic = variableOptions.find((element) => element.name === variable)!.statistic;
    const titleText = variableOptions.find((element) => element.name === variable)!.title;
    let backgroundGradient: string = ""
    let backgroundGradient2: string = ""
    const fontSizeH = landscape ? (0.025 * window.innerHeight) : (0.02 * window.innerHeight)
    const fontSizeW = landscape ? (0.045 * window.innerWidth) : (0.045 * window.innerWidth)
    const fontSizeBoxW = landscape ? (0.055 * width) : (0.055 * width)
    const fontSize = `${Math.max(Math.min(fontSizeH, fontSizeW, fontSizeBoxW), 16)}px`
    const fontSizeUnits = `${Math.max(0.6 * Math.min(fontSizeH, fontSizeW), 14)}px`

    backgroundGradient = useMemo(() => {
        switch (variable) {
            case "Temperature daily climatology":
                return makeStripesBadgeGradient(50, dailyPolarColors, 0);
            case "Frost days etc":
                return makeThresholdGradient();
            case "Temperature warming stripes":
                return makeStripesBadgeGradient(50, stripeColorsGrey, 0.1);
            default:
                return "";
        }
    }, [variable]);

    backgroundGradient2 = useMemo(() => {
        switch (variable) {
            case "Frost days etc":
                return makeThresholdGradient();
            default:
                return "";
        }
    }, [variable]);

    const color1 = variableOptions.find((element) => element.name === variable)!.color1;
    const color2 = variableOptions.find((element) => element.name === variable)!.color2;
    const showPeriod = variableOptions.find((element) => element.name === variable)!.statistic !== "timeseries";
    const extraBadges = (variable === "Frost days etc") ? true : false

    const color1Mod = color1
    const color2Mod = ["Precipitation", "Temperature"].includes(
        variableOptions.find((element) => element.name === variable)!.title
    ) ? (color2 + 'c3') : color2;

    if (backgroundGradient2 === "") {
        backgroundGradient2 = backgroundGradient
    }

    const heightWithoutShadow = landscape ? height : (height - shadowOffset)

    const handleClick1 = useCallback(() => {
        console.log("Clicked 1")
        if (!ready) {
            return
        }

        if (showPrevCurr[0]) {
            // If we're about to change the first bars to hidden, then make sure we're showing the second
            // setShowSecond(true)
            setShowPrevCurr([!showPrevCurr[0], true])
        } else if (mutuallyExclusive) {
            // We're about to REVEAL the first bars. As we're exclusive, hide the second ones
            // setShowSecond(false)
            setShowPrevCurr([!showPrevCurr[0], false])
        } else {
            // setShowFirst(!showFirst)
            setShowPrevCurr([!showPrevCurr[0], showPrevCurr[1]])
        }

        dispatch(setAttentionReceived())
    }, [ready, showPrevCurr, mutuallyExclusive, dispatch]);

    const handleClick2 = useCallback(() => {
        console.log("Clicked 2")
        if (!ready) {
            return
        }
        if (showPrevCurr[1]) {
            // setShowFirst(true)
            setShowPrevCurr([true, !showPrevCurr[1]])
        } else if (mutuallyExclusive) {
            // We're about to REVEAL the second bars. As we're exclusive, hide the first ones
            // setShowFirst(false)
            setShowPrevCurr([false, !showPrevCurr[1]])
        } else {
            // setShowSecond(!showSecond)
            setShowPrevCurr([showPrevCurr[0], !showPrevCurr[1]])
        }

        dispatch(setAttentionReceived())
    }, [ready, showPrevCurr, mutuallyExclusive, dispatch]);

    let textColorSelected = 'white'
    let textColorUnselected = 'black'
    let blankBackgroundColor = 'rgb(200, 200, 200)'
    if ((backgroundGradient !== "") && (variable !== "Frost days etc")) {
        textColorSelected = 'black'
        // textColorUnselected = 'white'
        // blankBackgroundColor = 'rgb(200, 200, 200)'
    }

    let textColorSelectedPrev = textColorSelected
    if (variableOptions.find((element) => element.name === variable)!.title === "Precipitation") {
        textColorSelectedPrev = 'black'
    } else if (variableOptions.find((element) => element.name === variable)!.title === "Temperature") {
        textColorSelectedPrev = 'black'
    }

    let textColorSelectedCurrent = textColorSelected
    if (variableOptions.find((element) => element.name === variable)!.title === "Wind") {
        textColorSelectedPrev = 'black'
        textColorSelectedCurrent = 'black'
    }

    const basicBadgeStyles = {
        root: {
            borderWidth: 0,
            borderColor: borderColorUnclickable,
            backgroundColor: '#ccc',
            color: textColorUnclickable,
        }
    }

    const colorsMemoized = useMemo(() => [color1Mod, color2Mod], [color1Mod, color2Mod]);

    const smallButtonWidth = isMobile ? 35 : 35

    const dragToCloseRef1 = usePreventPullToRefreshDiv();
    const dragToCloseRef3 = usePreventPullToRefreshDiv();
    const dragToCloseRef4 = usePreventPullToRefreshDiv();

    const previousBadge = (
        <ComplexBadge
            label="Loading..."
            onClick={handleClick1}
            showBadge={showPrevCurr[0]}
            color={color1}
            textColorSelected={textColorSelectedPrev}
            textColorUnselected={textColorUnselected}
            blankBackgroundColor={blankBackgroundColor}
            ready={ready}
            landscape={landscape}
            backgroundGradient={showPrevCurr[0] ? backgroundGradient : ""}
            gradientButton={(backgroundGradient !== "")}
            animationDelay={0}
            // rightSection={iconX}
            variable={variable}
            current={false}
            comparisonMode={comparisonMode}
            tooltipLabel={comparisonMode ? 'Toggle location' : 'Location'}
        />
    );

    const currentBadge = (
        <ComplexBadge
            label="Loading..."
            onClick={handleClick2}
            showBadge={showPrevCurr[1]}
            color={color2}
            textColorSelected={textColorSelectedCurrent}
            textColorUnselected={textColorUnselected}
            blankBackgroundColor={blankBackgroundColor}
            ready={ready}
            landscape={landscape}
            backgroundGradient={showPrevCurr[1] ? backgroundGradient2 : ""}
            gradientButton={(backgroundGradient !== "")}
            animationDelay={0.5}
            // rightSection={iconPlus}
            variable={variable}
            current={true}
            comparisonMode={comparisonMode}
            tooltipLabel={comparisonMode ? 'Toggle location' : 'Location'}
        />
    );

    const initialBadge = (
        <ComplexBadge
            label="Select on map"
            onClick={() => { }}
            showBadge={true}
            color="white"
            textColorSelected="black"
            textColorUnselected="black"
            blankBackgroundColor={blankBackgroundColor}
            animationDelay={1}
            ready={ready}
            landscape={landscape}
            ignoreAttention={true}
            tooltipLabel={'Select a location to compare'}
        />
    );

    const handleFrostClick = useCallback(() => {
        console.log("Clicked frost")

        if (arraysAreEqual(showABC, [true, false, false])) {
            // setShowB(true)
            setShowABC([false, true, false])
        } else {
            // setShowA(!showA)
            setShowABC([!showABC[0], showABC[1], showABC[2]])
        }

        dispatch(setAttentionReceived())
    }, [showABC, dispatch]);

    const handleSummerClick = useCallback(() => {
        console.log("Clicked summer")

        if (arraysAreEqual(showABC, [false, true, false])) {
            // setShowC(true)
            setShowABC([false, false, true])
        } else {
            // setShowB(!showB)
            setShowABC([showABC[0], !showABC[1], showABC[2]])
        }

        dispatch(setAttentionReceived())
    }, [showABC, dispatch]);

    const handleTropicalClick = useCallback(() => {
        console.log("Clicked tropical")

        if (arraysAreEqual(showABC, [false, false, true])) {
            // setShowA(true)
            setShowABC([true, false, false])
        } else {
            // setShowC(!showC)
            setShowABC([showABC[0], showABC[1], !showABC[2]])
        }

        dispatch(setAttentionReceived())
    }, [showABC, dispatch]);

    const showBadgeA = extraBadges ? (
        <ComplexBadge
            label="Frost days"
            onClick={handleFrostClick}
            showBadge={showABC[0]}
            color={frostColor2}
            textColorSelected='white'
            textColorUnselected={textColorUnselected}
            blankBackgroundColor={blankBackgroundColor}
            animationDelay={1}
            ready={ready}
            landscape={landscape}
            overrideReady={true}
            tooltipLabel='Frequency of frost days'
        />
    ) : null;

    const showBadgeB = extraBadges ? (
        <ComplexBadge
            label="Summer days"
            onClick={handleSummerClick}
            showBadge={showABC[1]}
            color={summerColor2}
            textColorSelected="black"
            textColorUnselected={textColorUnselected}
            blankBackgroundColor={blankBackgroundColor}
            animationDelay={0.6}
            ready={ready}
            landscape={landscape}
            overrideReady={true}
            tooltipLabel='Frequency of summer days'
        />
    ) : null;

    const showBadgeC = extraBadges ? (
        <ComplexBadge
            label="Tropical nights"
            onClick={handleTropicalClick}
            showBadge={showABC[2]}
            color={tropicalColor2}
            textColorSelected='white'
            textColorUnselected={textColorUnselected}
            blankBackgroundColor={blankBackgroundColor}
            animationDelay={0.3}
            ready={ready}
            landscape={landscape}
            overrideReady={true}
            tooltipLabel='Frequency of tropical nights'
        />
    ) : null;

    const somethingToShow = ((gotPreviousData) || (gotCurrentData)) ? true : false
    const loadingZIndex = somethingToShow ? 0 : 10
    const loadingVisibility = somethingToShow ? "hidden" : "visible"

    // const borderColor = variableOptions.find((element) => element.name === variable)!.color2;

    const [graphForDownload, setGraphForDownload] = useState(false); // State to track if the graph should be shown
    const plotInstanceRef = useRef(null); // Define the ref here
    const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null); // Create a ref to store the timeout ID

    const handleButtonClick = () => {
        setGraphForDownload(true); // Set state to true when the button is clicked

        // Clear any existing timeout to prevent multiple calls
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        // Set a timeout to call handleExport after 0.1 seconds
        timeoutRef.current = setTimeout(() => {
            handleExport();
            setGraphForDownload(false)
        }, 100); // Wait for 0.1 seconds
    };

    const handleExport = async () => {
        console.log('Export button clicked, plotInstanceRef:', plotInstanceRef.current); // Log the current reference
        if (plotInstanceRef.current) {
            try {
                const variableShort = sanitiseVariable(variable)
                const locationPositionCurr = store.getState().data[sliceKey][0].metadata.locationPosition
                const locationPositionPrev = store.getState().data[sliceKey][1].metadata.locationPosition
                let locationShort = (showPrevCurr[1] && gotCurrentData) ? "_" + sanitiseLocation(locationPositionCurr) : ""
                locationShort = (showPrevCurr[0] && gotPreviousData) ? locationShort + "_" + sanitiseLocation(locationPositionPrev) : locationShort

                if (locationShort === "") {
                    throw new Error('Graph not ready yet');
                }

                // Step 1: Generate the Plotly graph image
                const graphImage = await CustomPlotly.toImage(plotInstanceRef.current, {
                    format: 'png',
                    width: 600,
                    height: 500,
                });

                // Step 2: Load the premade attribution image from the public folder
                const attributionImagePath = `${process.env.PUBLIC_URL}/LogoLine_horizon_C3S_resized_white.png`; // Adjust to your file's location
                const attributionImg = new Image();
                attributionImg.src = attributionImagePath;
                await new Promise((resolve) => {
                    attributionImg.onload = resolve;
                });

                // Step 3: Create a canvas to combine both images
                const finalCanvas = document.createElement("canvas");
                finalCanvas.width = 600; // Keep the width consistent
                finalCanvas.height = 500 + 123; // Graph height + attribution image height
                const finalCtx = finalCanvas.getContext("2d");

                // Draw graph image
                const graphImg = new Image();
                graphImg.src = graphImage;
                await new Promise((resolve) => {
                    graphImg.onload = () => {
                        finalCtx?.drawImage(graphImg, 0, 0);
                        resolve(undefined);
                    };
                });

                // Draw attribution image below the graph image
                finalCtx?.drawImage(attributionImg, 0, 500); // 500 is the y-position for the attribution image

                // Step 4: Download the combined image
                const finalImage = finalCanvas.toDataURL("image/png");
                const link = document.createElement('a');
                link.href = finalImage;
                link.download = `${variableShort}${locationShort}.png`;
                link.click();
            } catch (error) {
                console.error('Error exporting plot:', error); // Log any export errors
            }
        } else {
            console.warn('Plot instance not available'); // Warn if the instance is not set
        }
    };

    return (
        <Paper
            withBorder
            className='cardClass'
            styles={{
                root: {
                    borderRadius: '6px',
                    width: `${width}px`,
                    height: `${heightWithoutShadow}px`,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '3px',
                    marginRight: '3px',
                    marginTop: '1px',
                    boxShadow: BOX_SHADOW,
                    backgroundColor: BACKGROUND_COLOR,
                },
            }}
        >

            <Stack
                h={height}
                align='center'
                justify='flex-start'
                gap={0}
                styles={{
                    root: {
                        padding: '20px',
                    }
                }}
            >
                <div
                    style={{
                        padding: '30px 10px 0px 10px',
                        display: "inline-block"
                    }}  // top, right, bottom, left
                    ref={nodeRef}
                >
                    <div
                        style={{
                            position: 'relative',
                            width: graphWidth,
                            height: graphHeight
                        }}
                        ref={dragToCloseRef1}
                    >
                        <div style={{ zIndex: 5, position: 'absolute' }}>
                            {somethingToShow &&
                                <MakeGraph
                                    width={graphWidth}
                                    height={graphHeight}
                                    variable={variable}
                                    colors={colorsMemoized}
                                    showLocations={showPrevCurr}
                                    showLines={showABC}
                                    landscape={landscape}
                                    plotInstanceRef={null}
                                    forDownload={false}
                                />
                            }
                        </div>
                        <div style={{ zIndex: loadingZIndex, position: 'absolute', visibility: loadingVisibility }}>
                            <GraphLoading
                                width={graphWidth}
                                height={graphHeight}
                            />
                        </div>
                    </div>

                    {/* Just want this to be rendered conditionally, and maybe not even inside this component */}
                    {somethingToShow && graphForDownload && ReactDOM.createPortal(
                        <div style={{ position: "absolute", right: "-9999px", top: "0", pointerEvents: "none" }}>
                            <MakeGraph
                                width={graphWidth}
                                height={graphHeight}
                                variable={variable}
                                colors={colorsMemoized}
                                showLocations={showPrevCurr}
                                showLines={showABC}
                                landscape={landscape}
                                plotInstanceRef={plotInstanceRef}
                                forDownload={true}
                            />
                        </div>,
                        document.body // This will append the div to the body element
                    )}

                    <ScrollArea
                        h={scrollHeight}
                        type="hover"
                        scrollbars="y"
                        classNames={scrollBarClass}
                        className='scrollAreaNamedForCloning'
                        styles={{
                            root: {
                                justifyItems: 'left',
                                textAlign: "left"
                            }
                        }}
                        offsetScrollbars={true}
                        viewportRef={scrollAreaRef}
                    >

                        <div
                            style={{
                                justifyItems: 'left',
                                textAlign: 'left',
                                width: '100%'
                            }}
                        >
                            <p className='CardTitle'>
                                <span className='CardTitleMain' style={{ fontSize: fontSize }}>{titleText} </span>
                                <span className='CardTitleUnits' style={{ fontSize: fontSizeUnits }}>[{units}]</span>
                            </p>
                            <p className='CardPara'>
                                <Tooltip label={"How the data was aggregated"}>
                                    <Badge component='span' styles={basicBadgeStyles} >
                                        {aggregation}
                                    </Badge>
                                </Tooltip>

                                <span> </span>
                                <Tooltip label={"The type of statistic"}>
                                    <Badge component='span' styles={basicBadgeStyles} className='thisMakesNoSenseToMe' >
                                        {statistic}
                                    </Badge>
                                </Tooltip>

                                {(showPeriod && (climPeriod !== null)) && (
                                    <>
                                        <span> </span>
                                        <Tooltip label={"The reference period"}>

                                            <Badge component='span' styles={basicBadgeStyles} className='thisMakesNoSenseToMe' >
                                                {climPeriod}
                                            </Badge>
                                        </Tooltip>
                                    </>
                                )}

                                <br></br>

                                {(extraBadges && (gotCurrentData || gotPreviousData)) && (
                                    <>
                                        {showBadgeA}
                                        <span> </span>
                                        {showBadgeB}
                                        <span> </span>
                                        {showBadgeC}
                                        <br></br>
                                    </>
                                )}

                                {(gotPreviousData) && (
                                    <>
                                        {previousBadge}
                                        <span> </span>
                                        {currentBadge}
                                    </>
                                )}

                                {((!gotPreviousData) && (gotCurrentData)) && (
                                    <>
                                        {currentBadge}
                                        <span> </span>
                                        {(comparisonMode) && (initialBadge)}
                                    </>
                                )}
                            </p>
                        </div>

                        <div className='removeFromPng'>
                            <AccordionGraphs variable={variable} period={climPeriod} comparisonMode={comparisonMode} />
                        </div>

                    </ScrollArea>
                </div>
            </Stack>

            <>
                {!isMobile &&
                    <Group
                        className='hover-div'
                        style={{
                            zIndex: 1000,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            margin: 7,
                        }}
                        gap={5}
                        ref={dragToCloseRef3}
                    >
                        <HideSlideButton buttonWidth={smallButtonWidth} buttonIndex={buttonIndex} />
                        <ShowSlidesButton buttonWidth={smallButtonWidth} />
                    </Group>
                }

                <Group
                    className={isMobile ? 'hover-div-mobile' : 'hover-div'}
                    style={{
                        zIndex: 1000,
                        position: 'absolute',
                        top: 0,
                        right: (!landscape || multipanel) ? 10 : 3,
                        margin: isMobile ? 10 : 7,
                    }}
                    gap={5}
                    ref={dragToCloseRef4}
                >
                    <ShareButton size={smallButtonWidth} buttonIndex={buttonIndex} />
                    <Tooltip label={"Download PNG"}>
                        <ActionIcon
                            size={smallButtonWidth}
                            onClick={handleButtonClick}
                        >
                            <IconCamera />
                        </ActionIcon>
                    </Tooltip>
                </Group>
            </>
        </Paper>
    )
};

export default memo(ScienceBox)