// src/utils/urlState.js
import queryString from 'query-string';
import { clampLatLng } from './clamp.ts';
import { variableOptions } from '../config/constants.ts';

export const getStateFromUrl = () => {
  const parsed = queryString.parse(window.location.search);
  const lat = parsed.lat ? Number(parsed.lat) : null;
  const lng = parsed.lng ? Number(parsed.lng) : null;
  const latB = parsed.latB ? Number(parsed.latB) : null;
  const lngB = parsed.lngB ? Number(parsed.lngB) : null;
  const plotIndex = parsed.plot ? Number(parsed.plot) : null;

  let gotLatLng = false
  let gotLatLngB = false

  let clamped = clampLatLng(lat, lng);
  if (clamped.lat !== null && clamped.lng !== null) {
    gotLatLng = true
  }

  let clampedB = clampLatLng(latB, lngB);
  if (clampedB.lat !== null && clampedB.lng !== null) {
    gotLatLngB = true
  }

  if (gotLatLngB && !gotLatLng) {
    // Got lat lng B (because we copied a link or refreshed when having just selected comparison mode)
    clamped = clampLatLng(latB, lngB)
    clampedB.lat = null
    clampedB.lng = null
  }

  const comparisonMode = gotLatLng && gotLatLngB
  const readLatLngFromURL = gotLatLng || gotLatLngB

  const maxPlotIndex = variableOptions.length - 1;
  const plotIndexClamped = (plotIndex ? (Math.min(Math.max(plotIndex, 0), maxPlotIndex)) : null);

  // console.log("getStateFromUrl", comparisonMode, { lat, lng }, { latB, lngB }, { plotIndex });

  return {
    location: {
      locationPosition: {
        lat: clamped.lat,
        lng: clamped.lng,
      },
      locationPosition2: {
        lat: clampedB.lat,
        lng: clampedB.lng,
      },
      readLatLngFromURL,
    },

    data: {
      plotToShow: plotIndexClamped,
      comparisonMode: comparisonMode,
    },
  };
};

export const updateUrlFromState = ({
  location: {
    locationPosition,
    locationPosition2,
  },
  data: {
    prioritySlide
  }
}) => {
  const newLat = (locationPosition.lat ? locationPosition.lat.toFixed(2) : null)
  const newLng = (locationPosition.lng ? locationPosition.lng.toFixed(2) : null)
  const newLatB = (locationPosition2.lat ? locationPosition2.lat.toFixed(2) : null)
  const newLngB = (locationPosition2.lng ? locationPosition2.lng.toFixed(2) : null)
  const newPlot = prioritySlide.toFixed(0);

  const currentUrlParams = new URLSearchParams(window.location.search);
  const currentLat = currentUrlParams.get('lat');
  const currentLng = currentUrlParams.get('lng');
  const currentLatB = currentUrlParams.get('latB');
  const currentLngB = currentUrlParams.get('lngB');
  const currentPlot = currentUrlParams.get('plot');

  if (newLat !== currentLat || newLng !== currentLng || newPlot !== currentPlot || newLatB !== currentLatB || newLngB !== currentLngB) {
    console.log("updateUrlFromState",
      { lat: newLat, lng: newLng },
      { latB: newLatB, lngB: newLngB },
      { plot: newPlot },
      { lat: currentLat, lng: currentLng },
      { latB: currentLatB, lngB: currentLngB },
      { plot: currentPlot }
    );

    const firstLatLon = (newLat !== null && newLng !== null)
    const secondLatLon = (newLatB !== null && newLngB !== null)

    let newUrl: string = ""
    let doUpdate = true
    if (firstLatLon && secondLatLon) {
      newUrl = queryString.stringify({ lat: newLat, lng: newLng, latB: newLatB, lngB: newLngB, plot: newPlot });
    } else if (firstLatLon) {
      newUrl = queryString.stringify({ lat: newLat, lng: newLng, plot: newPlot });
    } else if (secondLatLon) {
      newUrl = queryString.stringify({ latB: newLatB, lngB: newLngB, plot: newPlot });
    } else {
      doUpdate = false
    }
    
    if (doUpdate) {
      window.history.replaceState(null, '', `?${newUrl}`);
    }
  }
};