import React, { useState, useEffect, useRef } from 'react'
import { MapContainer, WMSTileLayer, AttributionControl, useMapEvents, useMap } from 'react-leaflet';
import L, { } from 'leaflet'
import { closeGraphs, wiggleBadges } from '../../reducers/graphSlice.ts'
import { normalizeLongitude } from '../../utils/helperFunctions.ts';

import LeafletVelocity from "./LeafletVelocity.tsx";

import 'leaflet/dist/leaflet.css';
import '../../styles/Map.css';  // Ensure you have the CSS file for styling

import { useSelector, useDispatch, TypedUseSelectorHook } from 'react-redux'
import { toggleVelocity } from '../../reducers/locationSlice.ts'
import useUpdatePositionAndData from '../../hooks/useUpdatePositionAndData.ts';
import { RootState } from '../../reducers/store.ts';
import MapMarkerContainer from './MapMarkerContainer.tsx';
import { isMobile } from 'react-device-detect';
import ZoomButtons from './ZoomButtons.tsx'; // Import your custom buttons component
import { DEFAULT_LOCATION } from '../../config/constants.ts';

// Workaround because the leaflet css doesn't play well with react-leaflet
// @ts-ignore
delete L.Icon.Default.prototype._getIconUrl;
(L.Icon as any).Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const mapStyle: React.CSSProperties = {
  position: 'fixed',
  left: '0px',
  right: '0px',
  top: '0px',
  bottom: '0px',
}

const PanToLocation = () => {
  useEffect(() => {
    console.log("Rendered PanToLocation")
  })

  const map = useMap();

  const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
  const position = useTypedSelector(state => state.location.locationPosition)
  const position2 = useTypedSelector(state => state.location.locationPosition2)
  const togglePanToLocation = useTypedSelector(state => state.location.togglePanToLocation)
  const panToLocationNumber = useTypedSelector(state => state.location.panToLocationNumber)
  const graphsVisible = useTypedSelector(state => state.graph.graphsVisible)
  const multipanel = useTypedSelector(state => state.graph.multipanel)
  const chromeIOSRotated = useTypedSelector(state => state.graph.chromeIOSRotated)

  const [landscape, setLandscape] = useState(window.innerHeight > window.innerWidth)
  useEffect(() => {
    const handleResize = () => {
      setLandscape(window.innerHeight > window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const prevTogglePanToLocation = useRef(togglePanToLocation);

  useEffect(() => {
    let positionMod = { lat: position.lat ?? position2.lat ?? DEFAULT_LOCATION.lat, lng: position.lng ?? position2.lng ?? DEFAULT_LOCATION.lng }
    if (prevTogglePanToLocation.current !== togglePanToLocation) {
      console.log('togglePanToLocation changed (rather than graphs being opened/closed)');
      if (panToLocationNumber === 0) {
        // positionMod.lat = position.lat
        // positionMod.lng = position.lng
      } else if (panToLocationNumber === 1) {
        positionMod.lat = position2.lat ?? position.lat ?? DEFAULT_LOCATION.lat
        positionMod.lng = position2.lng ?? position.lng ?? DEFAULT_LOCATION.lng
      }
    }

    prevTogglePanToLocation.current = togglePanToLocation; // Update the ref for the next render

    if (positionMod) {
      let graphsOnSide = true
      if (landscape || multipanel) {
        graphsOnSide = false
      }

      console.log("VIEWER PanToLocationX", positionMod)

      const zoom = map.getZoom()
      const degToRad = Math.PI / 180
      const latFactor = Math.cos(positionMod.lat * degToRad)
      const sizeFactor = window.innerHeight / 600
      const latOffset = (294.67 * Math.exp(-0.69 * zoom) - 0.48) * latFactor * sizeFactor
      let latOffsetMod = ((!graphsOnSide && graphsVisible) ? latOffset : 0)
      // const latOffsetMod = 0

      const bounds = map.getBounds()
      const westLng = bounds.getWest(); // Minimum longitude
      const eastLng = bounds.getEast(); // Maximum longitude
      const lonRange = eastLng - westLng
      const lonOffset = -0.15 * lonRange
      let lonOffsetMod = ((graphsOnSide && graphsVisible) ? lonOffset : 0)
      // const lonOffsetMod = 0

      if (chromeIOSRotated) {
        if (landscape) {
          lonOffsetMod -= (0.25 * lonRange)
        } else {
          const northLat = bounds.getNorth()
          const southLat = bounds.getSouth()
          const latRange = northLat - southLat
          latOffsetMod += (0.25 * latRange)
        }
      }

      const positionUpdated = { lat: positionMod.lat - latOffsetMod, lng: positionMod.lng - lonOffsetMod };

      map.panTo(positionUpdated, {
        animate: true,        // Whether to animate the transition
        duration: 0.3,        // Duration of the transition in seconds
        easeLinearity: 1,   // How linear the easing function is (0 - 1)
      });
    }
  }, [map, position, togglePanToLocation, graphsVisible, multipanel, position2,
    landscape, chromeIOSRotated, panToLocationNumber]);

  return null;
};

// Hook to handle map events
const MapEventsComponent = () => {
  useEffect(() => {
    console.log("Rendered/mounted MapEventsComponent")
    return () => {
      console.log("Unmounted MapEventsComponent");
    };
  }, []);

  const dispatch = useDispatch()
  const abortController = new AbortController()
  const { updatePositionAndData } = useUpdatePositionAndData();

  function handleZoomEnd() {
    console.log("Zoom ended")
    dispatch(toggleVelocity())
  }

  function handleDragEnd() {
    console.log("Dragging ended")

    dispatch(toggleVelocity())
  }

  const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
  const graphsVisible = useTypedSelector(state => state.graph.graphsVisible)
  const routeNumber = useTypedSelector(state => state.data.routeNumber)

  useMapEvents({
    dragend: () => { handleDragEnd() },
    zoomend: () => { handleZoomEnd() },

    click(e) {
      abortController.abort()

      dispatch(wiggleBadges())

      if (isMobile) {
        // On mobile make a tap that occurs when the graphs are visible close the graphs
        // but on desktop don't worry about that and just change the location
        if (graphsVisible) {
          dispatch(closeGraphs())
          dispatch(toggleVelocity())
        } else {
          dispatch(toggleVelocity())
          const { lat, lng } = e.latlng;
          updatePositionAndData(lat, normalizeLongitude(lng), routeNumber, null, 'mapClick')
          console.log(`Clicked at latitude: ${lat}, longitude: ${lng}`);
        }
      } else {
        dispatch(toggleVelocity())
        const { lat, lng } = e.latlng;

        updatePositionAndData(lat, normalizeLongitude(lng), routeNumber, null, 'mapClick')
        console.log(`Clicked at latitude: ${lat}, longitude: ${lng}`);
      }
    },
  });
  return null;
};

const Viewer = ({ windowSize }) => {
  useEffect(() => {
    console.log("Rendered Viewer")
  })

  const corner1 = L.latLng(-79, -Infinity)
  const corner2 = L.latLng(82, Infinity)
  const bounds = L.latLngBounds(corner1, corner2)

  // Adjust zoom levels dynamically
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;
  const maxViewportDimension = Math.max(viewportWidth, viewportHeight);

  let minZoom, maxZoom;
  let zoomSnap = 1
  const minOffset = 0
  const maxOffset = 0
  if (maxViewportDimension < 700) {
    minZoom = 2
    maxZoom = 7 - maxOffset
    zoomSnap = 0.5
  } else if (maxViewportDimension < 1000) {
    minZoom = 2.5
    maxZoom = 8 - maxOffset
    zoomSnap = 0.5
  } else if (maxViewportDimension < 1200) {
    minZoom = 3
    maxZoom = 8 - maxOffset
    zoomSnap = 1
  } else if (maxViewportDimension < 1500) {
    minZoom = 3
    maxZoom = 9 - maxOffset
    zoomSnap = 1
  } else if (maxViewportDimension < 2000) {
    minZoom = 3
    maxZoom = 9 - maxOffset
    zoomSnap = 1
  } else {
    minZoom = 3
    maxZoom = 9 - maxOffset
  }

  minZoom -= minOffset
  zoomSnap = 1

  // const [landscape, setLandscape] = useState(window.innerHeight < window.innerWidth)

  // useEffect(() => {
  //   const handleResize = () => {
  //     setLandscape(window.innerHeight < window.innerWidth);
  //   };

  //   window.addEventListener('resize', handleResize);

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
  const spectralPalette = useTypedSelector(state => state.graph.spectralPalette)
  const climatologyIndex = useTypedSelector(state => state.graph.climatologyIndex)
  const climatologyView = useTypedSelector(state => state.graph.climatologyView)
  const windEnabled = useTypedSelector(state => state.graph.windEnabled)

  let tempStyle, precipStyle, places_style, boundaries_style, lakes_style, rivers_style
  if (!spectralPalette) {
    tempStyle = 'matt_temp_mono'
    precipStyle = 'matt_precip_for_mono'
    places_style = 'matt_places_white'
    boundaries_style = 'matt_boundaries_black'
    lakes_style = 'matt_lakes_mono'
    rivers_style = 'matt_rivers_mono'
  } else {
    tempStyle = 'matt_temp_spectral'
    precipStyle = 'matt_precip_for_spectral'
    places_style = 'matt_places_black'
    boundaries_style = 'matt_boundaries_grey'
    lakes_style = 'matt_lakes'
    rivers_style = 'matt_rivers'
  }

  let temperatureLayer: string
  let precipLayer: string
  let mapTime = ''
  if (!climatologyView) {
    temperatureLayer = 'era:t2m'
    precipLayer = 'era:tp'
  } else {
    temperatureLayer = 'era:t2m_clim'
    precipLayer = 'era:tp_clim'
    mapTime = `2000-${(climatologyIndex + 1).toString().padStart(2, '0')}-15T00:00:00.000000000Z`
  }

  // ============================
  // This section buffers changes to ClimatologyIndex, to avoid crashes in the velocity layer if
  // it mounts and unmounts too quickly
  // ============================
  const [climatologyIndexMod, setClimatologyIndexMod] = useState<number>(-1);

  // Ref to track the last update time
  const lastUpdateTimeRef = useRef<number | null>(null);
  // Ref to store the buffered change
  const bufferedValueRef = useRef<number | null>(null);
  // Ref to store the timer ID
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const now = Date.now();
    const lastUpdateTime = lastUpdateTimeRef.current;

    // Check if the last update happened more than 2 seconds ago
    if (!lastUpdateTime || now - lastUpdateTime >= 2000) {
      // Update climatologyIndexMod immediately
      setClimatologyIndexMod(climatologyIndex);
      lastUpdateTimeRef.current = now;
    } else {
      // If we are within the 2-second window, buffer the update
      bufferedValueRef.current = climatologyIndex;

      // Clear any previous timer
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      // Set a timer to apply the buffered value after the 2-second window
      timerRef.current = setTimeout(() => {
        if (bufferedValueRef.current !== null) {
          setClimatologyIndexMod(bufferedValueRef.current);
          lastUpdateTimeRef.current = Date.now();
          bufferedValueRef.current = null; // Clear the buffered value
        }
      }, 2000 - (now - lastUpdateTime)); // Only wait for the remaining time
    }

    // Cleanup on unmount
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [climatologyIndex]);
  // ============================
  // ============================

  return (
    <MapContainer
      style={mapStyle}
      attributionControl={false}
      zoomControl={false}
      zoomSnap={zoomSnap}
      center={{ lat: 0, lng: 0 }}
      zoom={minZoom}
      bounceAtZoomLimits={true}
      maxBounds={bounds}
      maxBoundsViscosity={0}
      minZoom={minZoom}
      maxZoom={maxZoom}
      dragging={true}
      inertia={false}
      worldCopyJump={true}
      key={windowSize.width / windowSize.height}
    >

      <WMSTileLayer
        url='https://era-explorer.ecmwf-development.f.ewcloud.host/geoserver/wms?'
        format='image/png'
        pane="tilePane"
        transparent={false}
        params={{
          layers: temperatureLayer,
          // @ts-ignore
          time: mapTime,
          styles: tempStyle,
        }}
      />

      {!spectralPalette &&
        <WMSTileLayer
          url='https://era-explorer.ecmwf-development.f.ewcloud.host/geoserver/wms?'
          format='image/png'
          pane="tilePane"
          transparent={true}
          params={{
            layers: precipLayer,
            // @ts-ignore
            time: mapTime,
            styles: precipStyle,
          }}
        />
      }

      <WMSTileLayer
        url='https://era-explorer.ecmwf-development.f.ewcloud.host/geoserver/wms?'
        transparent={true}
        format='image/png'
        pane="shadowPane"
        params={{
          layers: 'era:ne_10m_admin_0_earthkit',
          styles: boundaries_style,
        }}
      />

      <WMSTileLayer
        url='https://era-explorer.ecmwf-development.f.ewcloud.host/geoserver/wms?'
        transparent={true}
        format='image/png'
        pane="shadowPane"
        params={{
          layers: "ne:populated_places",
          styles: places_style,
          width: 512,
          height: 512,
        }}
      />

      <WMSTileLayer
        url='https://era-explorer.ecmwf-development.f.ewcloud.host/geoserver/wms?'
        transparent={true}
        format='image/png'
        pane="shadowPane"
        opacity={0.3}
        params={{
          layers: 'era:ne_50m_lakes',
          styles: lakes_style,
        }}
      />

      <WMSTileLayer
        url='https://era-explorer.ecmwf-development.f.ewcloud.host/geoserver/wms?'
        transparent={true}
        format='image/png'
        pane="shadowPane"
        opacity={0.3}
        params={{
          layers: 'era:ne_50m_rivers_lake_centerlines',
          styles: rivers_style,
        }}
      />

      {/* <WMSTileLayer
        url='https://era-explorer.ecmwf-development.f.ewcloud.host/geoserver/wms?'
        transparent={true}
        format='image/png'
        pane="shadowPane"
        opacity={0.6}
        params={{
          layers: 'era:ne_110m_graticules_1',
          styles: 'matt_gridlines',
        }}
      /> */}

      <AttributionControl
        prefix={false}
      />

      {!isMobile && <ZoomButtons />}

      <MapEventsComponent />

      <MapMarkerContainer />

      <PanToLocation />

      {windEnabled && <LeafletVelocity climatologyIndex={climatologyIndexMod} />}

    </MapContainer>
  )
}

export default Viewer
