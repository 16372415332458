import React, { memo, useEffect } from 'react';
import { ActionIcon, Tooltip, Group, Modal, Title, Text, Timeline, Indicator } from '@mantine/core';
import {
    IconX,
    IconWorldHeart,
    IconUmbrella,
    IconTemperaturePlus,
    IconChartHistogram,
    IconAB2,
    IconBeach,
    IconBaselineDensitySmall,
    IconLock,
} from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { usePreventPullToRefreshButton } from '../../hooks/usePreventPullToRefresh.ts';
import { ERA_VERSION } from '../../config/constants.ts';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers/store.ts';
import { setProgressIndicator, updateOpenedProgress } from '../../reducers/progressSlice.ts';
import ProgressIcon from './ProgressIcon.tsx';

const iconSize = 20
const timelineData = [
    {
        bullet: <IconWorldHeart size={iconSize} />,
        title: "Welcome to ERA explorer",
        text: `Powered up the app and started exploring the world according to ${ERA_VERSION}!`,
    },
    {
        bullet: <IconChartHistogram size={iconSize} />,
        title: "The power of knowledge",
        text: "Checked out all the graphs for a location",
    },
    {
        bullet: <IconTemperaturePlus size={iconSize} />,
        title: "Is it hot in here?",
        text: "Found somewhere very warm",
    },
    {
        bullet: <IconUmbrella size={iconSize} />,
        title: "Don't forget your umbrella",
        text: "Used the precipitation layer to find somewhere very wet. Tip: Open the settings menu for more options",
    },
    {
        bullet: <IconAB2 size={iconSize} />,
        title: "How do these compare?",
        text: "Compared 2 different locations",
    },
    {
        bullet: <IconBeach size={iconSize} />,
        title: "Southern hemisphere summer",
        text: "Changed the layers to monthly and found somewhere where January is hottest",
    },
    {
        bullet: <IconBaselineDensitySmall size={iconSize} style={{ rotate: '90deg' }} />,
        title: "#ShowYourStripes",
        text: "Shared the climate warming stripes for a location",
    },
];


function ProgressButtonWithPopup({ buttonWidth }) {
    useEffect(() => {
        console.log("Rendered ProgressButtonWithPopup")
    })

    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
    const progressInt = useTypedSelector(state => state.progress.progressInt)
    const progressIndicator = useTypedSelector(state => state.progress.progressIndicator)

    const dispatch = useDispatch()

    const [opened, { open, close }] = useDisclosure(false);

    const titleButtonWidth = buttonWidth
    const tooltipLabel = 'Progress meter'

    const buttonRef = usePreventPullToRefreshButton()

    const margin = 16

    function handleClose() {
        close()
        dispatch(setProgressIndicator(false));  // The first progress is for opening this, so we should cancel AFTER it is opened too
    }

    function handleOpen() {
        open();
        setTimeout(() => {
            dispatch(updateOpenedProgress(true));
        }, 500);  // Seem to need this debounce otherwise the animation skips to the end
        dispatch(setProgressIndicator(false));
    }

    const indicatorEnabled = progressIndicator || progressInt === -1

    const title = (
        <>
            <Group>
                <ActionIcon
                    size={titleButtonWidth}
                    ref={buttonRef}
                    onClick={handleClose}
                >
                    <ProgressIcon buttonWidth={buttonWidth} progressInt={progressInt} scale={1} />
                </ActionIcon>

                <Title order={3}>
                    Explore the explorer!
                </Title>
            </Group>

            <ActionIcon
                size={titleButtonWidth}
                onClick={handleClose}
                style={{ position: 'absolute', right: `${margin}px`, top: `${margin}px` }}
            >
                <IconX stroke={1.5} />
            </ActionIcon>
        </>
    )

    return (
        <>
            <Tooltip label={tooltipLabel}>
                <Indicator
                    inline
                    label={"!"}
                    processing
                    disabled={!indicatorEnabled}
                    color="c3sRed"
                    offset={6}
                    size={16}
                    position={'top-start'}
                    style={{height: `${buttonWidth}px`}}
                >
                    <ActionIcon
                        size={buttonWidth}
                        onClick={handleOpen}
                        ref={buttonRef}
                    >
                        <ProgressIcon buttonWidth={buttonWidth} progressInt={progressInt} scale={1} />
                    </ActionIcon>
                </Indicator>
            </Tooltip>

            <Modal
                opened={opened}
                onClose={handleClose}
                title={title}
                centered
                radius={7}
                overlayProps={{
                    backgroundOpacity: 0.55,
                    blur: 3,
                }}
                withCloseButton={false}
            >

                <Timeline active={progressInt} bulletSize={buttonWidth * 0.8} lineWidth={2} data-autofocus>
                    {timelineData.map((item, index) => (
                        <Timeline.Item
                            key={index}
                            bullet={(index <= (progressInt + 1)) ? item.bullet : <IconLock size={iconSize} color={'#999999'} />}
                            title={
                                <Text style={{ fontWeight: (index === (progressInt + 1) ? 'bold' : 'normal') }}>
                                    {item.title}
                                </Text>
                            }
                            lineVariant={index > progressInt ? 'dotted' : undefined}

                        >
                            <Text
                                c={index === (progressInt + 1) ? '' : 'dimmed'}
                                size="sm"
                                style={{
                                    marginBottom: -5,
                                }}
                            >
                                {(index <= (progressInt + 1)) ? item.text : "Complete the previous stage to advance"}
                            </Text>
                        </Timeline.Item>
                    ))}
                </Timeline>

            </Modal>
        </>
    );
}

export default memo(ProgressButtonWithPopup);