// CustomPlotly.js
import Plotly from 'plotly.js/lib/core';   // Base Plotly instance
import Scatter from 'plotly.js/lib/scatter';  // Import Scatter chart type
import Bar from 'plotly.js/lib/bar';          // Import Bar chart type
import Scatterpolar from 'plotly.js/lib/scatterpolar';
import Barpolar from 'plotly.js/lib/barpolar';

// Additional imports required for toImage functionality
// import 'plotly.js/lib/components/modebar'; // Required for exporting images
// import 'plotly.js/lib/components/legend';
// import 'plotly.js/lib/components/annotations';

// Register the chart types
Plotly.register([Scatter as any, Bar as any, Scatterpolar as any, Barpolar as any]);

export default Plotly;