import React, { useState, useEffect } from 'react'
import LocationSearchBar from './LocationSearchBarFree.tsx';
import { Group } from '@mantine/core';
import ProgressButtonWithPopup from './ProgressButtonWithPopup.tsx';
import SettingsButtonWithPopup from './SettingsButtonWithPopup.tsx';
import { MARGIN, MAX_INPUTS_WIDTH } from '../../config/constants.ts';
import MonthSlider from './MonthSlider.tsx';
import { usePreventPullToRefreshDiv } from '../../hooks/usePreventPullToRefresh.ts';

const Inputs = () => {
    console.log("Rendered Inputs")

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const topMargin = MARGIN
    const margin = MARGIN
    const buttonWidth = 40
    const gap = topMargin
    const maxBarWidth = MAX_INPUTS_WIDTH - (2 * buttonWidth + 2 * gap)
    const barWidth = Math.min(windowSize.width - 2 * margin - 2 * buttonWidth - 2 * gap, maxBarWidth)
    const barAndButtonsWidth = barWidth + 2 * buttonWidth + 2 * gap
    const markWidth = barAndButtonsWidth / 17
    const markOverflow = markWidth / 2
    const sliderWidth = barAndButtonsWidth - markOverflow * 2

    const sliderMargin = margin + markOverflow

    const divRef = usePreventPullToRefreshDiv()

    return (
        <div style={{ position: "fixed" }} ref={divRef}>
            <Group
                align="flex-start"
                gap={gap}
                style={{
                    margin: `${topMargin}px ${margin}px`,
                }}
            >

                <SettingsButtonWithPopup buttonWidth={buttonWidth} />
                <LocationSearchBar barWidth={barWidth} buttonWidth={buttonWidth} />
                <ProgressButtonWithPopup buttonWidth={buttonWidth} />
            </Group>

            <MonthSlider sliderWidth={sliderWidth} sliderMargin={sliderMargin} markWidth={markWidth} />
        </div>
    );
}

export default Inputs