import React, { memo, useState, useEffect, useRef } from 'react';
import { ActionIcon, Text, Center, RingProgress } from '@mantine/core';
import { IconTrophy } from '@tabler/icons-react';
import { theme } from '../../theme.tsx';

function ProgressIcon({ buttonWidth, progressInt, scale = 1 }) {
    const totalSteps = 7

    const startPercentComplete = Math.max(Math.round((progressInt) / totalSteps * 100), 0)
    const endPercentComplete = Math.round((progressInt + 1) / totalSteps * 100)

    const [percentComplete, setPercentComplete] = useState(endPercentComplete);
    const previousProgressIntRef = useRef(progressInt);

    const [animationComplete, setAnimationComplete] = useState(true);

    useEffect(() => {
        // Only trigger the animation if progressInt has changed
        if (previousProgressIntRef.current !== progressInt) {
            let currentPercent = percentComplete; // Start from the current percentComplete
            // const step = (endPercentComplete - currentPercent) / 20; // Divide the animation into 20 steps
            setAnimationComplete(false);
            setPercentComplete(startPercentComplete);

            const interval = setInterval(() => {
                if (currentPercent < endPercentComplete) {
                    currentPercent++;
                    setPercentComplete(currentPercent);
                } else {
                    clearInterval(interval);
                    setAnimationComplete(true);
                    console.log('TrackProgress: clearInterval')
                }
            }, 100); // Adjust the delay as needed for the speed of the transition

            // Update the previous progressInt to the current value
            previousProgressIntRef.current = progressInt;

            // Cleanup the interval on unmount or when progress changes
            return () => clearInterval(interval);
        } else {
            // If progressInt hasn't changed, then update the percentComplete directly
            setPercentComplete(endPercentComplete);
        }
    }, [progressInt]);

    const tutorialComplete = percentComplete >= 100
    const tutorialNotStarted = percentComplete <= 0

    const ringSize = buttonWidth * 1.1 * scale
    const ringThickness = 4 * scale
    const fontSize = 12 * scale
    const ringColor = 'c3sRed'

    const tutorialIcon = (
        tutorialNotStarted ?
            <IconTrophy stroke={1.5} />
            :
            <IconTrophy stroke={1.5} color={theme.colors.c3sRed[9]} />
    )

    const animDuration = 6

    const label = (
        <div style={{ position: "relative", display: "inline-block", width: '100%', height: '100%' }}>
            <Center
                style={{
                    animation: (animationComplete ? `progressfade1 ${animDuration}s ease-in-out infinite` : ''),
                    opacity: 0,
                }}
            >
                <ActionIcon size={ringSize * 0.5} style={{ boxShadow: "none" }}>
                    {tutorialIcon}
                </ActionIcon>
            </Center>

            <Text
                size={`${fontSize}`}
                c={ringColor}
                fw={700}
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -48.5%)",
                    textAlign: "center",
                    animation: (animationComplete ? `progressfade2 ${animDuration}s ease-in-out infinite` : ''),
                }}
            >
                {percentComplete}%
            </Text>
        </div>
    );

    return (
        ((!tutorialComplete && !tutorialNotStarted) ?
            <RingProgress
                size={ringSize}
                thickness={ringThickness}
                roundCaps
                sections={[{ value: percentComplete, color: ringColor }]}
                label={label}
            />
            :
            <RingProgress
                size={ringSize}
                thickness={ringThickness}
                roundCaps
                sections={[{ value: (tutorialComplete ? 100 : 0), color: ringColor }]}
                label={
                    <Center>
                        <ActionIcon size={ringSize * 0.5} style={{ boxShadow: "none" }} >
                            {tutorialIcon}
                        </ActionIcon>
                    </Center>
                }
            />
        )
    )
}

export default memo(ProgressIcon)