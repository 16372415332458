import { useEffect } from 'react'
import { useSelector, useDispatch, TypedUseSelectorHook } from 'react-redux'
import useUpdatePositionAndData from '../../hooks/useUpdatePositionAndData.ts';
import { RootState } from '../../reducers/store.ts';
import { getStateFromUrl } from '../../utils/urlState.ts';
import { openGraphs, updateShareLink } from '../../reducers/graphSlice.ts';
import { isMobile } from 'react-device-detect';
import { DEFAULT_LOCATION } from '../../config/constants.ts';

const ArrivedFromURL = () => {
  useEffect(() => {
    console.log("Rendered ArrivedFromURL")
  })
  const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
  const readLatLngFromURL = useTypedSelector(state => state.location.readLatLngFromURL)
  const dispatch = useDispatch()

  const { updatePositionAndData } = useUpdatePositionAndData();

  useEffect(() => {
    if (readLatLngFromURL) {

      const urlState = getStateFromUrl();

      const lat = urlState.location.locationPosition.lat;
      const lng = urlState.location.locationPosition.lng;
      const latLngValid = lat !== null && lng !== null

      const lat2 = urlState.location.locationPosition2.lat;
      const lng2 = urlState.location.locationPosition2.lng;
      const latLngBValid = lat2 !== null && lng2 !== null

      const comparisonMode = urlState.data.comparisonMode
      // const routeA = comparisonMode ? 1 : 0
      console.log('DID arrivedFromURL', comparisonMode, lat, lng, lat2, lng2, readLatLngFromURL)

      console.log("arrivedFromURL: ", latLngValid, latLngBValid)
      if (latLngValid && latLngBValid) {
        updatePositionAndData(lat, lng, 1, null, 'arrivedFromURL', true, true)
        updatePositionAndData(lat2, lng2, 0, null, 'arrivedFromURL', true, true)
      } else if (latLngValid) {
        updatePositionAndData(lat, lng, 0, null, 'arrivedFromURL', true)
      } else if (latLngBValid) {
        updatePositionAndData(lat2, lng2, 0, null, 'arrivedFromURL', true)
      }

      dispatch(openGraphs())
    } else {
      console.log('NOT arrivedFromURL')
      // const locationPosition = store.getState().location.locationPosition
      const locationPosition = DEFAULT_LOCATION
      const lat = locationPosition.lat;
      const lng = locationPosition.lng;
      updatePositionAndData(lat, lng, 0, null, 'NOT arrivedFromURL')

      if (!isMobile) {
        dispatch(openGraphs())
      }
    }
  }, [dispatch, updatePositionAndData, readLatLngFromURL]); // Add myStateVariable to dependencies

  useEffect(() => {
    // Function to update the state when the URL changes
    const updateUrl = () => {
      dispatch(updateShareLink(window.location.href))
    };

    // Listen to popstate events, which occur when the browser's navigation buttons are used
    // window.addEventListener('popstate', updateUrl);

    // Optionally, listen for changes in history.pushState or history.replaceState
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    window.history.pushState = function (...args) {
      originalPushState.apply(window.history, args);
      updateUrl();
    };

    window.history.replaceState = function (...args) {
      originalReplaceState.apply(window.history, args);
      updateUrl();
    };

    // Clean up event listeners when the component unmounts
    return () => {
      // window.removeEventListener('popstate', updateUrl);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, [dispatch]);

  return null
};

export default ArrivedFromURL;