import React, { createContext, useContext, useRef, useMemo } from 'react';

interface SequenceAbortControllerContextType {
    sequenceAbortController: React.MutableRefObject<AbortController>[];
    abortControllers: React.MutableRefObject<Record<string, AbortController | null>>[];
}

const SequenceAbortControllerContext = createContext<SequenceAbortControllerContextType | null>(null);

export const SequenceAbortControllerProvider = ({ children }) => {
    // Initialize the global sequenceAbortController
    const sac1 = useRef(new AbortController());
    const sac2 = useRef(new AbortController());

    const sequenceAbortController = useMemo(() => [sac1, sac2], []);

    // Initialize the abortControllers for individual keys
    const variableOptions = ["option1", "option2"]; // Replace with actual options

    const abortControllerSet1 = useRef(
        variableOptions.reduce((acc, option) => {
            acc[option] = null;
            return acc;
        }, {} as Record<string, AbortController | null>)
    );

    const abortControllerSet2 = useRef(
        variableOptions.reduce((acc, option) => {
            acc[option] = null;
            return acc;
        }, {} as Record<string, AbortController | null>)
    );

    const abortControllers = useMemo(() => [abortControllerSet1, abortControllerSet2], []);

    return (
        <SequenceAbortControllerContext.Provider value={{ sequenceAbortController, abortControllers }}>
            {children}
        </SequenceAbortControllerContext.Provider>
    );
};

export const useSequenceAbortController = () => {
    const context = useContext(SequenceAbortControllerContext);
    if (!context) {
        throw new Error("useSequenceAbortController must be used within a SequenceAbortControllerProvider");
    }
    return context;
};