import { ActionIcon, Image, Tooltip } from '@mantine/core';
import { IconShare } from '@tabler/icons-react';
import React, { memo, useEffect } from 'react';
import { useClipboard } from '@mantine/hooks';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers/store.ts';
import { isMobile } from 'react-device-detect';
import { setSharedStripes } from '../../reducers/progressSlice.ts';
import { notifications } from '@mantine/notifications';

function convertURLToClickedBox(url, buttonIndex) {
    const urlObj = new URL(url);
    urlObj.searchParams.set("plot", buttonIndex);
    return urlObj.toString();
}

export function ShareButton({ size, buttonIndex }) {
    useEffect(() => {
        console.log('Rendered ShareButton')
    })

    const clipboard = useClipboard({ timeout: 500 });

    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

    const dispatch = useDispatch();
    const prioritySlide = useTypedSelector(state => state.data.prioritySlide);

    function copyToClipboard(text) {
        clipboard.copy(text)
        notifications.show({
            title: 'ERA Explorer',
            message: 'Link copied to clipboard!',
            color: 'c3sRed',
            withBorder: true,
            withCloseButton: false,
            radius: 'md',
            w: 270,
            icon: <Image src={'/icons/apple-touch-icon-light.png'} radius={10} h={40} w={40} />
        })
    }

    const handleButtonClick = async () => {
        const currentUrl = convertURLToClickedBox(window.location.href, buttonIndex)
        if ((navigator.share) && isMobile) {
            try {
                await navigator.share({
                    title: 'ERA-explorer',
                    text: 'Weather and climate information from ECMWF',
                    url: currentUrl,
                });
                console.log('SHARE: Content shared successfully');

                if (prioritySlide === 5) {
                    console.log("SHARE: Setting sharedStripes to true");
                    dispatch(setSharedStripes(true));
                }
            } catch (error) {
                console.error('SHARE: Error sharing:', error);
            }
            console.log('SHARE:', currentUrl)
        } else {
            console.log('SHARE: Web Share API is not supported in your browser.', navigator.share, isMobile);
            console.log('SHARE:', currentUrl)
            if (prioritySlide === 5) {
                console.log("SHARE: Setting sharedStripes to true");
                dispatch(setSharedStripes(true));
            }
            copyToClipboard(currentUrl)
        }
    };


    return (
        <Tooltip label={"Share"}>
            <ActionIcon
                size={size}
                onClick={handleButtonClick}
            >
                <IconShare />
            </ActionIcon>
        </Tooltip>
    );
};

export default memo(ShareButton);
