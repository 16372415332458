import React from 'react';
import { ActionIcon, Tooltip } from '@mantine/core';
import { useDispatch } from 'react-redux'
import { hideSlide, showSlides, updateActiveSlide } from '../../reducers/dataSlice.ts'
import { useSelector, TypedUseSelectorHook } from 'react-redux'
import { RootState } from '../../reducers/store.ts';
import { MIN_SLIDES_REQD } from '../../config/constants.ts'
import { IconEyeOff, IconEye } from '@tabler/icons-react';
import { usePreventPullToRefreshButton } from '../../hooks/usePreventPullToRefresh.ts';

function HideSlideButton({ buttonWidth, buttonIndex }) {

    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
    const slidesVisible = useTypedSelector(state => state.data.slidesVisible)
    const oldActiveSlide = useTypedSelector(state => state.data.activeSlide)

    const numSlidesVisible = slidesVisible.filter(value => value === true).length

    let canHide = true
    if (numSlidesVisible <= MIN_SLIDES_REQD) {
        canHide = false
    }
    console.log("canHide: ", canHide)

    const dispatch = useDispatch()

    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        event.stopPropagation();

        dispatch(hideSlide(buttonIndex))

        let newActiveSlide = oldActiveSlide
        if (newActiveSlide >= (numSlidesVisible - 1)) {
            // -1 because we're hiding one right now
            newActiveSlide = 0
        }
        console.log("newActiveSlide (hide): ", newActiveSlide, numSlidesVisible)
        dispatch(updateActiveSlide(newActiveSlide))
    }

    const buttonRef = usePreventPullToRefreshButton()

    return (
        (canHide ?
            <Tooltip label={"Hide this slide"}>
                <ActionIcon
                    style={{ borderColor: 'black' }}
                    className={'buttonTransparent'}
                    onClick={handleClick}
                    size={buttonWidth}
                    ref={buttonRef}
                >
                    <IconEyeOff style={{ width: '70%', height: '70%' }} stroke={1.5} color='black' />
                </ActionIcon>
            </Tooltip>
            : null)
    );
}

function ShowSlidesButton({ buttonWidth }) {
    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
    const slidesVisible = useTypedSelector(state => state.data.slidesVisible)

    const dispatch = useDispatch()

    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        event.stopPropagation();

        dispatch(showSlides())
    }

    const allSlidesVisible = slidesVisible.every(value => value === true);

    const buttonRef = usePreventPullToRefreshButton()

    return (
        (allSlidesVisible ? null :
            <Tooltip label={"Show all slides"}>
                <ActionIcon
                    style={{ borderColor: 'black' }}
                    className={'buttonTransparent'}
                    onClick={handleClick}
                    size={buttonWidth}
                    ref={buttonRef}
                >
                    <IconEye style={{ width: '70%', height: '70%' }} stroke={1.5} color='black' />
                </ActionIcon>
            </Tooltip>
        )
    );
}

export { ShowSlidesButton, HideSlideButton };
