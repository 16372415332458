import React, { ReactNode } from "react";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  // This method updates state when an error is caught
  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  // This method is called after an error is caught to log error details
  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    console.error("Error caught by Error Boundary:", error, info);
  }

  // Reload the page if an error has occurred
  componentDidUpdate(_: ErrorBoundaryProps, prevState: ErrorBoundaryState): void {
    if (this.state.hasError && !prevState.hasError) {
      setTimeout(() => {
        window.location.reload();
      }, 3000); // Reload after 3 seconds
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{color: 'white'}}>
          <h1>Oops! Something went wrong. Please try refreshing the page...</h1>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;