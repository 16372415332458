import "leaflet-velocity-ts/dist/leaflet-velocity.js";
import { useEffect } from "react";
import L from "leaflet";
import { useMap } from "react-leaflet";
import { isMobile } from 'react-device-detect';

function onAdd() {
    console.log('LeafletVelocity onAdd')
}

const LeafletVelocity = ({ climatologyIndex }) => {
    const map = useMap();

    let suffix
    if (climatologyIndex === -1) {
        suffix = "time_mean"
    } else {
        const clim_string = climatologyIndex.toString()
        suffix = "climatology_" + clim_string
        console.log("wind XX", typeof (climatologyIndex), climatologyIndex, suffix)
    }
    const wind_file = `${process.env.PUBLIC_URL}/windData/wind_data_19912010_${suffix}.json`
    console.log("wind_file", suffix)


    useEffect(() => {
        console.log('Rendered/mounted velocity')
        return () => {
            console.log("Unmounted velocity");
        };
    }, []);

    useEffect(() => {
        if (!map) return;
        console.log('Rendered velocity (really)')

        let mounted = true;
        let windGlobalLayer;
        const colorScale = ["#ffffff"]
        // const colorScale = ["#0000ff66"]

        const heightScaled = window.innerHeight / 100000
        const widthScaled = window.innerWidth / 1000000
        const aspectRatio = window.innerWidth / window.innerHeight
        const aspectRatioScaled = aspectRatio / 100
        const mobileValue = 0.002
        console.log("widthScaled", widthScaled, heightScaled, aspectRatioScaled)
        let particleMultiplier = isMobile ? mobileValue : 0.006
        particleMultiplier /= 2

        let velocityScale = isMobile ? 0.04 : 0.015
        // velocityScale /= 2

        let particleAge = isMobile ? 20 : 50
        particleAge /= 2

        const particlelineWidth = isMobile ? 1 : 2

        // const wind_file = "/wind_data.json"

        // TImeout to delay loading the velocity slightly, as it seems to cause a crash on mobile possibly
        // due to the map not being ready yet
        setTimeout(() => {
            fetch(wind_file)
                .then((response) => response.json())
                .then((data) => {
                    if (!mounted) return;

                    windGlobalLayer = (L as any).velocityLayer({
                        displayValues: false,
                        displayOptions: {
                            velocityType: "ERA winds",
                            position: "bottomleft",
                            emptyString: "No wind data"
                        },
                        data: data,

                        particleAge: particleAge,
                        particleMultiplier: particleMultiplier,
                        particlelineWidth: particlelineWidth,
                        frameRate: 25,
                        // minVelocity: 0.0,
                        maxVelocity: 10,
                        velocityScale: velocityScale, // modifier for particle animations, arbitrarily defaults to 0.005
                        colorScale: colorScale,
                        onAdd: onAdd,
                        onRemove: () => console.log('LeafletVelocity onRemove'),
                        // opacity: 0.8, // layer opacity, default 0.97  // Must not make this 1!
                        paneName: "overlayPane",
                    });


                    if (map && windGlobalLayer) {
                        windGlobalLayer.addTo(map);
                        // requestAnimationFrame(() => {
                        //     windGlobalLayer.addTo(map);
                        // });
                    }
                })
                .catch((err) => console.log("VELOCITY", err));
        }, 750); // Add a small delay to allow map rendering


        return () => {
            if (map && windGlobalLayer) {
                console.log("Unmounted velocity layer")
                map.removeLayer(windGlobalLayer);
            }
            mounted = false;
        };
    }, [map, wind_file]);

    return null;
};

export default LeafletVelocity;