import { Slider } from '@mantine/core';
import React, { memo, useState } from 'react';
import classes from '../../styles/MonthSlider.module.css';
import {
    IconLetterASmall, IconLetterJSmall, IconLetterMSmall, IconLetterOSmall, IconLetterFSmall,
    IconLetterSSmall, IconLetterNSmall, IconLetterDSmall
} from '@tabler/icons-react';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { updateClimatologyIndex } from '../../reducers/graphSlice.ts';
import { RootState } from '../../reducers/store.ts';
import { theme } from '../../theme.tsx';
import { BOX_SHADOW } from '../../config/constants.ts';

const iconStroke = 2
const iconSize = '130%'
const iconStyle = { width: iconSize, height: iconSize, minWidth: iconSize, minHeight: iconSize }
const minWidth = 400

function MonthSlider({ sliderWidth, sliderMargin, markWidth }) {
    const defaultValue = 0
    const [sliderValue, setSliderValue] = useState(defaultValue);  // Initialize state with default value

    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
    const spectralPalette = useTypedSelector(state => state.graph.spectralPalette)
    const climatologyView = useTypedSelector(state => state.graph.climatologyView)

    const dispatch = useDispatch()

    const handleChange = (value) => {
        setSliderValue(value);
        dispatch(updateClimatologyIndex(value))
    }

    const markLabelColor = spectralPalette ? 'black' : 'black'

    const rotate = sliderWidth < minWidth
    const labelStyle: React.CSSProperties = {
        rotate: `${rotate ? 35 : 0}deg`,
    }

    const iconColor = 'white'

    const marks = [
        { value: 0, label: <div style={labelStyle}>Jan</div>, letter: 'J', icon: <IconLetterJSmall style={iconStyle} stroke={iconStroke} color={iconColor} /> },
        { value: 1, label: <div style={labelStyle}>Feb</div>, letter: 'F', icon: <IconLetterFSmall style={iconStyle} stroke={iconStroke} color={iconColor} /> },
        { value: 2, label: <div style={labelStyle}>Mar</div>, letter: 'M', icon: <IconLetterMSmall style={iconStyle} stroke={iconStroke} color={iconColor} /> },
        { value: 3, label: <div style={labelStyle}>Apr</div>, letter: 'A', icon: <IconLetterASmall style={iconStyle} stroke={iconStroke} color={iconColor} /> },
        { value: 4, label: <div style={labelStyle}>May</div>, letter: 'M', icon: <IconLetterMSmall style={iconStyle} stroke={iconStroke} color={iconColor} /> },
        { value: 5, label: <div style={labelStyle}>Jun</div>, letter: 'J', icon: <IconLetterJSmall style={iconStyle} stroke={iconStroke} color={iconColor} /> },
        { value: 6, label: <div style={labelStyle}>Jul</div>, letter: 'J', icon: <IconLetterJSmall style={iconStyle} stroke={iconStroke} color={iconColor} /> },
        { value: 7, label: <div style={labelStyle}>Aug</div>, letter: 'A', icon: <IconLetterASmall style={iconStyle} stroke={iconStroke} color={iconColor} /> },
        { value: 8, label: <div style={labelStyle}>Sep</div>, letter: 'S', icon: <IconLetterSSmall style={iconStyle} stroke={iconStroke} color={iconColor} /> },
        { value: 9, label: <div style={labelStyle}>Oct</div>, letter: 'O', icon: <IconLetterOSmall style={iconStyle} stroke={iconStroke} color={iconColor} /> },
        { value: 10, label: <div style={labelStyle}>Nov</div>, letter: 'N', icon: <IconLetterNSmall style={iconStyle} stroke={iconStroke} color={iconColor} /> },
        { value: 11, label: <div style={labelStyle}>Dec</div>, letter: 'D', icon: <IconLetterDSmall style={iconStyle} stroke={iconStroke} color={iconColor} /> },
    ];

    return (
        <div style={{
            maxWidth: `${sliderWidth + sliderMargin * 2}px`,
            height: '80px',
            marginTop: '-10px',
            display: climatologyView ? "inline" : "none",
            // backgroundColor: 'blue !important',
        }}>
            {/* This is to capture the clicks */}
            <div
                style={{
                    width: `${sliderWidth + sliderMargin * 2}px`,
                    height: '80px',
                    position: 'relative',
                    top: '0px',
                    zIndex: 1,
                    backgroundColor: "#ffffff66",
                    borderRadius: "10px",
                    WebkitBackdropFilter: 'blur(2px)', /* Adjust the blur level */
                    backdropFilter: 'blur(2px)', /* Adjust the blur level */
                    willChange: "transform, backdrop-filter",
                }}
            />

            <div style={{
                position: "relative",
                top: -41,
                zIndex: 1,
                marginLeft: `${sliderMargin}px`,
                marginRight: `${sliderMargin}px`,
                marginTop: '-20px',
                // backgroundColor: 'white',
                // width: `${sliderWidth}px`,
                // height: '80px',
            }}>
                <Slider
                    thumbChildren={(marks.find((mark) => mark.value === sliderValue)!.icon)}
                    defaultValue={defaultValue}
                    label={null}
                    step={1}
                    marks={marks}
                    min={defaultValue}
                    max={11}
                    value={sliderValue}
                    onChange={handleChange}
                    classNames={classes}
                    styles={{
                        markLabel: {
                            color: markLabelColor,
                            textAlign: 'center',
                            fontWeight: "700",
                            transform: `translateX(-${!rotate ? (markWidth / 3) : 3}px) translateY(4px)`,
                            textShadow:
                                `1px 1px 0px white,
                                -1px 1px 0px white,
                                1px -1px 0px white,
                                -1px -1px 0px white,
                                1px 0px 0px white,
                                -1px 0px 0px white,
                                0px 1px 0px white,
                                0px -1px 0px white`,
                        },
                        mark: {
                            width: `${markWidth}px`,
                            height: `${markWidth}px`,
                            transform: `translateX(-33%) translateY(-${markWidth * 1.3}%)`,
                            borderWidth: 0,
                            borderColor: 'black',
                            backgroundColor: theme.colors.c3sBlue[9],
                        },
                        thumb: {
                            height: 40,
                            width: 40,
                            backgroundColor: theme.colors.c3sRed[9],
                            color: 'black',
                            borderWidth: 0,
                            boxShadow: BOX_SHADOW,
                        },
                        bar: {
                            opacity: 0,
                        },
                    }}
                />
            </div>
        </div>
    );
}

export default memo(MonthSlider);