import { ERA_VERSION } from "./constants.ts";

const getBonusText = () => {
    const text = `
                All of the climate statistics generated and visualised by this application are derived from the <b>${ERA_VERSION}</b> reanalysis dataset,
                which is available to download and use under the Copernicus licence.
                <b>${ERA_VERSION}</b> is a global <b>atmospheric reanalysis</b> that covers the period from 1940 to present and is developed and maintained by <b>ECMWF</b>.
                This application uses the <b>hourly, 31km</b> high resolution reanalysis, interpolated to a regular 0.25° × 0.25° grid.
                The information presented by this application for specific locations is based on the <b>nearest grid point</b> to the given location in
                the <b>${ERA_VERSION}</b> reanalysis and is not based on site-specific observations.
                Map layers show 30 year averages from 1991-2020 inclusive.`
    return text;
};

const getDisclaimerText = () => {
    const text = `
                The designations employed and the presentation of material on the map do not imply the expression of any opinion whatsoever
                on the part of the European Union concerning the legal status of any country, territory or area or of its authorities,
                or concerning the delimitation of its frontiers or boundaries.
                Kosovo* This designation is without prejudice to positions on status, and is in line with UNSCR 1244/1999 and the ICJ Opinion on the Kosovo declaration of independence.
                `
    return text;
};

const getHiddenText = () => {
    const text = `
                Based on the ${ERA_VERSION} reanalysis, developed and maintained by ECMWF.`;
    return text;
};

const getECMWFText = () => {
    const text = `
                We are both a <b>research institute</b> and a <b>24/7 operational service</b>, producing global numerical weather predictions and other data 
                for our <b>Member and Co-operating States</b> and the broader community. We operate a <b>world-class supercomputer facility</b> for weather forecasting 
                and hold one of the <b>largest meteorological data archives</b>.`;
    return text;
};

export { getBonusText, getHiddenText, getECMWFText, getDisclaimerText };
