import { ActionIcon, createTheme, Tooltip, DEFAULT_THEME, mergeMantineTheme } from '@mantine/core';

const myTheme = createTheme({
    fontFamily: 'Lato, Noto Sans, sans-serif',

    primaryColor: 'c3sRed',
    primaryShade: 9,

    colors: {
        // These colours are made taking the C3S colours and then linearly going from that HSV to (H, 0, 100) (i.e. same H; S to 0; V to 100%)
        "c3sRed": [
            "#ffffff",
            "#edd5db",
            "#e8bcc7",
            "#db9cac",
            "#cf7e92",
            "#c4667e",
            "#b84d68",
            "#ab3754",
            "#a12544",
            "#941333"
        ],
        "c3sGrey": [
            "#ffffff",
            "#edeff0",
            "#dee0e0",
            "#cfd2d4",
            "#c0c2c4",
            "#b0b2b5",
            "#a1a3a6",
            "#939699",
            "#84878a",
            "#75787b"
        ],
        "c3sYellow": [
            "#ffffff",
            "#fff9e3",
            "#fff3c7",
            "#ffedab",
            "#ffe78f",
            "#ffe070",
            "#ffda54",
            "#ffd438",
            "#ffce1c",
            "#ffc800"
        ],
        "c3sBlue": [
            "#ffffff",
            "#dfe4f2",
            "#c1cbe6",
            "#a3b1d9",
            "#899bcc",
            "#7287c2",
            "#5c74b5",
            "#475fa8",
            "#354f9c",
            "#25408f"
        ],
        "test": [
            "#ffffff",
            "#edd5db",
            "#e8bcc7",
            "#db9cac",
            "#cf7e92",
            "#c4667e",
            "#b84d68",
            "#ab3754",
            "#a12544",
            "#941333"
        ],
    },

    components: {

        ActionIcon: ActionIcon.extend({
            defaultProps: {
                variant: 'filled',
                color: '#f5f5f5',
            },

            styles: {
                root: {
                    borderRadius: 1000,
                    padding: 0,
                    margin: 0,
                    borderColor: 'white',
                    borderWidth: 0,
                    pointerEvents: 'auto',
                    boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.5)'  // right, down, blur, borderWidth, color
                },
                icon: {
                    style: {width: '70%', height: '70%'},
                    color: 'black',
                }
            }

        }),

        Tooltip: Tooltip.extend({
            defaultProps: {
                transitionProps: {
                    transition: 'fade',
                    duration: 300,
                },
                openDelay: 750,
                withArrow: true,
            }
        }),

    }
});

export const theme = mergeMantineTheme(DEFAULT_THEME, myTheme);
