import '@mantine/carousel/styles.css';
import React, { useState, useRef, useEffect, memo } from 'react'
import { Carousel, CarouselProps, Embla, useAnimationOffsetEffect } from '@mantine/carousel';
import { useDispatch } from 'react-redux';
import { updateActiveSlide } from '../../reducers/dataSlice.ts'
import '@mantine/carousel/styles.css';
import classesHoriz from '../../styles/CarouselHoriz.module.css';
import classesVert from '../../styles/CarouselVert.module.css';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import ScienceBox from './ScienceBox.tsx';
import { isMobile } from 'react-device-detect';
import { IconArrowLeft, IconArrowRight, IconArrowUp, IconArrowDown } from '@tabler/icons-react';
import { useSelector, TypedUseSelectorHook } from 'react-redux'
import ToggleGraphs from './ToggleGraphs.tsx';
import { toggleGraphs } from '../../reducers/graphSlice.ts';
import { TOTAL_SLIDES, variableOptions } from '../../config/constants.ts';
import store, { RootState } from '../../reducers/store.ts';
import { usePreventPullToRefreshDiv } from '../../hooks/usePreventPullToRefresh.ts';
import { updateViewedSlides } from '../../reducers/progressSlice.ts';

function CarouselContainer({ windowSize }) {
  useEffect(() => {
    console.log("Rendered CarouselContainer")
  })

  const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
  const displayGraphs = useTypedSelector(state => state.graph.graphsVisible)
  const multipanel = useTypedSelector(state => state.graph.multipanel)

  const targetSlideAspectRatio = 1.7  // Height / width of the card

  let landscape = true
  if ((windowSize.width < windowSize.height) || multipanel) {
    landscape = false
  }

  const maxSlideWidth = 450
  const maxSlideHeight = 900
  
  let slideWidth = Math.min(window.innerWidth * 0.87, maxSlideWidth)
  let slideHeight = slideWidth * targetSlideAspectRatio
  let height = slideHeight

  let myStyle
  let transform

  if (landscape && !multipanel) {
    // TODO: This is duplicated inside the carousel code!
    // If landscape then put scroll on the right
    height = window.innerHeight

    
    slideHeight = Math.min(window.innerHeight * 0.9, maxSlideHeight)
    slideWidth = slideHeight / targetSlideAspectRatio

    const minSlideWidth = 300
    // const maxSlideWidth = 450
    if (slideWidth > maxSlideWidth) {
      slideWidth = maxSlideWidth
      slideHeight = slideWidth * targetSlideAspectRatio
    } else if (slideWidth < minSlideWidth) {
      const minAspectRatio = 1.2
      const newAspectRatio = Math.max(slideHeight / minSlideWidth, minAspectRatio)
      slideWidth = slideHeight / newAspectRatio
    }

    transform = `translateX(${slideWidth * 0.98}px)`

    myStyle = {
      right: "0px",
      height: { height },
      minHeight: { height },
      maxHeight: { height },
      top: '0px',
      textAlign: "right",
      display: "flex",
      // zIndex: 10000,
    }

  } else {
    // If portrait (i.e. mobile) then put scroll on the bottom and ensure that the overall height of the slide won't be too large
    const maxSlideHeightRatio = isMobile ? 0.7 : 0.62
    const maxHeight = window.innerHeight * maxSlideHeightRatio
    const slideHeightRatio = slideHeight / maxHeight
    if (slideHeightRatio > 1) {
      slideHeight /= slideHeightRatio
    }
    const minAspectRatio = multipanel ? 1.7 : 1.2
    if ((slideHeight / slideWidth) < minAspectRatio) {
      slideWidth = slideHeight / minAspectRatio
    }
    height = slideHeight

    transform = `translateY(${isMobile ? height * 0.98 : height * 0.98}px)`  // To cover the attribution better

    myStyle = {
      width: '100%',
      minWidth: '100%',
      maxWidth: '100%',
      bottom: `0px`,
      textAlign: "right",
      // zIndex: 10000,
    }
  }

  const containerStyle: React.CSSProperties = {
    ...myStyle,
    position: 'fixed',
    pointerEvents: "none",
    transform: transform,
    transition: "transform 500ms ease",
  }

  const containerStyleHidden: React.CSSProperties = {
    ...myStyle,
    position: 'fixed',
    pointerEvents: "none",
    transform: `translateX(0)`,
    transition: "transform 500ms ease",
  }

  console.log("CAROUSEL CONTAINER", slideWidth, slideHeight, slideHeight / slideWidth, landscape, multipanel)

  return (
    <div
      style={!displayGraphs ? containerStyle : containerStyleHidden}
    >
      <MyCarousel windowSize={windowSize} landscape={landscape} slideWidth={slideWidth} slideHeight={slideHeight} multipanel={multipanel} />
    </div>
  );
}

function MyCarousel({ windowSize, landscape, slideWidth, slideHeight, multipanel }) {
  useEffect(() => {
    console.log("Rendered MyCarousel")
  })

  const [embla, setEmbla] = useState<Embla | null>(null);
  const wheelGestures = useRef(WheelGesturesPlugin());
  const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
  const slidesVisible = useTypedSelector(state => state.data.slidesVisible)

  const dispatch = useDispatch();

  useEffect(() => {
    const captureIndex = () => {
      const newActiveSlide = embla?.internalEngine().index.get() ?? 0

      dispatch(updateViewedSlides(newActiveSlide))

      dispatch(updateActiveSlide(newActiveSlide))

      console.log("newActiveSlide", newActiveSlide, store.getState().data.prioritySlide)  // This is always in the range 0<nVisibleSlides (which can get smaller)
    }

    embla?.on("select", captureIndex);

    return () => {
      embla?.off("select", captureIndex);
    };
  }, [embla, dispatch]);

  let height = slideHeight

  let orientation: CarouselProps["orientation"] = 'horizontal'

  let slideGap
  let gradientDirection, blurPadding
  let nextControlIcon, prevControlIcon

  if (landscape && !multipanel) {
    // If landscape then put scroll on the right
    height = window.innerHeight
    orientation = 'vertical'

    slideGap = slideHeight * 0.01

    gradientDirection = 'left'
    blurPadding = '0px 5px 0px 3px'

    nextControlIcon = <IconArrowDown />
    prevControlIcon = <IconArrowUp />
  } else {
    // If portrait (i.e. mobile) then put scroll on the bottom and ensure that the overall height of the slide won't be too large
    slideGap = slideWidth * 0.02
    orientation = 'horizontal'
    height = slideHeight

    gradientDirection = "top"
    blurPadding = '1px 0px 2px 0px'

    nextControlIcon = <IconArrowRight />
    prevControlIcon = <IconArrowLeft />
  }

  const blurContainerStyle: React.CSSProperties = {
    WebkitBackdropFilter: 'blur(2px)', /* Adjust the blur level */
    backdropFilter: 'blur(2px)', /* Adjust the blur level */
    willChange: "transform, backdrop-filter",
    background: `linear-gradient(to ${gradientDirection}, rgba(255, 255, 255, 0.3) 98%, rgba(255, 255, 255, 0) 100%)`,
    padding: `${blurPadding}`,
    pointerEvents: "auto",
  }

  const plugins = landscape ? [wheelGestures.current] : undefined

  const plotToShow = useTypedSelector(state => state.data.plotToShow)
  const [initialSlide, setInitialSlide] = useState(0)
  useEffect(() => {
    if ((slidesVisible.filter(Boolean).length === TOTAL_SLIDES) && (plotToShow !== null)) {
      // All slides are visible, we might have just arrived to set the initial slide
      setInitialSlide(plotToShow)
    }
  }, [plotToShow, slidesVisible])

  const maxButtonSize = 50
  const minButtonSize = 40
  const buttonSizeFromWindow = Math.max(windowSize.height, windowSize.width) * 0.07
  const buttonWidth = Math.min(Math.max(minButtonSize, buttonSizeFromWindow), maxButtonSize)

  const tapToCloseStyle: React.CSSProperties = {
    width: "100%",
    height: '20px',
    backgroundColor: '#ff000000',
    // backgroundColor: '#ff000066',
    position: 'absolute',
    pointerEvents: "auto",
    top: `${buttonWidth + 6}px`,
    zIndex: 1000,
  }

  const [isListening, setIsListening] = useState(true); // State to trigger re-renders if needed
  const [startY, setStartY] = useState<number | null>(null);
  const isListeningRef = useRef(true); // Ref for immediate state updates
  const minDragDist = 10
  const graphsVisible = useTypedSelector(state => state.graph.graphsVisible)

  const handleTouchMove = (event) => {
    // Only proceed if we are still listening
    if (isListeningRef.current && isListening) {
      // Stop listening after detecting the first touch move

      if (startY) {
        if ((((startY - event.touches[0].clientY) > minDragDist) && (!graphsVisible)) ||
          (((event.touches[0].clientY - startY) > minDragDist) && (graphsVisible))) {
          dispatch(toggleGraphs());

          // Update ref immediately
          isListeningRef.current = false;
          // Optionally update state for re-render purposes
          setIsListening(false);
        }
      }
    }
  };

  const handleTouchStart = (event) => {
    // console.log("Touch start, ready to capture next move.");

    // Reset ref immediately
    isListeningRef.current = true;
    // Optionally update state for re-render purposes
    setIsListening(true);
    setStartY(event.touches[0].clientY);
  };

  // Create a ref for the specific element where you want to prevent pull-to-refresh
  const dragToCloseRef = usePreventPullToRefreshDiv();

  const graphsButtonDiv: React.CSSProperties = {
    width: 'fit-content',  // Adjust width to the content (or you can specify a fixed width if needed)
    marginLeft: ((multipanel || (!isMobile && !landscape)) ? 'auto' : '10px'),
    marginRight: ((multipanel || (!isMobile && !landscape)) ? '10px' : 'auto'),
    marginBottom: '0px', // Adds space between this div and the next one
    marginTop: '10px', // Adds space between this div and the next one
    // pointerEvents: "auto",
  }

  const nodeRef = usePreventPullToRefreshDiv();

  useEffect(() => {
    if (!embla) return;

    console.log("Embla carousel resized");
    embla.reInit();
  }, [embla, windowSize]);

  useAnimationOffsetEffect(embla, 1000);

  return (
    <>
      <div ref={nodeRef} style={graphsButtonDiv}>
        <ToggleGraphs buttonWidth={buttonWidth} />
      </div>

      {(isMobile && !landscape) &&
        <div
          style={tapToCloseStyle}
          onTouchMove={handleTouchMove}
          onTouchStart={handleTouchStart}
          ref={dragToCloseRef}
        />
      }

      <div style={blurContainerStyle}>
        <Carousel
          withControls={!isMobile}
          controlSize={60}
          withIndicators
          height={height}
          orientation={orientation}
          slideSize={{ base: `${slideWidth}` }}
          slideGap={slideGap}
          nextControlIcon={nextControlIcon}
          previousControlIcon={prevControlIcon}
          loop
          initialSlide={initialSlide}
          slidesToScroll={1}
          align='center'
          classNames={landscape ? classesVert : classesHoriz}
          plugins={plugins}
          getEmblaApi={setEmbla}
          styles={{
            root: {
            },
            indicator: {
              backgroundColor: "#828282",
            }
          }}
        >

          {variableOptions.map((slide, index) =>
            slidesVisible[index] && (
              <Carousel.Slide key={index} hidden={!slidesVisible[index]}>
                <ScienceBox
                  width={slideWidth}
                  height={slideHeight}
                  variable={slide.name}
                  landscape={landscape}
                  buttonIndex={index}
                  mutuallyExclusive={slide.mutuallyExclusive}
                />
              </Carousel.Slide>
            )
          )}

        </Carousel>
      </div>
    </>
  );
}

export default memo(CarouselContainer)