import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getStateFromUrl } from '../utils/urlState.ts';
import { TOTAL_SLIDES, MIN_SLIDES_REQD, variableOptions } from '../config/constants.ts';

interface LatLng {
    lat: number;
    lng: number;
}

interface LocationInfo {
    placeName: string;
    position: LatLng;
}

// How the json data looks on receipt from the server
interface Climatology {
    data: {
        time: number[] | null;
        min: number[] | null;
        diff: number[] | null;
        clim_period: string | null;
        x: number | null;
        y: number | null;
        r: number | null;
    },
    metadata: {
        locationName: string | null;
        locationPosition: LatLng | null;
        clim_period: string | null;
        gotData: boolean
    }
}

// How I am storing it
interface ClimatologyState {
    0: Climatology;
    1: Climatology;
}

interface TemperatureClimatology extends Climatology {
    data: Climatology['data'] & {
        frost: number[] | null;
        tropical: number[] | null;
        summer: number[] | null;
    }
}

interface TemperatureClimatologyState {
    0: TemperatureClimatology;
    1: TemperatureClimatology;
}

// How the json data looks on receipt from the server
interface TimeSeries {
    data: {
        time: number[] | null;
        values: number[] | null;
        anomalies: number[] | null;
        anomalyScaling: number | null;
        clim_period: string | null;
        x: number | null;
        y: number | null;
        r: number | null;
    },
    metadata: {
        locationName: string | null;
        locationPosition: LatLng | null;
        clim_period: string | null;
        gotData: boolean
    }
}

// How I am storing it
interface TimeSeriesState {
    0: TimeSeries;
    1: TimeSeries;
}

interface WindRose {
    data: {
        anglePcs_at_speed1: number[] | null;
        anglePcs_at_speed2: number[] | null;
        anglePcs_at_speed3: number[] | null;
        anglePcs_at_speed4: number[] | null;
        angle_bins: number[] | null;
        cutoff_labels: string[] | null;
        clim_period: string | null;
        x: number | null;
        y: number | null;
        r: number | null;
    },
    metadata: {
        locationName: string | null;
        locationPosition: LatLng | null;
        clim_period: string | null;
        gotData: boolean
    }
}

interface PrecipHistogramState {
    0: PrecipHistogram;
    1: PrecipHistogram;
}

interface PrecipHistogram {
    data: {
        histogram: number[] | null;
        bin_edges: number[] | null;
        clim_period: string | null;
        x: number | null;
        y: number | null;
        r: number | null;
    },
    metadata: {
        locationName: string | null;
        locationPosition: LatLng | null;
        clim_period: string | null;
        gotData: boolean
    }
}

interface WindRoseState {
    0: WindRose;
    1: WindRose;
}

// Define the overall state type
interface DataState {
    tempAnn: TimeSeriesState;

    tempDaily: ClimatologyState;

    tempMonthly: TemperatureClimatologyState;

    precipMonthly: ClimatologyState;

    humidityMonthly: ClimatologyState;

    windAnn: WindRoseState;

    precipAnn: TimeSeriesState;

    precipHourly: PrecipHistogramState;

    prioritySlide: number;

    activeSlide: number;

    plotToShow: number | null;

    slidesVisible: boolean[];

    fetchingData: boolean[];
    routeNumber: number;

    popupsInverted: boolean;

    comparisonMode: boolean;
}

const createEmptyTimeSeries = (): TimeSeries => ({
    data: {
        time: null,
        values: null,
        anomalies: null,
        anomalyScaling: null,
        clim_period: null,
        x: null,
        y: null,
        r: null,
    },
    metadata: {
        locationName: null,
        locationPosition: null,
        clim_period: null,
        gotData: false,
    }
});

const createEmptyTimeSeriesState = (): TimeSeriesState => ({
    0: createEmptyTimeSeries(),
    1: createEmptyTimeSeries(),
});

const createEmptyWind = (): WindRose => ({
    data: {
        anglePcs_at_speed1: null,
        anglePcs_at_speed2: null,
        anglePcs_at_speed3: null,
        anglePcs_at_speed4: null,
        angle_bins: null,
        cutoff_labels: null,
        clim_period: null,
        x: null,
        y: null,
        r: null,
    },
    metadata: {
        locationName: null,
        locationPosition: null,
        clim_period: null,
        gotData: false,
    }
});

const createEmptyWindState = (): WindRoseState => ({
    0: createEmptyWind(),
    1: createEmptyWind(),
});

const createEmptyHist = (): PrecipHistogram => ({
    data: {
        histogram: null,
        bin_edges: null,
        clim_period: null,
        x: null,
        y: null,
        r: null,
    },
    metadata: {
        locationName: null,
        locationPosition: null,
        clim_period: null,
        gotData: false,
    }
});

const createEmptyHistState = (): PrecipHistogramState => ({
    0: createEmptyHist(),
    1: createEmptyHist(),
});

const createEmptyClimatology = (): Climatology => ({
    data: {
        time: null,
        min: null,
        diff: null,
        clim_period: null,
        x: null,
        y: null,
        r: null,
    },
    metadata: {
        locationName: null,
        locationPosition: null,
        clim_period: null,
        gotData: false,
    }
});

const createEmptyClimatologyState = (): ClimatologyState => ({
    0: createEmptyClimatology(),
    1: createEmptyClimatology(),
});

const createEmptyTemperatureClimatology = (): TemperatureClimatology => ({
    data: {
        time: null,
        min: null,
        diff: null,
        frost: null,
        tropical: null,
        summer: null,
        clim_period: null,
        x: null,
        y: null,
        r: null,
    },
    metadata: {
        locationName: null,
        locationPosition: null,
        clim_period: null,
        gotData: false,
    }
});

const createEmptyTemperatureClimatologyState = (): TemperatureClimatologyState => ({
    0: createEmptyTemperatureClimatology(),
    1: createEmptyTemperatureClimatology(),
});

// Get initial state from URL and merge with default initial state
const urlState = getStateFromUrl();

// Define the initial state
const initialState: DataState = {
    tempAnn: createEmptyTimeSeriesState(),
    precipAnn: createEmptyTimeSeriesState(),

    tempDaily: createEmptyClimatologyState(),
    tempMonthly: createEmptyTemperatureClimatologyState(),
    precipMonthly: createEmptyClimatologyState(),
    humidityMonthly: createEmptyClimatologyState(),

    windAnn: createEmptyWindState(),

    precipHourly: createEmptyHistState(),

    // The plot to show (if we came from a URL with a plot index)
    plotToShow: urlState.data.plotToShow,

    // The first slide to get data for (i.e. the central, viewable, slide)
    prioritySlide: urlState.data.plotToShow || 0,

    activeSlide: urlState.data.plotToShow || 0,

    slidesVisible: Array(TOTAL_SLIDES).fill(true),

    fetchingData: [false, false],
    routeNumber: (urlState.data.comparisonMode ? 1 : 0),

    popupsInverted: false,

    comparisonMode: urlState.data.comparisonMode || false,
};

function xAndYAreClose(pos1: LatLng | null, pos2: LatLng) {
    if (pos1 === null) {
        console.log("xAndYAreClose FAIL", pos1, pos2);
        return false
    }

    const tol = 0.00001
    if (Math.abs(pos1.lat - pos2.lat) > tol) {
        console.log("xAndYAreClose FAIL", pos1, pos2);
        return false
    } else if (Math.abs(pos1.lng - pos2.lng) > tol) {
        console.log("xAndYAreClose FAIL", pos1, pos2);
        return false
    }

    console.log("xAndYAreClose TRUE", pos1, pos2);
    return true
}

function resetAllExtraData(state) {
    const keys: string[] = [...new Set(variableOptions.map(option => option.sliceKey))];
    keys.forEach((key) => {
        const empty = getEmptyData(key)
        state[key][1] = empty
    })
}

function getEmptyData(key: string) {
    let empty
    switch (key) {
        case 'windAnn':
            empty = createEmptyWind()
            break
        case 'precipHourly':
            empty = createEmptyHist()
            break
        case 'tempMonthly':
            empty = createEmptyTemperatureClimatology()
            break
        case 'tempDaily':
        case 'precipMonthly':
            case 'humidityMonthly':
            empty = createEmptyClimatology()
            break
        case 'tempAnn':
        case 'precipAnn':
            empty = createEmptyTimeSeries()
            break
        default:
            throw new Error(`Unknown key ${key}`)
    }

    return empty
}

export const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        updateVariable: (state, action: PayloadAction<{ sliceKey: string, result: any }>) => {
            const routeNumber = Number(action.payload.result.r)
            const key = action.payload.sliceKey

            // Make sure that the data that just arrived is for this location
            const sameLocation = xAndYAreClose(
                state[key][routeNumber].metadata.locationPosition,
                {
                    lat: action.payload.result.y ?? -999,
                    lng: action.payload.result.x ?? -999
                }
            )

            if (sameLocation) {
                state[key][routeNumber].data = { ...action.payload.result };
                state[key][routeNumber].metadata.clim_period = state[key][routeNumber].data.clim_period
                state[key][routeNumber].metadata.gotData = true;
            } else {
                console.log("DATADATA: Not same location")
                state[key][routeNumber] = getEmptyData(key)
                state[key][routeNumber].metadata.gotData = false;
            }

        },

        setVariablesLoading: (state, action: PayloadAction<{ routeNumber: number; locationPosition: LatLng }>) => {
            const routeNumber = Number(action.payload.routeNumber)

            const keys: string[] = [...new Set(variableOptions.map(option => option.sliceKey))];
            keys.forEach((key) => {
                const empty = getEmptyData(key)
                state[key][routeNumber] = empty
                state[key][routeNumber].metadata.locationPosition = action.payload.locationPosition
            })
        },

        updateActiveSlide: (state, action: PayloadAction<number>) => {
            // This is between 0 and the number of visible slides (-1)
            state.activeSlide = action.payload;
            // This is between 0 and TOTAL_SLIDES - 1
            state.prioritySlide = getXthTrue(state.slidesVisible, action.payload)
            console.log("updateActiveSlide: ", state.activeSlide, state.prioritySlide)
        },

        updateNamesInData: (state, action: PayloadAction<{ routeNumber: number, locationInfo: LocationInfo }>) => {
            const keys: string[] = [...new Set(variableOptions.map(option => option.sliceKey))];
            keys.forEach((key) => {
                // Check that this location (when the data was requested) is the same as it is now
                const positionInData = state[key][action.payload.routeNumber].metadata.locationPosition
                // console.log("DEBUG", positionInData, positionInData.lat, state[key][action.payload.routeNumber].data.y, state[key][0].data.y)
                if (xAndYAreClose(positionInData, action.payload.locationInfo.position)) {
                    state[key][action.payload.routeNumber].metadata.locationName = action.payload.locationInfo.placeName;
                }
            })
        },

        setFetchingData: (state, action: PayloadAction<{ routeNumber: number, fetchingData: boolean}>) => {
            state.fetchingData[action.payload.routeNumber] = action.payload.fetchingData;
        },

        switchCurrentAndPrevious: (state) => {
            const keys: string[] = [...new Set(variableOptions.map(option => option.sliceKey))];
            keys.forEach((key) => {
                const safe = state[key][0]
                state[key][0] = state[key][1]
                state[key][1] = safe
            })
            state.popupsInverted = !state.popupsInverted
        },

        toggleComparisonMode: (state) => {
            state.comparisonMode = !state.comparisonMode
            state.routeNumber = state.comparisonMode ? 1 : 0
            if (!state.comparisonMode) {
                resetAllExtraData(state)
            }
        },

        hideSlide: (state, action: PayloadAction<number>) => {
            // This is always relative to the maximum number of slides (8)
            if (state.slidesVisible.filter(value => value === false).length < (TOTAL_SLIDES - MIN_SLIDES_REQD)) {
                // Only if this wouldn't remove too many slides
                state.slidesVisible[action.payload] = false
            }
        },

        showSlides: (state) => {
            state.slidesVisible = Array(TOTAL_SLIDES).fill(true)
        },
    }
})

function getXthTrue(arr, x) {
    let count = 0; // To count the number of true values encountered

    for (let i = 0; i < arr.length; i++) {
        if (arr[i] === true) {
            count++; // Increment the count for each true value
            if (count === (x + 1)) {
                return i; // Return the index of the Xth true value
            }
        }
    }

    throw new Error(`${x}: ${arr}`);
}

// Action creators are generated for each case reducer function
export const {
    updateVariable, setVariablesLoading,
    updateNamesInData,
    switchCurrentAndPrevious,
    toggleComparisonMode, hideSlide,
    showSlides, updateActiveSlide,
    setFetchingData,
} = dataSlice.actions

export default dataSlice.reducer;