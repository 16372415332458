import { useCallback } from 'react';
import fetchZarrData from '../utils/fetchZarrData.ts'
import { useDispatch } from 'react-redux'
import { updateVariable } from '../reducers/dataSlice.ts'
import { variableOptions } from '../config/constants.ts';

const useFetchData = () => {
    const dispatch = useDispatch();

    const fetchData = useCallback((lat: number, lng: number, routeNumber: number, route: string, debug: string, abortController: AbortController): Promise<void> => {
        console.log(`fetchData: Called by ${debug}`, route, routeNumber);
        return new Promise<void>((resolve, reject) => {

            const query = `${route}?x=${lng}&y=${lat}&r=${routeNumber}`;

            fetchZarrData(query, abortController.signal)
                .then((result) => {
                    const matchedVariables = variableOptions
                        .filter(option => option.route === route) // Filter to get all matching options
                        .map(option => option.sliceKey);          // Extract the sliceKey from each matched option

                    matchedVariables.forEach(sliceKey => (
                        dispatch(
                            updateVariable({
                                sliceKey: sliceKey,
                                result: result
                            })
                        )
                    ))

                    resolve(); // Resolve the promise when the operation is complete
                })
                .catch((error) => {
                    reject(error); // Reject the promise if there's an error
                });
        });
    }, [dispatch]);

    return { fetchData };
};

export default useFetchData;
