import React, { useState, memo, useEffect } from 'react';
import { generatePolarData, generatePolarLayout, getStripeColor, getDataMinMax, generateWindLayout, generateWindData, formatPrecipValues, makePrecipText, makeAnnotation, deepMerge } from '../../utils/helperFunctions.ts'
import { BACKGROUND_COLOR, ERA_VERSION, frostColor, frostColor2, MONTHS_LONG, MONTHS_SHORT, summerColor, summerColor2, tropicalColor, tropicalColor2, variableOptions } from '../../config/constants.ts';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { RootState } from '../../reducers/store.ts';
import { useMantineTheme } from '@mantine/core';

import createPlotlyComponent from 'react-plotly.js/factory';
import CustomPlotly from './CustomPlotly.ts';  // Import the custom Plotly instance

const Plot = createPlotlyComponent(CustomPlotly);

interface Annotation {
    x: number;
    y: number;
    xref: string;
    yref: string;
    showarrow: boolean;
    text: string;
    font: {
        color: string;
        weight: number;
        size: number;
    };
}

function MakeGraph({ width, height, variable, colors, showLocations, showLines, landscape, plotInstanceRef, forDownload }) {
    useEffect(() => {
        console.log("Rendered MakeGraph")
    })
    const [isMounted, setIsMounted] = useState(false);

    const theme = useMantineTheme();

    useEffect(() => {
        // Set the component as mounted when the effect runs
        setIsMounted(true);

        return () => {
            // Cleanup function: Set the component as unmounted
            setIsMounted(false);
        };
    }, []);

    // To trigger a re-render when the popup ordering is switched, so the badges and plots remain in sync
    // Similarly for the comparison mode
    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
    // const popupsInverted = useTypedSelector(state => state.data.popupsInverted)
    // const comparisonMode = useTypedSelector(state => state.data.comparisonMode)  // CAN I SAFELY REMOVE THIS?

    const climatologyView = useTypedSelector(state => state.graph.climatologyView)
    const climatologyIndex = useTypedSelector(state => state.graph.climatologyIndex)
    const monthIndex = climatologyView ? climatologyIndex : null
    const aspectRatio = height / width

    const aggregation = variableOptions.find((element) => element.name === variable)?.aggregation;
    let climatologyHighlight = (((monthIndex !== null) && (aggregation === "monthly")) ? [{
        type: 'rect',
        xref: 'x',
        yref: 'paper',
        x0: monthIndex + 1.5,  // Start of the rectangle on x-axis
        x1: monthIndex + 2.5,  // End of the rectangle on x-axis
        y0: 0,  // Start of the rectangle on the y-axis (paper refers to full plot height)
        y1: 1,  // Full height of the plot area
        // fillcolor: theme.colors.c3sYellow[9] + '44',
        fillcolor: theme.colors.c3sRed[9] + '22',
        line: {
            width: 0  // No border line around the rectangle
        },
        layer: 'below'  // Ensure the rectangle is behind the bars
    }] : null)
    console.log("TESTING", variable, climatologyHighlight)

    const paper_bgcolor = BACKGROUND_COLOR

    let yvals: number[] | null = null
    let yvals2: number[] | null = null
    let yvals_b: number[] | null = null
    let yvals_c: number[] | null = null
    let yvals2_b: number[] | null = null
    let yvals2_c: number[] | null = null
    let base2: number[] | null = null

    let markerColor = colors[0]
    let markerColor2 = colors[1]
    let markerColor_b: string | null = null
    let markerColor_c: string | null = null
    let markerColor2_b: string | null = null
    let markerColor2_c: string | null = null
    // let markerColorAlt = markerColor
    // let markerColorAlt2 = markerColor2

    let plotType, xvals, plotMode, xvals2
    let xAxisRange: (number | null)[] | null = null;
    // let yAxisRange: [number, null] | null = null
    let base: number[] | null = null
    let customdata: number[] | number[][] | null = null
    let customdata2: number[] | null = null
    let dtick: number | null = null
    let bargap: number | null = null
    let barcornerradius: number = (showLocations[0] !== showLocations[1]) ? 10 : 7
    let tickmode: string = "linear"
    let yAxisVisible = true
    let tickangle = -60
    // const monthIndex = new Date().getMonth()
    let text: string[] = [""]
    let hovertemplate: string = ""
    let hovertext: string[] | null = null
    let hovertemplate2: string = ""
    let hovertext2: string[] | null = null
    let isPolar = false
    let barmode = ""
    let annotations: Annotation[] = [];
    let tickposition = aspectRatio / 15
    let xAxisTickposition = 0
    let ticklabelposition = "outside top"
    let include: number[] | number | null = null
    let minallowed: number | null = null
    let markerOutlineWidth: number = 0
    let markerOutlineWidth2: number = 0
    let lineWidth: number = Math.min(3, width * 0.008)
    let markerSize = Math.min(15, width * 0.04)
    let markerSize2 = 13
    let barWidth: number | null = null
    let barOffset1: number | null = null
    const fontSize = Math.max(height * 0.04 * 1.2, 12)
    // const tickColor = "#00000066"
    const tickColor = "#000000"
    const annotationColor = "00000099"
    const xShift = -2
    let markerSymbol = "x"
    let markerSymbol2 = "circle"
    let ticktext = MONTHS_SHORT
    let xAxisTickSize = 12
    let plotTitle = ""
    let yAxisLabel = ""
    let downloadShowLegend = true
    let downloadBottomMargin = 60

    xvals2 = null

    const sliceKey = variableOptions.find((element) => element.name === variable)!.sliceKey;
    const previousData = useTypedSelector(state => state.data[sliceKey][1].data)
    const currentData = useTypedSelector(state => state.data[sliceKey][0].data)

    const previousLocationName = useTypedSelector(state => state.data[sliceKey][1].metadata.locationName)
    const currentLocationName = useTypedSelector(state => state.data[sliceKey][0].metadata.locationName)

    let climPeriodText = " " + useTypedSelector((state) => state.data[sliceKey][0].metadata.clim_period);

    switch (variable) {
        case "Wind annual climatology":
            isPolar = true
            plotTitle = `${ERA_VERSION}: Wind rose (hourly climatology)`
            downloadBottomMargin = 25
            break
        case "Temperature daily climatology":
        case "Precip monthly climatology":
        case "Temperature monthly climatology":
        case "Humidity monthly climatology":
            plotType = "bar"
            plotMode = "markers"
            tickmode = "array"

            if (previousData['time'] !== null) {
                xvals = previousData['time'].map(x => x - xShift)
            } else if (currentData['time'] !== null) {
                xvals = currentData['time'].map(x => x - xShift)
            } else {
                throw new Error("THIS IS BAD NEWS!")
            }
            yvals = previousData['diff']
            yvals2 = currentData['diff']
            base = previousData['min']
            base2 = currentData['min']

            markerColor = Array(12).fill(markerColor)
            markerColor2 = Array(12).fill(markerColor2)
            text = MONTHS_LONG

            if (variable === "Temperature monthly climatology") {
                hovertemplate = '%{text}<br><b>Max: </b>%{y:.1f} °C<br><b>Min</b>: %{base:.1f} °C'
                yAxisLabel = "Temperature [°C]"
                plotTitle = `${ERA_VERSION}: Monthly temperature climatology`
            } else if (variable === "Humidity monthly climatology") {
                hovertemplate = '%{text}<br><b>Max: </b>%{y:.0f} %<br><b>Min</b>: %{base:.0f} %'
                yAxisLabel = "Relative humidity [%]"
                plotTitle = `${ERA_VERSION}: Monthly relative humidity climatology`
                include = 110
                minallowed = -5
            } else if (variable === "Precip monthly climatology") {
                if (yvals !== null) {
                    hovertext = yvals.map((y, i) => `${text[i]}<br><b>Total</b>: ${formatPrecipValues(y)} mm`)  // Apply custom formatting
                }
                if (yvals2 !== null) {
                    hovertext2 = yvals2.map((y, i) => `${text[i]}<br><b>Total</b>: ${formatPrecipValues(y)} mm`)  // Apply custom formatting
                }
                hovertemplate = '%{hovertext}'  // Refer to hovertext in template
                hovertemplate2 = '%{hovertext}'  // Refer to hovertext in template

                base = null
                base2 = null
                yAxisLabel = "Precipitation [mm]"
                plotTitle = `${ERA_VERSION}: Monthly precipitation climatology`
            } else if (variable === "Temperature daily climatology") {
                isPolar = true
                plotTitle = `${ERA_VERSION}: Daily temperature climatology`
                downloadShowLegend = false
                downloadBottomMargin = 25
            }
            break
        case "Precip hourly hist":
            plotType = "bar"
            plotMode = "markers"
            tickmode = "array"

            if (previousData['histogram'] !== null) {
                xvals = Array.from({ length: previousData['histogram'].length }, (_, index) => index - xShift);
                text = makePrecipText(previousData['bin_edges'], false)
                ticktext = makePrecipText(previousData['bin_edges'], true)
            } else if (currentData['histogram'] !== null) {
                xvals = Array.from({ length: currentData['histogram'].length }, (_, index) => index - xShift);
                text = makePrecipText(currentData['bin_edges'], false)
                ticktext = makePrecipText(currentData['bin_edges'], true)
            } else {
                throw new Error("THIS IS BAD NEWS!")
            }
            yvals = previousData['histogram']
            yvals2 = currentData['histogram']

            markerColor = Array(12).fill(markerColor)
            markerColor2 = Array(12).fill(markerColor2)

            // hovertemplate = '%{text}<br><b>Total</b>: %{y:.1f} %'
            if (yvals !== null) {
                hovertext = yvals.map((y, i) => `${text[i]}<br><b>Total</b>: ${formatPrecipValues(y)} %`)  // Apply custom formatting
            }
            if (yvals2 !== null) {
                hovertext2 = yvals2.map((y, i) => `${text[i]}<br><b>Total</b>: ${formatPrecipValues(y)} %`)  // Apply custom formatting
            }
            hovertemplate = '%{hovertext}'  // Refer to hovertext in template
            hovertemplate2 = '%{hovertext}'  // Refer to hovertext in template

            base = null
            base2 = null
            tickangle = 0
            xAxisTickSize = Math.min(width * 0.035, xAxisTickSize)
            tickposition *= 1.2
            console.log("xAxisTickSize", xAxisTickSize)

            yAxisLabel = "Percentage of hours"
            plotTitle = `${ERA_VERSION}: Precipitation intensity distribution`

            break
        case "Frost days etc":
            // barmode = 'stack'
            plotType = "scatter"
            plotMode = "lines+markers"
            tickmode = "array"

            markerColor = frostColor
            markerColor_b = summerColor
            markerColor_c = tropicalColor
            // markerColor = theme.colors.c3sBlue[9]
            // markerColor_b = theme.colors.c3sYellow[9]
            // markerColor_c = theme.colors.c3sRed[9]

            xAxisRange = [1.5, 1.5 + 12]

            if (previousData['time'] !== null) {
                xvals = previousData['time'].map(x => x - xShift)
            } else if (currentData['time'] !== null) {
                xvals = currentData['time'].map(x => x - xShift)
            } else {
                throw new Error("THIS IS BAD NEWS!")
            }

            if (previousData['time'] !== null) {
                // xvals = previousData['time'].map(x => x - xShift)
                yvals = previousData['frost']
                yvals_b = previousData['summer']
                yvals_c = previousData['tropical']
            }

            if (currentData['time'] !== null) {
                // xvals2 = currentData['time'].map(x => x - xShift)
                yvals2 = currentData['frost']
                yvals2_b = currentData['summer']
                yvals2_c = currentData['tropical']

                markerColor2 = frostColor2
                markerColor2_b = summerColor2
                markerColor2_c = tropicalColor2
                // markerColor2 = theme.colors.c3sBlue[5]
                // markerColor2_b = theme.colors.c3sYellow[6]
                // markerColor2_c = theme.colors.c3sRed[5]
            }
            text = MONTHS_SHORT
            barcornerradius = 5
            bargap = 0
            include = 110
            minallowed = -5
            markerOutlineWidth = Math.min(2, width * 0.03)
            markerOutlineWidth2 = Math.min(2, width * 0.03)

            hovertemplate = '%{text}<br><b>Frost days: </b>%{y:.0f} %'

            yAxisLabel = "Percentage of days"
            plotTitle = `${ERA_VERSION}: Monthly climatology of temperature threshold exceedances`
            downloadBottomMargin = 120
            break
        case "Precip annual timeseries":
        case "Temperature annual timeseries":
            if (variable === "Temperature annual timeseries") {
                plotType = "scatter"
                plotMode = "lines+markers"
                hovertemplate = '<b>Year:</b> %{text}<br><b>Average</b>: %{y:.1f} °C'
            } else if (variable === "Precip annual timeseries") {
                plotType = "bar"
                plotMode = "markers"
                barmode = "overlay"
                // hovertemplate = '<b>Year:</b> %{text}<br><b>Total</b>: %{y:.2s} mm'
            } else {
                throw new Error("THIS IS BAD NEWS!")
            }
            xAxisRange = [1939, null]
            dtick = 10
            tickangle = -90
            xAxisTickposition = 0

            if (previousData['time'] !== null) {
                xvals = previousData['time']
            } else if (currentData['time'] !== null) {
                xvals = currentData['time']
            } else {
                throw new Error("THIS IS BAD NEWS!")
            }
            yvals = previousData['values']
            yvals2 = currentData['values']

            text = xvals
            climPeriodText = ""
            // bargap = 0.5

            if (variable === "Precip annual timeseries") {
                if (yvals !== null) {
                    hovertext = yvals.map((y, i) => `<b>Year:</b> ${xvals[i]}<br><b>Total</b>: ${formatPrecipValues(y)} mm`)  // Apply custom formatting
                }
                if (yvals2 !== null) {
                    hovertext2 = yvals2.map((y, i) => `<b>Year:</b> ${xvals[i]}<br><b>Total</b>: ${formatPrecipValues(y)} mm`)  // Apply custom formatting
                }
                hovertemplate = '%{hovertext}'  // Refer to hovertext in template
                hovertemplate2 = '%{hovertext}'  // Refer to hovertext in template
                barWidth = 0.5
                barOffset1 = -0.7

                tickposition *= 1.5
                // if ((yvals !== null) && (yvals2 !== null) && (showLocations[0]) && (showLocations[1])) {
                //     bargap = 0
                // } else {
                //     bargap = null
                // }
                // bargap = 0.3
                yAxisLabel = "Precipitation [mm]"
                plotTitle = `${ERA_VERSION}: Annual total precipitation`
            } else if (variable === "Temperature annual timeseries") {
                tickposition *= 1.6
                lineWidth = 2
                markerSize = 7
                markerSize2 = markerSize
                markerSymbol = "circle"
                markerSymbol2 = markerSymbol
                yAxisLabel = "Temperature [°C]"
                plotTitle = `${ERA_VERSION}: Annual average temperature`
            }
            break
        case "Temperature warming stripes":
            barmode = 'stack'
            plotType = "bar"
            dtick = 10

            yvals = previousData['anomalies']
            if (yvals !== null) {
                markerColor = (yvals as number[]).map(
                    function (value) { return getStripeColor(value) }
                )
            }

            yvals2 = currentData['anomalies']
            if (yvals2 !== null) {
                markerColor2 = (yvals2 as number[]).map(
                    function (value) { return getStripeColor(value) }
                )
            }
            bargap = 0
            barcornerradius = 0
            yAxisVisible = false
            tickangle = -90
            xAxisTickposition = 0

            hovertemplate = '<b>Year:</b> %{text}<br><b>Anomaly</b>: %{customdata:.1f} °C'
            if ((previousData['anomalies'] !== null) && (previousData['anomalyScaling'] !== null)) {
                customdata = previousData['anomalies'].map((anomaly: number) => anomaly * previousData['anomalyScaling'])
            }
            if ((currentData['anomalies'] !== null) && (currentData['anomalyScaling'] !== null)) {
                customdata2 = currentData['anomalies'].map((anomaly: number) => anomaly * currentData['anomalyScaling'])
            }

            if (previousData['time'] !== null) {
                xvals = previousData['time']
            } else if (currentData['time'] !== null) {
                xvals = currentData['time']
            } else {
                throw new Error("THIS IS BAD NEWS!")
            }
            text = xvals
            if (yvals !== null) {
                yvals = Array(xvals.length).fill(1)
            }
            if (yvals2 !== null) {
                yvals2 = Array(xvals.length).fill(1)
            }
            const strangeScaling = 0.96  // Seems necessary as the top of the plot isn't centred on 1 for some reason
            if ((showLocations[0]) && (showLocations[1]) && (customdata !== null) && (customdata2 !== null)) {
                // const currentLocationName = store.getState().data.tempAnn[0].metadata.locationName
                // const previousLocationName = store.getState().data.tempAnn[1].metadata.locationName
                annotations.push(makeAnnotation(currentLocationName ?? "Loading...", 0.75 * strangeScaling, fontSize, forDownload))
                annotations.push(makeAnnotation(previousLocationName ?? "Loading...", 0.25 * strangeScaling, fontSize, forDownload))
            } else if (forDownload) {
                if ((showLocations[0]) && (customdata !== null)) {
                    annotations.push(makeAnnotation(previousLocationName ?? "Loading...", 0.5 * strangeScaling, fontSize, forDownload))
                }
                if ((showLocations[1]) && (customdata2 !== null)) {
                    annotations.push(makeAnnotation(currentLocationName ?? "Loading...", 0.5 * strangeScaling, fontSize, forDownload))
                }
            } else {
                include = [-0.5, 1.5]
            }

            plotTitle = `${ERA_VERSION}: Climate warming stripes #ShowYourStripes`
            downloadShowLegend = false
            climPeriodText = ""
            break
        default:
            throw new Error()
    }

    if (hovertemplate2 === "") {
        hovertemplate2 = hovertemplate
    }

    let dataToPlot: Record<string, any>[] = [];

    if (showLocations[0] && (yvals !== null)) {
        if (showLines[0]) {
            dataToPlot.push({
                x: xvals,
                y: yvals,
                width: barWidth,
                offset: barOffset1,
                base: base,
                mode: plotMode,
                type: plotType,
                name: (forDownload ? (variable === "Frost days etc" ? "Frost days: " : "") + previousLocationName : ""),
                marker: {
                    color: markerColor,
                    size: markerSize,
                    symbol: markerSymbol,
                    line: {
                        color: paper_bgcolor,
                        width: markerOutlineWidth
                    }
                },
                line: {
                    color: markerColor[0],
                    width: lineWidth,
                    shape: "spline"
                },
                text: text,
                hovertemplate: hovertemplate,
                hovertext: hovertext,
                textposition: "none",
                customdata: customdata,
            })
        }

        if ((yvals_b !== null) && (showLines[1])) {
            const hovertemplate2 = '%{text}<br><b>Summer days: </b>%{y:.0f} %'

            dataToPlot.push({
                x: xvals,
                y: yvals_b,
                mode: plotMode,
                type: plotType,
                name: (forDownload ? `Summer days: ${previousLocationName}` : ""),
                marker: {
                    color: markerColor_b,
                    size: markerSize,
                    symbol: markerSymbol,
                    line: {
                        color: paper_bgcolor,
                        width: markerOutlineWidth
                    }
                },
                line: {
                    color: markerColor_b,
                    width: lineWidth,
                    shape: "spline",
                },
                text: text,
                hovertemplate: hovertemplate2,
                textposition: "none",
            })
        }

        if ((yvals_c !== null) && (showLines[2])) {
            const hovertemplate2 = '%{text}<br><b>Tropical nights: </b>%{y:.0f} %'

            dataToPlot.push({
                x: xvals,
                y: yvals_c,
                mode: plotMode,
                type: plotType,
                name: (forDownload ? `Tropical nights: ${previousLocationName}` : ""),
                marker: {
                    color: markerColor_c,
                    size: markerSize,
                    symbol: markerSymbol,
                    line: {
                        color: paper_bgcolor,
                        width: markerOutlineWidth
                    }
                },
                line: {
                    color: markerColor_c,
                    width: lineWidth,
                    shape: "spline"
                },
                text: text,
                hovertemplate: hovertemplate2,
                textposition: "none",
            })
        }

    }

    if ((colors[0] !== colors[1]) && (showLocations[1]) && (yvals2 !== null)) {

        if (showLines[0]) {
            dataToPlot.push({
                x: (xvals2 !== null) ? xvals2 : xvals,
                y: yvals2,
                width: barWidth,
                base: base2,
                mode: plotMode,
                type: plotType,
                name: (forDownload ? (variable === "Frost days etc" ? "Frost days: " : "") + currentLocationName : ""),
                marker: {
                    color: markerColor2,
                    size: markerSize2,
                    symbol: markerSymbol2,
                    line: {
                        color: paper_bgcolor,
                        width: markerOutlineWidth2
                    }
                },
                line: {
                    color: markerColor2,
                    width: lineWidth,
                    shape: "spline",
                },
                text: text,
                hovertemplate: hovertemplate2,
                hovertext: hovertext2,
                textposition: "none",
                customdata: customdata2,
            })
        }

        if ((yvals2_b !== null) && (showLines[1])) {
            const hovertemplate2 = '%{text}<br><b>Summer days: </b>%{y:.0f} %'

            dataToPlot.push({
                x: (xvals2 !== null) ? xvals2 : xvals,
                y: yvals2_b,
                mode: plotMode,
                type: plotType,
                name: (forDownload ? `Summer days: ${currentLocationName}` : ""),
                marker: {
                    color: markerColor2_b,
                    size: markerSize2,
                    symbol: markerSymbol2,
                    line: {
                        color: paper_bgcolor,
                        width: markerOutlineWidth2
                    }
                },
                line: {
                    color: markerColor2_b,
                    width: lineWidth,
                    shape: "spline",
                },
                text: text,
                hovertemplate: hovertemplate2,
                textposition: "none",
            })
        }

        if ((yvals2_c !== null) && (showLines[2])) {
            const hovertemplate2 = '%{text}<br><b>Tropical nights: </b>%{y:.0f} %'

            dataToPlot.push({
                x: (xvals2 !== null) ? xvals2 : xvals,
                y: yvals2_c,
                mode: plotMode,
                type: plotType,
                name: (forDownload ? `Tropical nights: ${currentLocationName}` : ""),
                marker: {
                    color: markerColor2_c,
                    size: markerSize2,
                    symbol: markerSymbol2,
                    line: {
                        color: paper_bgcolor,
                        width: markerOutlineWidth2
                    }
                },
                line: {
                    color: markerColor2_c,
                    width: lineWidth,
                    shape: "spline",
                },
                text: text,
                hovertemplate: hovertemplate2,
                textposition: "none",
            })
        }
    }

    const cartesianLayout = {
        xaxis: {
            showgrid: false,
            fixedrange: true,
            range: xAxisRange,
            tickangle: tickangle,
            tickmode: tickmode, //  If "linear", the placement of the ticks is determined by a starting position `tick0` and a tick step `dtick`
            dtick: dtick,
            zeroline: true,
            tickvals: xvals,
            ticktext: ticktext,
            position: xAxisTickposition,
            tickfont: {
                size: xAxisTickSize,
                weight: 600,
                color: tickColor,
            },
        },
        yaxis: {
            showgrid: true,
            fixedrange: true,
            anchor: 'free',
            position: tickposition,
            visible: yAxisVisible,
            ticklabelposition: ticklabelposition,
            tickfont: {
                weight: 600,
                size: fontSize,
                color: tickColor,
            },
            autorangeoptions: {
                include: include,
                minallowed: minallowed,
            }
        },
        font: {
            size: 12,
            family: 'Lato, sans-serif',
        },
        width: `${width}`,
        height: `${height}`,
        autosize: false,
        showlegend: false,
        margin: {
            b: 35,
            l: 10,
            r: 5,
            t: 0,
        },
        paper_bgcolor: paper_bgcolor,
        plot_bgcolor: '#00000000',
        barcornerradius: barcornerradius,
        bargap: bargap,
        barmode: barmode,
        annotations: annotations,

        shapes: climatologyHighlight,

        hoverlabel: {
            font: {
                family: 'Lato, sans-serif',
            }
        },
    }

    let polarLayout
    if (variable === "Temperature daily climatology") {
        let dataRange = getDataMinMax(yvals, base, yvals2, base2)
        if (forDownload) {
            if (showLocations[0]) {
                dataRange = getDataMinMax(yvals, base, yvals, base)
            } else if (showLocations[1]) {
                dataRange = getDataMinMax(yvals2, base2, yvals2, base2)
            }
        }
        dataToPlot = generatePolarData(yvals, base, yvals2, base2, xvals, dataRange, showLocations, monthIndex, forDownload)
        polarLayout = generatePolarLayout(width, height, dataRange, tickColor, annotationColor, monthIndex, fontSize)
    } else if (variable === "Wind annual climatology") {
        dataToPlot = generateWindData(previousData, currentData, showLocations, forDownload)
        polarLayout = generateWindLayout(width, height, tickColor, annotationColor, fontSize)
    }

    let layout = isPolar ? polarLayout : cartesianLayout

    let creditX = 0.98
    switch (variable) {
        case "Temperature daily climatology":
        case "Wind annual climatology":
            creditX = 1.06
            break
        default:
            break
    }

    let creditY = -0.15
    switch (variable) {
        case "Frost days etc":
            creditY = 1.05
            break
        case "Temperature warming stripes":
            creditY = 0.0
            break
        case "Temperature daily climatology":
        case "Wind annual climatology":
            creditY = -0.05
            break
        default:
            break
    }
    // const creditY = (variable === "Frost days etc" ? 1.05 : 0.02)

    let downloadRightMargin = 0
    switch (variable) {
        case "Temperature daily climatology":
        case "Wind annual climatology":
            downloadRightMargin = 40
            break
        default:
            break
    }

    let downloadLeftMargin = 60
    switch (variable) {
        case "Wind annual climatology":
            // downloadLeftMargin = 100
            break
        case "Temperature warming stripes":
            downloadLeftMargin = 10
            break
        default:
            break
    }

    const creditText = `Data: ${ERA_VERSION}${climPeriodText} (nearest grid cell)     Credit: C3S/ECMWF`

    const offsets = [
        { x: 0, y: 0.002 },
        { x: 0, y: -0.002 },
        { x: 0.002, y: 0 },
        { x: -0.002, y: 0 },
    ];

    const halo = offsets.map(offset => ({
        text: creditText,
        xref: 'paper',
        yref: 'paper',
        x: creditX + offset.x,
        y: creditY + offset.y,
        showarrow: false,
        font: {
            size: 16,
            color: 'white',
        },
        align: 'right',
    }));

    const locationNameAnnotation = [
        {
            text: (showLocations[0] ? previousLocationName : currentLocationName),
            xref: 'paper', // Use 'paper' to position relative to the plotting area
            yref: 'paper',
            x: 0,
            y: 1.08,
            // y: -0.15,
            showarrow: false, // Do not show an arrow
            font: {
                size: 16, // Font size for the annotation
                color: 'black', // Font color for the annotation
            },
            align: 'left', // Align the text to the right
        },
    ]

    const axisAnnotation = [
        {
            x: variable === "Wind annual climatology" ? 0.63 : 0.64,               // Position relative to plot width
            y: 0.78,               // Position above the plot
            text: variable === "Wind annual climatology" ? "Percentage of time" : "Temperature [°C]",  // Text to display
            showarrow: false,     // No arrow
            xref: "paper",        // Reference x to paper, so it doesn't move with data
            yref: "paper",        // Reference y to paper, for a fixed position
            font: {
                size: fontSize,
                color: tickColor,
            },
            textangle: -37,
        },
    ]

    const downloadLayout = {
        margin: {
            t: (variable === "Frost days etc" ? 50 : 80),
            b: downloadBottomMargin,
            l: downloadLeftMargin,
            r: downloadRightMargin,
        },
        title: {
            text: plotTitle, // Optional: Add a title for the plot
            font: {
                size: (variable === "Frost days etc" ? fontSize * 1.1 : fontSize * 1.5),
                weight: 600,
                color: tickColor,
            },
        },
        yaxis: {
            position: 0,
            title: {
                text: yAxisLabel, // Set the y-axis label
                font: {
                    size: fontSize * 1.3,
                    weight: 600,
                    color: tickColor,
                },
                standoff: 50,
            }
        },
        showlegend: downloadShowLegend,
        legend: {
            x: (variable === "Wind annual climatology" ? -0.1 : 0.1),
            y: (variable === "Frost days etc" ? -0.1 : (variable === "Wind annual climatology" ? 1 : 1.1)),
            bgcolor: 'transparent', // Background color for the legend
            borderwidth: 0, // Border width for the legend
            yanchor: 'top',
        },
        annotations: [
            ...halo,
            {
                text: creditText,
                xref: 'paper', // Use 'paper' to position relative to the plotting area
                yref: 'paper',
                x: creditX, // Position at the right
                y: creditY,
                // y: -0.15,
                showarrow: false, // Do not show an arrow
                font: {
                    size: 16, // Font size for the annotation
                    color: 'black', // Font color for the annotation
                },
                align: 'right', // Align the text to the right
            },
            ...((variable === "Wind annual climatology" || variable === "Temperature daily climatology") ? locationNameAnnotation : []), // Conditionally append place name
            ...((variable === "Wind annual climatology" || variable === "Temperature daily climatology") ? axisAnnotation : []),
        ],
    };

    if (forDownload) {
        layout = deepMerge(layout, downloadLayout);
    }

    const handleInitialized = (_, plotInstance) => {
        console.log('Plot instance initialized:', plotInstance); // Log to confirm initialization
        plotInstanceRef.current = plotInstance;
    };

    return (
        <>
            <Plot
                data={isMounted && dataToPlot && layout ? dataToPlot : []}
                layout={isMounted && dataToPlot && layout ? layout : {
                    width: `${width}`,
                    height: `${height}`,
                    xaxis: {
                        showgrid: false,
                        fixedrange: true,
                    },
                    yaxis: {
                        showgrid: false,
                        fixedrange: true,
                    },
                }}
                config={{
                    displayModeBar: false,
                }}
                onInitialized={handleInitialized}
                style={{ visibility: isMounted && dataToPlot && layout ? 'visible' : 'hidden' }}
            />
        </>
    )
}

export default memo(MakeGraph)