import React, { useEffect, useState } from 'react';

import './styles/App.css';
import Inputs from './components/inputs/Inputs.tsx';
import Viewer from './components/map/Viewer.tsx';
import ArrivedFromURL from './components/nonUI/ArrivedFromURL.tsx';
import CarouselContainer from './components/carousel/CarouselContainer.tsx';
import { MantineProvider } from '@mantine/core';
import { TypographyStylesProvider } from '@mantine/core';
import Header from './components/map/Header.tsx';
import { Notifications } from '@mantine/notifications';
import { SequenceAbortControllerProvider } from './components/nonUI/SequenceAbortControllerProvider.tsx';
import { NavigationProgress } from '@mantine/nprogress';

import '@mantine/core/styles.css';
import '@mantine/core/styles/global.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/nprogress/styles.css';

import store from './reducers/store.ts'
import { Provider } from 'react-redux'

// import FPS from './components/FPS.tsx'
import ColorBars from './components/map/ColorBars.tsx';

import { theme } from './theme.tsx';
import TrackProgress from './components/nonUI/TrackProgress.tsx';
import HandleChromeIOSRotation from './components/nonUI/HandleChromeIOSRotation.tsx';

function App() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (process.env.NODE_ENV !== 'development') {
    console.log = () => { };
    // console.warn = () => { };
    // console.error = () => { };
  }

  return (
    <>
      <SequenceAbortControllerProvider>
        <Provider store={store}>

          <ArrivedFromURL />

          <TrackProgress />

          <HandleChromeIOSRotation />

          <div className="App" key={5}>
            <MantineProvider theme={theme}>
              <NavigationProgress size={4} />

              <Notifications limit={1} position='top-center' />

              <TypographyStylesProvider key={windowSize.width / windowSize.height}>

                <Viewer windowSize={windowSize} />

                {/* <FPS /> */}

                <Header />

                <Inputs />

                <ColorBars />

                <CarouselContainer windowSize={windowSize} key={windowSize.width / windowSize.height} />

              </TypographyStylesProvider>
            </MantineProvider>
          </div>
        </Provider>
      </SequenceAbortControllerProvider>
    </>
  );
}

export default App;
