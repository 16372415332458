import React, { useEffect, useRef, useState, memo } from 'react';
import { Combobox, Loader, TextInput, useCombobox, ActionIcon, Tooltip } from '@mantine/core';
import { useSelector, useDispatch, TypedUseSelectorHook } from 'react-redux'
import useUpdatePositionAndData from '../../hooks/useUpdatePositionAndData.ts';
import { fetchPlaceList } from '../../utils/osmApi.ts'
import { RootState } from '../../reducers/store.ts';
import { closeGraphs, openGraphs } from '../../reducers/graphSlice.ts';
import { IconLocation, IconLocationFilled, IconMapPinFilled, IconX } from '@tabler/icons-react';
import { theme } from '../../theme.tsx';
import { setHaveGeolocated, triggerPanToCurrentLocation } from '../../reducers/locationSlice.ts'
import fetchGeolocation from '../../utils/fetchGeolocation.ts'
import { BOX_SHADOW } from '../../config/constants.ts';

function LocationSearchBar({ barWidth, buttonWidth }) {
  const [controller, setController] = useState<AbortController | null>(null);  // AbortController stored in state

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption()
      // returnFocus()
    },
    onDropdownOpen() {
      console.log('onDropdownOpen')
      dispatch(closeGraphs())
    },
  });

  const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
  const boxLocationName = useTypedSelector(state => state.location.boxLocationName)
  const locationPosition = useTypedSelector(state => state.location.locationPosition)
  const locationPosition2 = useTypedSelector(state => state.location.locationPosition2)
  const routeNumber = useTypedSelector(state => state.data.routeNumber)
  const geolocationToggle = useTypedSelector(state => state.location.geolocationToggle)
  const haveGeolocated = useTypedSelector(state => state.location.haveGeolocated)
  const comparisonMode = useTypedSelector(state => state.data.comparisonMode)

  const comparisonModeAndSecondLocation = comparisonMode && locationPosition.lat &&
    locationPosition.lng && locationPosition2.lat && locationPosition2.lng

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Array<{ string: number[] }> | null>(null);

  const [value, setValue] = useState<string>(boxLocationName);

  const [empty, setEmpty] = useState(false);
  const abortController = useRef<AbortController>();
  const debounceTimeout = useRef<NodeJS.Timeout | undefined>(undefined);

  const [geolocating, setGeolocating] = useState(false);
  const [geolocationEnabled, setGelocationEnabled] = useState(true);

  // We need to useEffect to set the value if we want it set here, otherwise the value is actually only set ON THE FIRST RENDER!
  useEffect(() => {
    setValue(boxLocationName)
    setData(null);
  }, [boxLocationName])

  const dispatch = useDispatch()

  const { updatePositionAndData } = useUpdatePositionAndData();

  const fetchOptions = (query: string) => {
    // Abort any previous request
    abortController.current?.abort();

    // Clear any previous timer
    clearTimeout(debounceTimeout.current);

    // Set up a new abort controller
    abortController.current = new AbortController();
    setLoading(true);

    // Set a new debounce timer (0.3s delay)
    debounceTimeout.current = setTimeout(() => {
      fetchPlaceList(query, abortController.current!.signal)
        .then((result) => {
          setData(result);
          setLoading(false);
          setEmpty(result.length === 0);
          abortController.current = undefined;
        })
        .catch(() => {
          setLoading(false);
        });
    }, 300);
  };

  const options = (data || [[]]).map((item, index) => (
    <Combobox.Option value={item[0]} key={index}>
      {item[0]}
    </Combobox.Option>
  ));

  const findNumbersArray = (searchString: string): number[] | null => {
    let newPosition: number[] | null = null

    if (data) {
      data.forEach((item, index) => {
        if (newPosition !== null) return;
        if (item[0] === searchString) {
          newPosition = item[1]
          return newPosition // This return only returns from the forEach loop and not the wider {} block
        }
      });
    };

    console.log('IS THIS POSSIBLE? findNumbersArray failed:', searchString, data);
    return newPosition;
  };

  const emptyBoxText = loading ? "Searching..." : "No results found."
  const borderColor = '#ffffff'
  const backgroundColor = '#f9f9f9'
  const searchBarHeight = buttonWidth

  function handlePanTo(locationNumber: number) {
    dispatch(triggerPanToCurrentLocation(locationNumber))
  }

  const leftSection = (
    <Tooltip label={"Center on location"}>
      <ActionIcon
        onMouseDown={(event) => event.preventDefault()}
        onClick={() => handlePanTo(0)}
      >
        <IconMapPinFilled color={theme.colors.c3sRed[9]} />
      </ActionIcon>
    </Tooltip>
  )

  const leftSectionComparisonMode = (
    <div style={{
      width: '60px',
      height: '28px',
      position: "absolute",
      left: 3,
      top: '13.5%',
      flexWrap: "nowrap",
      gap: "4px",
      display: "flex",
    }}>
      <Tooltip label={"Center on location"}>
        <ActionIcon
          onMouseDown={(event) => event.preventDefault()}
          onClick={() => handlePanTo(1)}
        >
          <IconMapPinFilled color={theme.colors.c3sRed[9]} />
        </ActionIcon>
      </Tooltip>

      <Tooltip label={"Center on location"}>
        <ActionIcon
          onMouseDown={(event) => event.preventDefault()}
          onClick={() => handlePanTo(0)}
        >
          <IconMapPinFilled color={theme.colors.c3sBlue[9]} />
        </ActionIcon>
      </Tooltip>
    </div>
  )

  const handleGeolocate = () => {
    // Abort any previous geolocation if still active
    if (controller) {
      controller.abort();
    }

    const newController = new AbortController();
    setController(newController);  // Store the new controller in state
    setGeolocating(true);
    const { signal } = newController;

    const getLocation = async () => {
      try {
        const result = await fetchGeolocation(signal);
        if (result) {
          console.log("GEO: Geolocation was successful.");
          controller?.abort()
          setGelocationEnabled(true);
          dispatch(setHaveGeolocated(true))
          updatePositionAndData(result.latitude, result.longitude, routeNumber, null, 'geolocation');
        } else {
          console.log("GEO: Geolocation was not successful.");
          setGelocationEnabled(false);
        }
        setGeolocating(false);
      } catch (error) {
        console.error("GEO: Error fetching geolocation:", error);
        setGelocationEnabled(false);
        setGeolocating(false);
      }
    };

    getLocation();
  };

  // Abort geolocation if `state.test` changes
  useEffect(() => {
    if (controller) {
      controller.abort();  // Abort any ongoing geolocation
      console.log("GEO: Aborted due to Redux state change");
    }
    // Cleanup: when component unmounts or state changes, make sure to abort
    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, [geolocationToggle]);  // Watch for changes to state

  let tooltipLabel = ""
  let tooltipDisabled = false

  if (geolocating) {
    tooltipLabel = "Geolocating..."
    tooltipDisabled = true
  } else {
    tooltipDisabled = false
    if (geolocationEnabled) {
      if (haveGeolocated) {
        tooltipLabel = "Geolocated"
      } else {
        tooltipLabel = "Find my location"
      }
    } else {
      tooltipLabel = "Geolocation disabled"
    }
  }

  const rightSection = (
    <div style={{
      width: '60px',
      height: '28px',
      position: "absolute",
      right: 3,
      top: '13.5%',
      flexWrap: "nowrap",
      gap: "4px",
      display: "flex",
    }}>
      <Tooltip label={tooltipLabel} disabled={tooltipDisabled}>
        <ActionIcon
          onClick={handleGeolocate}
        >
          {geolocating ?
            <Loader size={18} color={'c3sBlue'} />
            :
            (geolocationEnabled ?
              (haveGeolocated ?
                <IconLocationFilled style={{ width: '60%', height: '60%' }} stroke={1.5} color={theme.colors.c3sBlue[9]} />
                :
                <IconLocation style={{ width: '60%', height: '60%' }} stroke={4} color={theme.colors.c3sBlue[9]} />
              )
              :
              <IconLocation style={{ width: '60%', height: '60%' }} stroke={4} color={theme.colors.c3sGrey[3]} />
            )
          }
        </ActionIcon>
      </Tooltip>

      <Tooltip label={"Clear selection"} disabled={loading}>
        <ActionIcon
          onMouseDown={(event) => event.preventDefault()}
          onClick={() => setValue('')}
          aria-label="Clear value"
        >
          {loading ?
            <Loader size={18} />
            :
            <IconX style={{ width: '70%', height: '70%' }} stroke={1.5} />
          }
        </ActionIcon>
      </Tooltip>
    </div>
  )


  return (
    <Combobox
      offset={3}
      onOptionSubmit={(optionValue) => {
        setValue(optionValue);
        combobox.closeDropdown();

        // Dismiss the keyboard
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }

        const newPosition = findNumbersArray(optionValue);
        console.log('Option submitted:', optionValue, value, newPosition, data);
        if (newPosition !== null) {
          const newPositionStruct = { lat: newPosition[0][1], lng: newPosition[0][0] }
          dispatch(openGraphs())
          updatePositionAndData(newPositionStruct.lat, newPositionStruct.lng, routeNumber, optionValue, 'dropdown')
        }
      }}
      withinPortal={false}
      store={combobox}
      size='md'
      width={barWidth}
      styles={{
        options: {
          pointerEvents: 'auto',
        },
        dropdown: {
          borderRadius: '10px',
          borderColor: `${borderColor}`,
          borderWidth: "2px",
          backgroundColor: `${backgroundColor}`
        }
      }}
    >
      <Combobox.Target>
        <TextInput
          w={barWidth}
          // placeholder="🔍 Search location"
          placeholder="Search location"
          value={value}
          onChange={(event) => {
            setValue(event.currentTarget.value);
            fetchOptions(event.currentTarget.value);
            combobox.resetSelectedOption();
            combobox.openDropdown();
            console.log("opening dropdown")
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => {
            combobox.openDropdown();
            if (data === null) {
              fetchOptions(value);
            }
          }}
          onBlur={() => {
            combobox.closeDropdown()
            console.log("closing dropdown on BLUR")
          }
          }
          leftSection={comparisonModeAndSecondLocation ? leftSectionComparisonMode : leftSection}
          leftSectionWidth={comparisonModeAndSecondLocation ? buttonWidth * 1.65 : undefined}
          rightSection={rightSection}
          rightSectionWidth={buttonWidth * 1.67}
          styles={{
            root: {
              padding: '0',
              pointerEvents: 'auto',
            },
            input: {
              borderRadius: '10px',
              pointerEvents: 'auto',
              color: 'black',
              borderColor: `${borderColor}`,
              borderWidth: "2px",
              backgroundColor: `${backgroundColor}`,
              boxShadow: BOX_SHADOW,
              height: `${searchBarHeight}px`,
            },
          }}
        />
      </Combobox.Target>

      <Combobox.Dropdown hidden={data === null}>
        <Combobox.Options>
          {options}
          {empty && <Combobox.Empty>{emptyBoxText}</Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}

export default memo(LocationSearchBar);