import { theme } from '../theme.tsx';

// ==============================
// Setting up colours that aren't in the theme directly
// ==============================
export const stripeColorsGrey = [
    '#08306b', '#08519c', '#2171b5', '#4292c6',
    '#6baed6', '#9ecae1', '#c6dbef', '#deebf7', '#dddddd',
    '#fee0d2', '#fcbba1', '#fc9272', '#fb6a4a',
    '#ef3b2c', '#cb181d', '#a50f15', '#67000d',
];

export const stripeColorsWhite = [
    '#08306b', '#08519c', '#2171b5', '#4292c6',
    '#6baed6', '#9ecae1', '#c6dbef', '#deebf7', '#ffffff',
    '#fee0d2', '#fcbba1', '#fc9272', '#fb6a4a',
    '#ef3b2c', '#cb181d', '#a50f15', '#67000d',
];

export const dailyPolarColors = [
    "#0055ff", "#4376ce", "#859bb4", "#b1bab8", "#d3d7b2", "#f2eaa3", "#fff29e", "#fee996", "#fed383", "#ffb369", "#ff8949", "#ff4e21", "#ff0000"
]

export const c3sred = theme.colors.c3sRed[9]
export const c3syellow = theme.colors.c3sYellow[9]
export const c3sblue = theme.colors.c3sBlue[9]
export const c3sgrey = theme.colors.c3sGrey[9]

export const c3sredAlt = theme.colors.c3sRed[4]
export const c3syellowAlt = theme.colors.c3sYellow[7]
export const c3syellowAlt2 = theme.colors.c3sYellow[4]
export const c3sblueAlt = theme.colors.c3sBlue[4]
export const c3sgreyAlt = '#444'


export const frostColor = c3sblueAlt
export const summerColor = c3syellowAlt
export const tropicalColor = c3sredAlt
export const frostColor2 = c3sblue
export const summerColor2 = c3syellow
export const tropicalColor2 = c3sred
export const temperatureColor = c3sredAlt
export const precipColor = c3sblueAlt
export const temperatureColor2 = c3sred
export const precipColor2 = c3sblue
export const humidityColor = c3sgreyAlt
export const humidityColor2 = c3sgrey

export const windColors = [c3syellowAlt2, c3syellow, c3syellowAlt2, c3syellow]

export const variableOptions = [
    {
        name: "Precip monthly climatology",
        title: "Precipitation",
        units: "mm/month",
        route: "precip-monthly-clim",
        mutuallyExclusive: false,
        aggregation: "monthly",
        statistic: "climatology",
        sliceKey: "precipMonthly",
        color1: precipColor,
        color2: precipColor2,
    },
    {
        name: "Temperature monthly climatology",
        title: "Temperature",
        units: "°C",
        route: "temperature-monthly-clim",
        mutuallyExclusive: false,
        aggregation: "monthly",
        statistic: "climatology",
        sliceKey: "tempMonthly",
        color1: temperatureColor,
        color2: temperatureColor2,
    },
    {
        name: "Frost days etc",
        title: "Temperature thresholds",
        units: "% days",
        route: "temperature-monthly-clim",
        mutuallyExclusive: false,
        aggregation: "monthly",
        statistic: "climatology",
        sliceKey: "tempMonthly",
        color1: temperatureColor,
        color2: temperatureColor2,
    },
    {
        name: "Precip annual timeseries",
        title: "Precipitation",
        units: "mm/year",
        route: "precip-annual-timeseries",
        mutuallyExclusive: false,
        aggregation: "annual",
        statistic: "timeseries",
        sliceKey: "precipAnn",
        color1: precipColor,
        color2: precipColor2,
    },
    {
        name: "Temperature annual timeseries",
        title: "Temperature",
        units: "°C",
        route: "temperature-annual-timeseries",
        mutuallyExclusive: false,
        aggregation: "annual",
        statistic: "timeseries",
        sliceKey: "tempAnn",
        color1: temperatureColor,
        color2: temperatureColor2,
    },
    {
        name: "Temperature warming stripes",
        title: "Warming stripes",
        units: "°C",
        route: "temperature-annual-timeseries",
        mutuallyExclusive: false,
        aggregation: "annual",
        statistic: "anomaly",
        sliceKey: "tempAnn",
        color1: temperatureColor,
        color2: temperatureColor2,
    },
    {
        name: "Wind annual climatology",
        title: "Wind",
        units: "% hours",
        route: "wind-annual-clim",
        mutuallyExclusive: true,
        aggregation: "hourly",
        statistic: "climatology",
        sliceKey: "windAnn",
        color1: windColors[1],
        color2: windColors[1],
    },
    {
        name: "Precip hourly hist",
        title: "Precipitation",
        units: "% hours",
        route: "precip-hourly-hist",
        mutuallyExclusive: false,
        aggregation: "hourly",
        statistic: "distribution",
        sliceKey: "precipHourly",
        color1: precipColor,
        color2: precipColor2,
    },
    {
        name: "Temperature daily climatology",
        title: "Temperature",
        units: "°C",
        route: "temperature-daily-clim",
        mutuallyExclusive: true,
        aggregation: "daily",
        statistic: "climatology",
        sliceKey: "tempDaily",
        color1: temperatureColor,
        color2: temperatureColor2,
    },
    {
        name: "Humidity monthly climatology",
        title: "Relative humidity",
        units: "%",
        route: "humidity-monthly-clim",
        mutuallyExclusive: false,
        aggregation: "monthly",
        statistic: "climatology",
        sliceKey: "humidityMonthly",
        color1: humidityColor,
        color2: humidityColor2,
    },
]

export const MONTHS_SHORT = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
export const MONTHS_LONG = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];

export const TOTAL_SLIDES = variableOptions.length
export const MIN_SLIDES_REQD = 3  // Minimum number of slides to show on the carousel
export const DEFAULT_LOCATION = { lat: 50.86, lng: 4.35 }
export const ERA_VERSION = "ERA5"

// Temperature thresholds
export const FROST_DAY = 0
export const SUMMER_DAY = 25
export const TROPICAL_NIGHT = 20

// Layout etc
export const MARGIN = 10
export const BUTTON_WIDTH = 35
export const MAX_INPUTS_WIDTH = 450
export const BOX_SHADOW = '1px 1px 3px 1px rgba(0, 0, 0, 0.5)'  // right, down, blur, borderWidth, color
export const BACKGROUND_COLOR = "#ffffff"
