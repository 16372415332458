// usePreventPullToRefresh.ts
import { useEffect, useRef } from 'react';

const usePreventPullToRefreshButton = () => {
  const ref = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const handleTouchMove = (e: TouchEvent) => {
      e.preventDefault();
    };

    const element = ref.current;
    if (element) {
      element.addEventListener('touchmove', handleTouchMove, { passive: false });
    }

    // Cleanup function to remove event listener
    return () => {
      if (element) {
        element.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, []);

  return ref;
};

const usePreventPullToRefreshDiv = () => {
    const ref = useRef<HTMLDivElement | null>(null);
  
    useEffect(() => {
      const handleTouchMove = (e: TouchEvent) => {
        e.preventDefault();
      };
  
      const element = ref.current;
      if (element) {
        element.addEventListener('touchmove', handleTouchMove, { passive: false });
      }
  
      // Cleanup function to remove event listener
      return () => {
        if (element) {
          element.removeEventListener('touchmove', handleTouchMove);
        }
      };
    }, []);
  
    return ref;
  };

export { usePreventPullToRefreshButton, usePreventPullToRefreshDiv}