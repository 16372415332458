import { Title } from '@mantine/core';
import React, { useState, useEffect } from 'react';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { RootState } from '../../reducers/store.ts';
import { MARGIN, MAX_INPUTS_WIDTH } from '../../config/constants.ts';

const Header = () => {
    console.log("Rendered Header")

    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
    const climatologyView = useTypedSelector(state => state.graph.climatologyView)
    const spectralPalette = useTypedSelector(state => state.graph.spectralPalette)
    const headerVisible = useTypedSelector(state => state.graph.headerVisible)
    const graphsVisible = useTypedSelector(state => state.graph.graphsVisible)
    const baseOpacity = !spectralPalette ? 0.6 : 0.4
    const minWidth = 750
    const minWidthWide = 1180
    const [landscape, setLandscape] = useState(window.innerWidth > minWidth)
    const [landscapeWide, setLandscapeWide] = useState(window.innerWidth > minWidthWide)

    useEffect(() => {
        const handleResize = () => {
            setLandscape(window.innerWidth > minWidth)
            setLandscapeWide(window.innerWidth > minWidthWide)
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const headerShouldBeVisible = !graphsVisible && headerVisible
    const lowOpacity = landscape ? baseOpacity : 0
    const opacity = headerShouldBeVisible ? baseOpacity : lowOpacity

    const stylesLandscape = {
        root: {
            position: 'fixed',
            top: '10px',
            left: MAX_INPUTS_WIDTH + MARGIN * 2,
            justifyContent: 'center',   /* Horizontally center the text */
            alignItems: 'center',      /* Vertically center the text */
            color: (spectralPalette ? '#000000' : '#FFFFFF'),
            opacity: opacity,
            margin: 0,
            fontSize: '2rem',
            visibility: (climatologyView ? 'hidden' : 'visible'),
            pointerEvents: 'none',
            transition: 'opacity 0.3s ease-in-out', // Smooth transition for opacity
        }
    }

    const stylesLandscapeWide = {
        root: {
            position: 'fixed',
            top: '10px',
            width: '100%',
            justifyContent: 'center',   /* Horizontally center the text */
            alignItems: 'center',      /* Vertically center the text */
            color: (spectralPalette ? '#000000' : '#FFFFFF'),
            opacity: opacity,
            margin: 0,
            fontSize: '2rem',
            visibility: (climatologyView ? 'hidden' : 'visible'),
            pointerEvents: 'none',
            transition: 'opacity 0.3s ease-in-out', // Smooth transition for opacity
        }
    }

    const extraMargin = 0
    const stylesPortrait = {
        root: {
            position: 'fixed',
            top: '50px',
            left: extraMargin + MARGIN,
            right: extraMargin + MARGIN,
            maxWidth: MAX_INPUTS_WIDTH - extraMargin * 2,
            justifyContent: 'center',   /* Horizontally center the text */
            alignItems: 'center',      /* Vertically center the text */
            color: (spectralPalette ? '#000000' : '#FFFFFF'),
            opacity: opacity,
            margin: 0,
            fontSize: '2rem',
            visibility: (climatologyView ? 'hidden' : 'visible'),
            pointerEvents: 'none',
            transition: 'opacity 0.3s ease-in-out', // Smooth transition for opacity
        }
    }

    const stylesAPI = (landscape ? (landscapeWide ? stylesLandscapeWide : stylesLandscape) : stylesPortrait)

    return (
        (landscape ?
            <Title
                order={3}
                lineClamp={1}
                // @ts-ignore  For the win!
                styles={stylesAPI}
            >
                ERA EXPLORER
            </Title>
            :
            <Title
                order={3}
                lineClamp={1}
                // @ts-ignore  For the win!
                styles={stylesAPI}
            >
                <div className="spread-text" >
                    <span>E</span>
                    <span>R</span>
                    <span>A</span>
                    <span>{' '}</span>
                    <span>{' '}</span>
                    <span>E</span>
                    <span>X</span>
                    <span>P</span>
                    <span>L</span>
                    <span>O</span>
                    <span>R</span>
                    <span>E</span>
                    <span>R</span>
                </div>
            </Title>

        )
    );
};

export default Header;
