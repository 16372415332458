import { memo, useEffect } from 'react';
import { useSelector, TypedUseSelectorHook, useDispatch } from 'react-redux'
import { RootState } from '../../reducers/store.ts';
import { TOTAL_SLIDES } from '../../config/constants.ts';
import { incrementAndResetProgress } from '../../reducers/progressSlice.ts';

function isMaxAtIndexZero(arr) {
    const max = Math.max(...arr); // Find the maximum value
    const maxIndex = arr.indexOf(max); // Get the index of the maximum value
    return maxIndex === 0; // Check if the index is 0
}

function TrackProgress() {

    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
    const dispatch = useDispatch()

    const progressInt = useTypedSelector(state => state.progress.progressInt)

    const openedProgress = useTypedSelector(state => state.progress.openedProgress)

    const viewedSlides = useTypedSelector(state => state.progress.viewedSlides)
    const numberViewedSlides = viewedSlides.length

    const annualAverageTemp = useTypedSelector(state => state.progress.annualAverageTemp)
    const annualAverageTempThreshold = 28.5

    const rainSelected = useTypedSelector(state => state.progress.rainSelected)
    const maxRainfall = useTypedSelector(state => state.progress.maxRainfall)
    const maxRainfallThreshold = 3000

    const routeOneComplete = useTypedSelector(state => state.progress.routeOneComplete)
    const comparisonMode = useTypedSelector(state => state.data.comparisonMode)

    const monthlySelected = useTypedSelector(state => state.progress.monthlySelected)
    const monthlyTemp = useTypedSelector(state => state.progress.monthlyTemp)

    const sharedStripes = useTypedSelector(state => state.progress.sharedStripes)

    useEffect(() => {
        switch (progressInt) {
            case -1:
                if (openedProgress) {
                    dispatch(incrementAndResetProgress())
                }
                break
            case 0:
                if (numberViewedSlides === TOTAL_SLIDES) {
                    dispatch(incrementAndResetProgress())
                }
                break
            case 1:
                if (annualAverageTemp > annualAverageTempThreshold) {
                    dispatch(incrementAndResetProgress())
                }
                break
            case 2:
                if (rainSelected && (maxRainfall > maxRainfallThreshold)) {
                    dispatch(incrementAndResetProgress())
                }
                break
            case 3:
                if (routeOneComplete && comparisonMode) {
                    dispatch(incrementAndResetProgress())
                }
                break
            case 4:
                if (monthlySelected && isMaxAtIndexZero(monthlyTemp)) {
                    dispatch(incrementAndResetProgress())
                }
                break
            case 5:
                if (sharedStripes) {
                    dispatch(incrementAndResetProgress())
                }
                break
            case 6:
                break
            default:
                throw new Error(`Invalid progressInt: ${progressInt}`)
        }
    }, [progressInt, dispatch, openedProgress, numberViewedSlides, annualAverageTemp,
        rainSelected, maxRainfall, routeOneComplete, comparisonMode,
        monthlySelected, monthlyTemp, sharedStripes]);

    return (
        null
    );
}

export default memo(TrackProgress);
