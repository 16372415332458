import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Tooltip } from 'react-leaflet';

function MarkerTooltip({ content }) {
    useEffect(() => {
        console.log("Rendered MarkerTooltip")
    })

    if (isMobile) {
        return null
    }

    return (
        <Tooltip
            direction="bottom"
            offset={[0, 3]}
            opacity={1}
            permanent={false}
            content={content}
            className="custom-tooltip" // Apply the custom CSS class
        />
    );
};

export default MarkerTooltip;