import { useRef, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux'
import { fetchPlaceName } from '../utils/osmApi.ts'
import { updateName, updateSecondaryNameWhenArrivedFromURL } from '../reducers/locationSlice.ts'
import { updateNamesInData } from '../reducers/dataSlice.ts'
import { formatLocation, normalizeLongitude } from '../utils/helperFunctions.ts'

const useFindNearestPlace = () => {
    const ac1 = useRef<AbortController | null>(null)
    const ac2 = useRef<AbortController | null>(null)
    const abortController = useMemo(() => [ac1, ac2], [])
    const dispatch = useDispatch()

    // Add a debounce timer ref
    const debounceTimer1 = useRef<NodeJS.Timeout | null>(null);
    const debounceTimer2 = useRef<NodeJS.Timeout | null>(null);
    const debounceTimer = useMemo(() => [debounceTimer1, debounceTimer2], []);

    const findNearestPlace = useCallback((lat: number, lng: number, routeNumber: number, fromComparisonURL: boolean, debug: string) => {
        console.log(`findNearestPlace: Called by ${debug}`, routeNumber)

        // Clear the previous debounce timer
        if (debounceTimer[routeNumber].current) {
            clearTimeout(debounceTimer[routeNumber].current);
        }

        if (fromComparisonURL) {
            switch (routeNumber) {
                case 0:
                    dispatch(updateSecondaryNameWhenArrivedFromURL("Fetching location..."));
                    break;
                case 1:
                    dispatch(updateName("Fetching location..."));
                    break;
                default:
                    throw new Error('Invalid route number');
            }
        } else {
            dispatch(updateName("Fetching location..."));
        }

        // Set a new debounce timer
        debounceTimer[routeNumber].current = setTimeout(() => {

            abortController[routeNumber].current?.abort();
            abortController[routeNumber].current = new AbortController();

            const longitudeWrapped = normalizeLongitude(lng)
            const query = `reverse?format=geocodejson&lat=${lat}&lon=${longitudeWrapped}&addressdetails=1&zoom=12`;
            const defaultName = formatLocation(lat, lng)

            console.log("findNearestPlace fetchPlaceName", fromComparisonURL, routeNumber)

            fetchPlaceName(query, defaultName, abortController[routeNumber].current.signal)
                .then((result) => {
                    if (fromComparisonURL) {
                        switch (routeNumber) {
                            case 0:
                                dispatch(updateSecondaryNameWhenArrivedFromURL(result));
                                break;
                            case 1:
                                dispatch(updateName(result));
                                break;
                            default:
                                throw new Error('Invalid route number');
                        }
                    } else {
                        dispatch(updateName(result));
                    }

                    const newLocationInfo = {
                        placeName: result,
                        position: { lat: lat, lng: lng },
                    };
                    dispatch(updateNamesInData({
                        routeNumber: routeNumber,
                        locationInfo: newLocationInfo
                    }));

                    abortController[routeNumber].current = null;
                })
                .catch(() => {
                    dispatch(updateName("Error loading location"));
                });
        }, 1000);
    }, [dispatch, abortController, debounceTimer]);

    return { findNearestPlace };
};

export default useFindNearestPlace;
